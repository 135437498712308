import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

export const DetailBlock = ({ detailBlocks }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        mt: 0,
        p: 4,
        backgroundColor: "#FAF9FF",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {detailBlocks.map((detail, index) => (
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          lg={4}
          xl={4}
          key={index}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Paper
            elevation={5}
            sx={{
              p: 3,
              borderRadius: 10,
              border: "solid #FCE6EA 2px",
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: { xs: 2, sm: 0 },
                }}
              >
                {detail.icon}
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography
                  textAlign='center'
                  fontWeight='bold'
                  fontSize={{ xs: "24px", sm: "30px" }}
                  sx={{ mb: 1 }}
                >
                  {detail.step}
                </Typography>
                <Typography
                  textAlign='center'
                  variant='subtitle1'
                  fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
                  sx={{
                    whiteSpace: "pre-line", // Permite los saltos de línea
                  }}
                >
                  {detail.title}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
