import { Fab } from "@mui/material";
import { Breadcrumb } from "../Breadcrumb/Breadcrubm";
import Layout from "./Layout";

export const PublicLayout = ({
  children,
  breadcrumb,
  breadcrumbTitle,
  description,
}) => {
  return (
    <Layout>
      <Breadcrumb
        breadcrumb={breadcrumb}
        title={breadcrumbTitle}
        description={description}
      />
      <>{children}</>
    </Layout>
  );
};
