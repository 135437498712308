import React, { useContext } from "react";
import Layout from "../../components/Layout/Layout";
import { Grid, Paper, Typography } from "@mui/material";
import CardSalesWeek from "../../components/Dashboard/CardSalesWeek";
import CardSalesMonthly from "../../components/Dashboard/CardSalesMonthly";
import CardSalesDay from "../../components/Dashboard/CardSalesDay";
import CardCashEntryDay from "../../components/Dashboard/CardCashEntryDay";
import SalesChart from "../../components/Graphs/SalesChart";
import MenuAdmin from "./MenuAdmin";
import AuthContext from "../../Context/auth/AuthContext";
import IndexVerificate from "../../containers/Orders/OrdersAdmin/IndexVerificate";
import IndexOrdersSeller from "../Orders/OrdersAdmin/OrdersSellers/IndexOrdersSeller";
import IndexCont from "../Orders/OrdersContabilidad/IndexCont";

// Component for Admin view
const MenuAdminView = () => (
  <>
    <Grid container spacing={2} sx={{ margin: 2 }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <MenuAdmin />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <CardSalesWeek />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <CardSalesMonthly />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <CardSalesDay />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <CardCashEntryDay />
      </Grid>
    </Grid>
    <Paper sx={{ margin: 6, width: "100%", borderRadius: 5 }} elevation={5}>
      <Typography sx={{ padding: 3 }}>Gráfica de Ventas</Typography>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          marginTop: 4,
          padding: 2,
        }}
      >
        <SalesChart />
      </Grid>
    </Paper>
  </>
);

// Main component
const InicioAdmin = () => {
  const { usuario } = useContext(AuthContext);

  // Render content based on user type
  const renderContent = () => {
    if (!usuario) return null;

    switch (usuario.type_user) {
      case 0: // Admin user
        return <MenuAdminView />;
      case 2: // Seller user
        return <IndexOrdersSeller />;
      case 3: // Accounting user
        return <IndexCont />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ margin: 1, marginTop: 1 }}>
        <Grid item xs={12}>
          <Typography align='center' fontSize='30px' fontWeight='bold'>
            Bienvenido
          </Typography>
        </Grid>
        {renderContent()}
      </Grid>
    </Layout>
  );
};

export default InicioAdmin;
