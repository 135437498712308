import React from "react";

const MapIcon = ({ width }) => {
  return (
    <svg
      height='45px'
      width={width}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 506.525 506.525'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <path
          style={{ fill: "#BC3F2C" }}
          d='M345.863,111.663l-14.4-25.6c-11.2-10.8-24-20.4-38-26.8c-12.4-5.6-25.6-8.4-39.6-8.4 c-37.6,0-72,22-87.6,56.4c-21.6,48-0.4,105.2,48,126.8c12.4,5.6,25.6,8.4,39.6,8.4c37.6,0,72-22,87.6-56.4 C351.863,162.863,352.663,136.863,345.863,111.663z M253.863,211.263c-35.6,0-64.4-28.8-64.4-64.4s28.8-64.4,64.4-64.4 c35.6,0,64.4,28.8,64.4,64.4C318.663,182.463,289.463,211.263,253.863,211.263z'
        ></path>{" "}
        <path
          style={{ fill: "#61C2AB" }}
          d='M255.063,358.063c-0.4,0-0.8,0.4-1.2,0.4s-0.8-0.4-1.2-0.4c-0.8-0.4-38-20.4-72.8-64.4h-2.4h-106 l-65.6,196h493.2l-65.6-196h-102h-4.8C293.063,337.663,255.463,357.663,255.063,358.063z M326.663,348.463c0.4,0.8,0,2-0.8,2.8 l-68,33.6l83.2,41.2c0.8,0.4,1.2,1.6,0.8,2.8c-0.4,0.8-1.2,1.2-1.6,1.2c-0.4,0-0.4,0-0.8-0.4l-86-42.4l-86,42.4 c-0.4,0-0.4,0.4-0.8,0.4c-0.8,0-1.6-0.4-1.6-1.2c-0.4-0.8,0-2,0.8-2.8l83.2-41.2l-68-33.6c-0.8-0.4-1.2-1.6-0.8-2.8 c0.4-0.8,1.6-1.2,2.8-0.8l70.8,35.2l70.8-35.2C324.663,347.263,325.863,347.663,326.663,348.463z'
        ></path>{" "}
        <path
          style={{ fill: "#DF5C4E" }}
          d='M253.863,18.463c-70.4,0-128,57.2-128,128c0,62,28.8,112,59.2,147.6s62.4,56.4,68.4,60.4 c6-4,38-24.8,68.4-60.4s59.2-85.6,59.2-147.6C381.863,76.063,324.263,18.463,253.863,18.463z M253.863,246.463 c-14.4,0-28-2.8-41.2-8.8c-50-22.8-72.4-82-50-132c16-35.6,52-58.8,91.2-58.8c14.4,0,28,2.8,41.2,8.8c14.8,6.8,27.6,16.8,36.4,30.4 l14.8,26c10.8,24.4,10,51.2-0.8,75.6C328.663,223.263,293.063,246.463,253.863,246.463z'
        ></path>{" "}
        <path d='M253.863,360.863c-0.8,0-1.6,0-2-0.4c-1.2-0.8-132-80-132-213.6c0-74,60-134,134-134c73.6,0,134,60,134,134 c0,133.6-130.4,212.8-131.6,213.6C255.463,360.463,254.663,360.863,253.863,360.863z M253.863,20.463c-69.2,0-126,56.4-126,126 c0,120,109.6,194.8,126,205.6c16.4-10.4,126-85.6,126-205.6C379.863,76.863,323.463,20.463,253.863,20.463z'></path>{" "}
        <path d='M253.863,248.463c-14.4,0-28.4-3.2-42-9.2c-51.2-23.2-74-83.6-50.8-134.8c23.2-51.2,83.6-74,134.8-50.8 c15.2,6.8,28.4,17.2,38.4,30.4c1.2,1.6,1.2,4.4-0.8,5.6c-1.6,1.2-4.4,1.2-5.6-0.8c-9.2-12-21.6-21.6-35.6-28 c-47.2-21.2-102.8-0.4-124,46.8c-21.2,47.2-0.4,102.8,46.8,124c22.8,10.4,48.4,11.2,71.6,2.4c23.6-8.8,42-26.4,52.4-49.2 c10.4-22.8,11.2-48,2.4-71.6c-0.8-2,0.4-4.4,2.4-5.2c2-0.8,4.4,0.4,5.2,2.4c9.6,25.2,8.4,52.8-2.8,77.6 c-11.2,24.8-31.2,43.6-56.8,53.2C278.263,246.063,265.863,248.463,253.863,248.463z'></path>{" "}
        <path d='M253.863,213.263c-36.8,0-66.8-30-66.8-66.8s30-66.8,66.8-66.8s66.8,30,66.8,66.8S290.663,213.263,253.863,213.263z M253.863,88.063c-32.4,0-58.8,26.4-58.8,58.8s26.4,58.8,58.8,58.8c32.4,0,58.8-26.4,58.8-58.8S286.263,88.063,253.863,88.063z'></path>{" "}
        <path d='M502.663,493.663H3.863c-1.2,0-2.4-0.8-3.2-1.6c-0.8-1.2-0.8-2.4-0.4-3.6l66.8-200c0.4-1.6,2-2.8,3.6-2.8h106.8 c2.4,0,4,1.6,4,4s-1.6,4-4,4h-103.6l-64,192h487.2l-64-192h-104c-2.4,0-4-1.6-4-4s1.6-4,4-4h106.8c1.6,0,3.2,1.2,3.6,2.8l66.8,200 c0.4,1.2,0.4,2.4-0.4,3.6C505.063,492.863,503.863,493.663,502.663,493.663z'></path>{" "}
        <path d='M166.263,432.063c-1.6,0-2.8-0.8-3.6-2.4c-0.8-2,0-4.4,2-5.2l158.4-78.4c2-0.8,4.4,0,5.2,2s0,4.4-2,5.2l-158.4,78.4 C167.463,431.663,167.063,432.063,166.263,432.063z'></path>{" "}
        <path d='M339.863,432.063c-0.4,0-1.2,0-1.6-0.4l-158.4-78.4c-2-0.8-2.8-3.2-2-5.2s3.2-2.8,5.2-2l158.4,78.4c2,0.8,2.8,3.2,2,5.2 C342.663,430.863,341.463,432.063,339.863,432.063z'></path>{" "}
      </g>
    </svg>
  );
};

export default MapIcon;
