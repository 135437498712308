import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { TablePagination, Typography } from "@mui/material";
import TableProductSale from "./TableProductSale";
const useStyles = makeStyles((theme) => ({
  TableContainer: {
    padding: "25px",
  },
  fontNormal: {
    fontSize: "23px",
  },
  fontSubtitle: {
    fontSize: "15px",
  },
}));

const TableSaleProducts = ({ products_list = [], discount }) => {
  const classes = useStyles();
  /**Paginacion de la tabla */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /**Termina paginacion */
  const quantyties = products_list.map((p) => p.quantity);
  const initialValue = 0;
  const sumaProductos = quantyties.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
  );

  return (
    <Grid
      container
      justifyContent='center  '
      spacing={3}
      className={classes.TableContainer}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer>
          <Table aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Imagen</TableCell>
                <TableCell align='center'>C.barras</TableCell>
                <TableCell align='center'>Producto</TableCell>
                <TableCell align='center'>
                  <Typography sx={{ fontSize: 12 }}>Precio Unit.</Typography>
                </TableCell>
                <TableCell align='center'>Cant.</TableCell>
                <TableCell align='center'>
                  <Typography sx={{ fontSize: 12 }}>Subt.</Typography>
                </TableCell>
                <TableCell align='center'>Precio C/Descuento</TableCell>
                <TableCell align='center'>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!products_list.length ? (
                <TableRow>
                  <TableCell align='center' colSpan={5}>
                    No se encuentran productos agregados a esta lista
                  </TableCell>
                </TableRow>
              ) : (
                products_list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((product, index) => (
                    <TableProductSale
                      key={index}
                      product={product}
                      discount={discount}
                    />
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component='div'
          count={products_list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Registros por página'
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography fontWeight='bold' fontFamily='monospace'>
          Total de productos: {sumaProductos} pzas.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TableSaleProducts;
