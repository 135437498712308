import { useContext, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SelectHelpSeller from "../../../containers/SelectOptions/HelpForSeller";
import SelectInvoice from "../../../containers/SelectOptions/SelectInvoice";
export const CheckoutStep2 = ({
  onNext,
  onPrev,
  setHelpSeller,
  setInvoice,
}) => {
  const [payment, setPayment] = useState("credit-card");
  const [copied, setCopied] = useState("");

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(text);
    setTimeout(() => setCopied(""), 2000); // Reset "copied" state after 2 seconds
  };

  const detectarCambiosHelpForSeller = (value) => {
    setHelpSeller(value.value);
  };

  const detectarCambiosInvoice = (value) => {
    setInvoice(value.value);
  };

  return (
    <>
      {/* BEING CHECKOUT STEP TWO */}
      <Box sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
        <Typography variant='h4' gutterBottom>
          Métodos de pago
        </Typography>

        {/* Payment Option */}
        <Box
          sx={{
            border: 1,
            borderColor: payment === "credit-card" ? "#E46EA7" : "grey.300",
            borderRadius: 2,
            p: 2,
            mb: 3,
          }}
        >
          <FormControl>
            <RadioGroup
              value={payment}
              onChange={(e) => setPayment(e.target.value)}
            >
              <FormControlLabel
                value='credit-card'
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant='body1' fontWeight='bold'>
                      Transferencia Bancaria
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>

          {payment === "credit-card" && (
            <Box sx={{ mt: 3 }}>
              <Typography variant='h6' gutterBottom>
                Detalles de Transferencia Bancaria
              </Typography>

              <Grid container spacing={2}>
                {/* Nombre del titular */}
                <Grid item xs={12} sm={6}>
                  <Typography variant='body1' fontWeight='bold'>
                    Titular de la cuenta:
                  </Typography>
                  <Box display='flex' alignItems='center'>
                    <Typography
                      variant='body1'
                      sx={{ mr: 1, fontWeight: "bold", color: "black" }}
                    >
                      Importadora MerryColor SAPI de CV
                    </Typography>
                    <IconButton
                      color='secondary'
                      onClick={() => handleCopy("Importadora MerryColor")}
                      size='small'
                    >
                      <ContentCopyIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </Grid>

                {/* CLABE */}
                <Grid item xs={12} sm={6}>
                  <Typography variant='body1' fontWeight='bold'>
                    CLABE:
                  </Typography>
                  <Box display='flex' alignItems='center'>
                    <Typography
                      variant='body2'
                      sx={{ mr: 1, fontWeight: "bold", color: "black" }}
                    >
                      012441001237829215
                    </Typography>
                    <IconButton
                      color='secondary'
                      onClick={() => handleCopy("012345678901234567")}
                      size='small'
                    >
                      <ContentCopyIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant='body1' fontWeight='bold'>
                    N° CUENTA:
                  </Typography>
                  <Box display='flex' alignItems='center'>
                    <Typography
                      variant='body2'
                      sx={{ mr: 1, fontWeight: "bold", color: "black" }}
                    >
                      0123782921
                    </Typography>
                    <IconButton
                      color='secondary'
                      onClick={() => handleCopy("012345678901234567")}
                      size='small'
                    >
                      <ContentCopyIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </Grid>

                {/* Banco */}
                <Grid item xs={12} sm={6}>
                  <Typography variant='body1' fontWeight='bold'>
                    Banco:
                  </Typography>
                  <Box display='flex' alignItems='center'>
                    <Typography
                      variant='body2'
                      sx={{ mr: 1, fontWeight: "bold", color: "black" }}
                    >
                      BBVA
                    </Typography>
                    <IconButton
                      color='secondary'
                      onClick={() => handleCopy("Banco Nacional")}
                      size='small'
                    >
                      <ContentCopyIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>

              {/* Nota para el cliente */}
              <Typography variant='body2' color='textSecondary' sx={{ mt: 2 }}>
                <b>Importante:</b>
                Por favor, realice la transferencia bancaria y conserve su
                comprobante. Debe de subir su comprobante de pago en la sección
                de mis pedidos.
                <br />
                <b>
                  Tiene maximo 5 dias para realizar su transferencia, de lo
                  contrario su pedido se te cancelará de manera automática.
                </b>
              </Typography>

              {/* Feedback de copiado */}
              {copied && (
                <Typography
                  variant='body1'
                  color='#D87390'
                  fontWeight='bold'
                  sx={{ mt: 2 }}
                >
                  ¡Copiado al portapapeles!
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Typography
          variant='h6'
          fontWeight='bold'
          textAlign='center'
          sx={{ mt: 4, mb: 2.8 }}
        >
          Selecciona una opcion en las preguntas que estan a continuación
        </Typography>
        <Grid container spacing={2}>
          <Typography variant='subtitle2' fontWeight='bold' sx={{ mt: 4 }}>
            ¿Recibiste ayuda de parte de un asesor de ventas para realizar tu
            compra?
          </Typography>
          <Grid item xs={12}>
            <SelectHelpSeller
              detectarCambiosHelpForSeller={detectarCambiosHelpForSeller}
            />
          </Grid>

          <Typography variant='subtitle2' fontWeight='bold' sx={{ mt: 2 }}>
            ¿Requires facturar tu compra?
          </Typography>
          <Grid item xs={12}>
            <SelectInvoice detectarCambiosInvoice={detectarCambiosInvoice} />
          </Grid>
        </Grid>
      </Box>
      {/* CHECKOUT STEP TWO EOF */}
    </>
  );
};
