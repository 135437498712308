import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import formatDate from "../../../../utils/FormatDate";
import FormatCurrency from "../../../../utils/FormatCurrency";
import ModalTicketPayment from "../../../Cart/ModalTicketPayment";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Remove, RemoveRedEye } from "@material-ui/icons";
import { UploadFile } from "@mui/icons-material";
const Card = ({ order, index, onCollapse, active }) => {
  const { direction, status } = order;

  const deliveryAddress = [
    direction.street ?? "N/A",
    direction.between_street ?? "N/A",
    direction.number_ext ?? "N/A",
    direction.number_int ?? "N/A",
    direction.and_between_street ?? "N/A",
    direction.city ?? "N/A",
    direction.state.name ? direction.state.name : "N/A",
    direction.municipality.name ? direction.municipality.name : "N/A",
    direction.zone ?? "N/A",
    direction.postal_code ?? "N/A",
    direction.references ?? "N/A",
    direction.email ?? "N/A",
    direction.phone ?? "N/A",
  ]
    .filter((field) => field)
    .join(", ");

  const [open, setOpen] = useState(false);
  const [order_id, setOrderId] = useState(null);
  const [total, setTotal] = useState(null);
  const [envio, setEnvio] = useState(null);

  const handleClickOpen = (id, total, envio) => {
    setOrderId(id);
    setTotal(total);
    setEnvio(envio);
    setOpen(true);
  };

  const handleClose = () => {
    setOrderId(null);
    setTotal(null);
    setOpen(false);
  };

  const statusColors = {
    0: "warning.main",
    1: "info.main",
    2: "info.dark",
    3: "success.main",
    4: "error.main",
  };

  const statusTexts = {
    0: "Pendiente de pago",
    1: "Validando Pago",
    2: "En preparación",
    3: "Enviado",
    4: "Cancelado",
  };

  const renderStatus = (status) => (
    <Typography variant='body1' textAlign='center' color={statusColors[status]}>
      {statusTexts[status]}
    </Typography>
  );

  const renderActionButton = () => {
    if (status === 0) {
      return <></>;
    }

    if (status === 0) {
      return (
        <>
          <IconButton variant='contained' size='large'>
            <PictureAsPdfIcon color='error' fontSize={30} />
          </IconButton>
        </>
      );
    }

    return null;
  };

  return (
    <Paper
      elevation={3}
      sx={{
        mb: 2,
        p: 2,
        borderRadius: 5,
        border: "dotted #802257 2px ",
        backgroundColor: active === index ? "rgba(0, 0, 255, 0.05)" : "white",
        cursor: "pointer",
      }}
      onClick={() => onCollapse(index)}
    >
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
          <Typography textAlign='center' fontWeight='bold'>
            Fecha Compra:
          </Typography>
          <Typography variant='body1' textAlign='center'>
            {formatDate(order.created_at)}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
          <Typography textAlign='center' fontWeight='bold'>
            Folio:
          </Typography>
          <Typography
            variant='body1'
            textAlign='center'
            sx={{ fontWeight: "bold" }}
          >
            #{order.id}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography textAlign='center' fontWeight='bold'>
            Dirección de envío:
          </Typography>
          <Typography
            variant='body2'
            textAlign='justify'
            sx={{
              maxHeight: "auto",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {deliveryAddress}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
          <Typography textAlign='center' fontWeight='bold'>
            Subtotal
          </Typography>
          <Typography variant='body1' textAlign='center'>
            {FormatCurrency(order.subtotal)}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
          <Typography
            textAlign='center'
            fontWeight='bold
          '
          >
            Descuento:
          </Typography>
          <Typography variant='body1' textAlign='center'>
            {order.business?.discount || 0} %
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
          <Typography textAlign='center' fontWeight='bold'>
            Desc. Adicional:
          </Typography>
          <Typography variant='body1' textAlign='center'>
            {order.aditional_discount || 0} %
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
          <Typography textAlign='center' fontWeight='bold'>
            Costo de envio:
          </Typography>
          <Typography variant='body1' textAlign='center'>
            {FormatCurrency(order.shipping_cost || 0)}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
          <Typography textAlign='center' fontWeight='bold'>
            Total:
          </Typography>
          <Typography variant='body1' textAlign='center' fontWeight='bold'>
            {FormatCurrency(order.total + (order.shipping_cost || 0))}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={1} lg={1} xl={1}>
          <Typography textAlign='center' fontWeight='bold'>
            Status:
          </Typography>
          {renderStatus(order.status)}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={1}
          lg={1}
          xl={1}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Tooltip title='Subir comprabante de pago'>
            <IconButton
              variant='contained'
              sx={{
                backgroundColor: "#802257",
                color: "white",
                "&:hover": { backgroundColor: "#802257" },
              }}
              onClick={(e) => {
                handleClickOpen(order.id, order.total, order.shipping_cost);
                e.stopPropagation();
              }}
            >
              <UploadFile />
            </IconButton>
          </Tooltip>

          <Link to={`/Detalle-venta/${order.id}`}>
            <Tooltip title='Ver detalle de la venta'>
              <IconButton
                sx={{
                  backgroundColor: "#802257",
                  color: "white",
                  "&:hover": { backgroundColor: "#802257" },
                }}
              >
                <RemoveRedEye />
              </IconButton>
            </Tooltip>
          </Link>
        </Grid>
      </Grid>
      {order_id !== null && total !== null && (
        <ModalTicketPayment
          open={open}
          handleClose={handleClose}
          order_id={order_id}
          total={total}
          envio={envio}
          total_pagado={
            order.payments
              ? order.payments
                  .map((payment) => payment.amount)
                  .reduce((total, amount) => total + amount, 0)
              : 0
          }
        />
      )}
    </Paper>
  );
};

export default Card;
