import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Faq = () => {
  const faqData = [
    {
      title: "Tiempos de entrega y servicio de paquetería",
      content:
        "Tu pedido será entregado en un plazo de 3 a 4 días hábiles, contados a partir de la confirmación de tu pago. Todos nuestros envíos se realizan a través de Estafeta, garantizando un servicio confiable y seguro.",
      id: "1",
    },
    {
      title:
        "¿Puedo realizar un pedido desde cualquier estado dentro de la republica Mexicana?",
      content:
        "Así es, puedes realizar tu compra desde cualquier parte del país. Lo único que necesitas es un dispositivo con acceso a internet, tener una cuenta en nuestra página, seleccionar tus productos favoritos y comprarlos.",
      id: "2",
    },
    {
      title: "¿Ofrecen asesorías para elegir los productos adecuados?",
      content:
        "Sí, contamos con un amplio equipo de ventas que puede asesorarte para elegir de mejor manera qué productos adquirir.",
      id: "3",
    },
    {
      title: "¿Tienen políticas de devoluciones o cambios?",
      content:
        "Sí, contamos con nuestro apartado de políticas, términos y condiciones.",
      id: "4",
    },
    {
      title: "¿Cuáles son los métodos de pago disponibles?",
      content:
        "Actualmente, nuestros métodos de pago son limitados, ya que únicamente aceptamos transferencia electrónica, en la seccion de tu perfil encontraras los datos de pago",
      id: "5",
    },
    {
      title: "¿Tienen certificaciones de calidad o ecológicas?",
      content:
        "Sí, todos nuestros productos son certificados, necesarios y amables con el medio ambiente.",
      id: "6",
    },
  ];

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FAF9FF",
        borderRadius: 2,
        padding: 4,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: "auto",
        margin: "0 auto",
      }}
    >
      <Typography
        variant='h4'
        sx={{
          textAlign: "center",
          marginBottom: 3,
          fontWeight: "bold",
          color: "#333",
        }}
      >
        Consulta aquí
      </Typography>
      {faqData.map((faq, index) => (
        <Accordion
          key={faq.id}
          expanded={expanded === index}
          onChange={handleChange(index)}
          sx={{
            marginBottom: 2,
            "&:before": {
              display: "none", // Elimina la línea por defecto de MUI
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: "#D8708E",
              borderRadius: 1,
              "&.Mui-expanded": {
                backgroundColor: "#EFC5D1",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", color: "white" }}>
              {faq.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
