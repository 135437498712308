import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import AuthContext from "../../Context/auth/AuthContext";
import imgbgregister from "../../assets/img/registration-form__bg.png";

const Register = () => {
  const { registerUser } = useContext(AuthContext);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      name: "",
      number_phone: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "El nombre debe tener al menos 3 caracteres")
        .required("Nombre completo requerido"),
      number_phone: Yup.string()
        .length(10, "El teléfono debe tener exactamente 10 dígitos")
        .required("Teléfono requerido"),
      password: Yup.string()
        .min(8, "La contraseña debe tener al menos 8 caracteres")
        .required("Contraseña requerida"),
    }),
    onSubmit: async (values) => {
      try {
        const token = await registerUser(values); // Llama a la función `register` del contexto
        if (token) {
          localStorage.setItem("token", token); // Guarda el token
          history.push("/"); // Redirige al usuario a la página principal
        }
      } catch (error) {
        console.error("Error al registrarse:", error);
      }
    },
  });

  return (
    <Container maxWidth='sm' sx={{ mt: 4, mb: 8 }}>
      <Box
        sx={{
          backgroundImage: `url(${imgbgregister})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant='h4' gutterBottom textAlign='center'>
          Regístrate ahora
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Nombre completo'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Teléfono'
                name='number_phone'
                type='tel'
                value={formik.values.number_phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number_phone &&
                  Boolean(formik.errors.number_phone)
                }
                helperText={
                  formik.touched.number_phone && formik.errors.number_phone
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Contraseña'
                name='password'
                type='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                type='submit'
                sx={{
                  backgroundColor: "#801F56",
                  "&:hover": { backgroundColor: "#801F56" },
                }}
              >
                Registrarme
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign='center'>
                ¿Ya tienes cuenta?{" "}
                <Link to='/iniciar-sesion'>Inicia sesión</Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default Register;
