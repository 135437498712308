import {
  ADD_MULTIMEDIA_PRODUCT,
  ADD_PRODUCT,
  CREATE_ENTRY_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_PAGINATION,
  GET_ENTRY_PRODUCTS,
  GET_PRODUCTS_DESACTIVATE,
  GET_PRODUCTS_WITHOUT_IMAGE,
  GET_PRODUCTS_WITH_STOCK_LOW,
  INCREASE_STOCK_PRODUCT,
  PRODUCTS_BY_CATEGORY,
  PRODUCTS_CATEGORY,
  PRODUCTS_NO_AUTH,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ADMIN,
  UPDATE_PRODUCT,
} from "../../types";

export default function ProductsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_PRODUCTS_DESACTIVATE:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case SEARCH_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case SEARCH_PRODUCTS_ADMIN:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PRODUCTS_CATEGORY:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_PRODUCTS_PAGINATION:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_PRODUCTS_WITHOUT_IMAGE:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_PRODUCTS_WITH_STOCK_LOW:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PRODUCTS_NO_AUTH:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [action.payload, ...state.products],
        success: false,
        ErrorsApi: [],
      };
    case ADD_MULTIMEDIA_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id ? { ...action.payload } : product
        ),
        success: true,
        ErrorsApi: [],
      };
    case INCREASE_STOCK_PRODUCT:
      return {
        ...state,
        products: state.products.filter(
          (product) => product.id !== action.payload.id
        ),
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id ? { ...action.payload } : product
        ),
        ErrorsApi: [],
      };
    case PRODUCTS_BY_CATEGORY:
      return {
        products: action.payload.data,
        current_page: action.payload.current_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
