import Swal from "sweetalert2";
const showToast = (icon, title, text = "") => {
  Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    background: icon === "success" ? "#388e3c" : "red",
    color: "white",
    iconColor: "white",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  }).fire({ icon, title, text });
};

export default showToast;
