import FormatDate from "../../../../utils/FormatDate";
import StarIcon from "@mui/icons-material/Star";
import DirectionsContext from "../../../../Context/Directions/DirectionsContext";
import { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { Box, Paper, Typography } from "@mui/material";
import MapIcon from "../../../icons/MapIcon";
import EditIcon from "@mui/icons-material/Edit";
import UpdateDirection from "../../../../containers/Directions/UpdateDirection";
export const Card = ({ order, index, onCollapse, active }) => {
  const {
    country,
    name,
    postal_code,
    reference,
    zone,
    city,
    municipality,
    state,
    is_default,
    number_int,
    number_out,
    between_street,
    and_between_street,
    phone,
    email,
  } = order;

  const { setIsDefaultDirection, DeleteDirection } =
    useContext(DirectionsContext);

  const new_direction = `${name}, ${country}, ${state.name}, ${
    municipality.name
  }, ${city}, ${between_street}, ${and_between_street}, N° int: ${
    number_int ?? "N/A"
  }, N° Ext: ${number_out ?? "N/A"}, ${zone}, ${postal_code}, ${reference}, ${
    phone ?? "N/A"
  }, ${email ?? "N/A"}`;

  /**open modal update*/
  const [directionId, setDirectionId] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const handleClickOpenUpdate = (id) => {
    setOpenUpdate(true);
    setDirectionId(id);
  };
  const handleClickCloseUpdate = () => {
    setOpenUpdate(false);
    setDirectionId(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: 2,
          marginBottom: 2,
          border: "1px solid",
          borderColor: active === index ? "primary.main" : "grey.300",
          borderRadius: 2,
          backgroundColor:
            active === index ? "primary.light" : "background.paper",
          boxShadow: active === index ? 4 : 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box>
            <MapIcon />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant='body2'
              color='text.secondary'
              sx={{ display: { xs: "block", sm: "none" }, fontWeight: "bold" }}
            >
              Delivery address
            </Typography>
            <Typography variant='body1' sx={{ fontWeight: "medium" }}>
              {new_direction}
            </Typography>
          </Box>

          <Box>
            {is_default ? (
              <StarIcon sx={{ color: "gold" }} />
            ) : (
              <IconButton onClick={() => setIsDefaultDirection(order.id)}>
                <StarIcon sx={{ color: "gray" }} />
              </IconButton>
            )}
          </Box>
          <Box>
            <IconButton onClick={() => handleClickOpenUpdate(order.id)}>
              <EditIcon sx={{ color: "#801F56" }} />
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={() => DeleteDirection(order.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {directionId && (
        <UpdateDirection
          open={openUpdate}
          id={directionId}
          handleClose={handleClickCloseUpdate}
        />
      )}
    </>
  );
};
