import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Button, Link, Container } from "@mui/material";
import { PromoVideo } from "../../components/PromoVideo";
import infoimg from "../../assets/img/info-item-bg1.jpg";
import info2img from "../../assets/img/PORTADA.jpg";

export const Info = () => {
  const [play, setPlay] = useState(false);

  const url = play
    ? "https://www.youtube.com/embed/5Pf2yiGnkAs?autoplay=1&modestbranding=1&fs=0&rel=0&controls=0"
    : "";

  return (
    <Box>
      {/* Bloque de Información */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          backgroundImage: `url(${infoimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          py: 8,
        }}
      >
        {/* Imagen */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            mb: { xs: 4, md: 0 },
          }}
        >
          <Box
            component='img'
            src={info2img}
            alt='Nueva colección'
            sx={{
              maxWidth: "80%",
              borderRadius: 2,
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Box>

        {/* Texto */}
        <Box
          sx={{
            flex: 1,
            px: { xs: 2, md: 6 },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant='h5' gutterBottom sx={{ fontWeight: "bold" }}>
            ¡Conocenos Mejor!
          </Typography>
          <Typography
            variant='h3'
            gutterBottom
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            MerryColor
          </Typography>
          <Typography
            variant='body1'
            gutterBottom
            sx={{ mb: 2, textAlign: "justify" }}
          >
            Nos especializamos en ofrecer una amplia gama de productos de
            belleza diseñados para resaltar tu estilo y cuidar de ti.
          </Typography>
          <Typography
            variant='body1'
            gutterBottom
            sx={{ mb: 4, textAlign: "justify" }}
          >
            Uñas: Geles, herramientas y accesorios para crear diseños únicos y
            profesionales.
            <br /> Pestañas: Extensiones, adhesivos y productos para una mirada
            impecable. <br /> Maquillaje: Todo lo que necesitas para realzar tu
            belleza natural. <br /> Cuidado de la piel: Mascarillas,
            tratamientos y productos que nutren y revitalizan tu piel. Nos
            enorgullece ofrecer productos de alta calidad para profesionales y
            amantes de la belleza. <br /> Explora nuestra tienda y encuentra
            todo lo necesario para lucir espectacular en cualquier ocasión.
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            MerryColor, donde comienza tu transformación.
          </Typography>

          {/* <Button
            variant='contained'
            sx={{
              backgroundColor: "#7F2459",
              "&:hover": { backgroundColor: "#7F2459" },
            }}
          >
            Comprar
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};
