import React, { useContext, useEffect, useState } from "react";
import PedidosContext from "../../../../Context/Pedidos/PedidosContext";
import Card from "../Card/Card";
import { Box, Typography, Alert, Grid } from "@mui/material";

const PendingPayment = () => {
  const [active, setActive] = useState(-1);
  const handleCollapse = (index) => {
    setActive(active === index ? -1 : index);
  };

  const { orders_client, GetPedidosPendientesDePago } =
    useContext(PedidosContext);

  useEffect(() => {
    GetPedidosPendientesDePago();
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      {orders_client.length > 0 ? (
        <Grid container spacing={2}>
          {/* Contenido */}
          {orders_client.map((order, index) => (
            <Grid item xs={12} key={index}>
              <Card
                key={index}
                index={index}
                onCollapse={handleCollapse}
                order={order}
                active={active}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity='info' sx={{ mt: 2 }}>
          No tienes pedidos pendientes de pago.
        </Alert>
      )}
    </Box>
  );
};

export default PendingPayment;
