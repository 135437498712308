import { PublicLayout } from "../../components/Layout/PublicLayout";
import Policies from "../../components/Policies/Policies";

const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Politicas de privacidad",
    path: "/policies-privacy",
  },
];
const PoliciesPage = () => {
  return (
    <PublicLayout
      breadcrumb={breadcrumbsData}
      breadcrumbTitle='Politicas de privacidad'
    >
      <Policies />
    </PublicLayout>
  );
};

export default PoliciesPage;
