import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import { useState, useEffect } from "react";
import MethodGet from "../../../config/Service";
import FormatDate from "../../../utils/FormatDate";
import TextField from "@mui/material/TextField"; // Importar TextField para el buscador

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ProductsMovements() {
  const [products_movements, setProductsMovements] = useState([]); // Inicializar como arreglo vacío
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState(""); // Estado para el texto de búsqueda

  useEffect(() => {
    const url = "/stock-movements";
    MethodGet(url)
      .then((res) => {
        setProductsMovements(res.data.data || []); // Asegurar que siempre sea un arreglo
      })
      .catch((error) => {
        console.error("Error fetching stock movements:", error);
      });
  }, []);

  const emptyRows =
    rowsPerPage > 0
      ? Math.max(0, (1 + page) * rowsPerPage - products_movements.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value); // Actualiza el texto de búsqueda
  };

  // Filtrar los productos según el texto de búsqueda
  const filteredRows = products_movements.filter(
    (row) =>
      row.product.name.toLowerCase().includes(searchText.toLowerCase()) ||
      row.description.toLowerCase().includes(searchText.toLowerCase()) ||
      row.id.toString().includes(searchText)
  );

  const displayedRows =
    rowsPerPage > 0
      ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : filteredRows;

  return (
    <>
      {/* Campo de búsqueda */}
      <TextField
        label='Buscar'
        variant='outlined'
        fullWidth
        value={searchText}
        onChange={handleSearchChange}
        sx={{ marginBottom: 2 }}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label='custom pagination table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' sx={{ fontWeight: "bold" }}>
                Folio
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: "bold" }}>
                C.barras
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: "bold" }}>
                Producto
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: "bold" }}>
                Descripción
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: "bold" }}>
                Cantidad
              </TableCell>
              <TableCell align='center' sx={{ fontWeight: "bold" }}>
                Fecha
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.length > 0 ? (
              displayedRows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell align='center'>
                    {row.product.tag || "N/A"}
                  </TableCell>
                  <TableCell align='center'>
                    {row.product.name || "N/A"}
                  </TableCell>

                  <TableCell align='center'>
                    {row.description || "N/A"}
                  </TableCell>
                  <TableCell align='center'>{row.quantity || "N/A"}</TableCell>
                  <TableCell align='center'>
                    {FormatDate(row.created_at)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align='center'>
                  No hay movimientos de stock disponibles.
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "Todos", value: -1 }]}
                colSpan={5}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
