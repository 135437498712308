import React from "react";

const PhoneEmailicon = () => {
  return (
    <svg
      fill='#000000'
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 446.599 446.6'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d='M317.156,446.6c16.707,0,30.306-13.604,30.306-30.31V30.303C347.461,13.596,333.863,0,317.156,0H129.443 c-16.709,0-30.305,13.596-30.305,30.303V416.29c0,16.706,13.596,30.31,30.305,30.31H317.156L317.156,446.6z M110.988,416.29 V30.303c0-10.166,8.279-18.454,18.456-18.454h187.717c10.177,0,18.459,8.288,18.459,18.454V416.29 c0,10.181-8.282,18.463-18.459,18.463H129.443C119.267,434.753,110.988,426.471,110.988,416.29z'></path>{" "}
            <path d='M323.884,391.103V52.242c0-1.636-1.325-2.963-2.961-2.963H125.675c-1.635,0-2.963,1.328-2.963,2.963v338.86 c0,1.636,1.328,2.966,2.963,2.966h195.254C322.559,394.068,323.884,392.738,323.884,391.103z M310.062,388.143H136.538 c-4.362,0-7.901-3.54-7.901-7.901V63.104c0-4.359,3.539-7.898,7.901-7.898h173.529c4.362,0,7.901,3.539,7.901,7.898v317.138 C317.963,384.603,314.429,388.143,310.062,388.143z'></path>{" "}
            <path d='M206.641,31.234h33.324c1.636,0,2.961-1.325,2.961-2.963c0-1.633-1.325-2.961-2.961-2.961h-33.324 c-1.635,0-2.963,1.328-2.963,2.961C203.678,29.909,205.006,31.234,206.641,31.234z'></path>{" "}
            <path d='M240.367,400.837h-34.129c-7.005,0-12.71,5.703-12.71,12.705v2.037c0,7.007,5.705,12.71,12.71,12.71h34.129 c7.008,0,12.71-5.713,12.71-12.71v-2.037C253.077,406.54,247.375,400.837,240.367,400.837z M247.151,415.579 c0,3.742-3.047,6.784-6.784,6.784h-34.129c-3.743,0-6.784-3.047-6.784-6.784v-2.037c0-3.737,3.049-6.778,6.784-6.778h34.129 c3.742,0,6.784,3.046,6.784,6.778V415.579z'></path>{" "}
            <path d='M188.432,193.651v42.899c0,11.704,9.529,21.241,21.244,21.241h71.62c11.715,0,21.246-9.537,21.246-21.241v-42.899 c0-6.35-5.175-11.525-11.537-11.525h-91.038C193.614,182.126,188.432,187.301,188.432,193.651z M192.075,193.651 c0-0.907,0.165-1.777,0.457-2.583c0.498-1.376,4.179-0.254,7.749,2.26l22.493,15.838c3.57,2.514,4.06,7.142,1.089,10.349 l-21.582,23.32c-2.963,3.199-7.576,3.636-8.952,0.224c-0.805-2.016-1.254-4.21-1.254-6.505V193.651L192.075,193.651z M209.675,254.14c-1.825,0-3.595-0.274-5.245-0.797c-2.803-0.874-2.354-4.926,0.609-8.125l21.827-23.585 c2.963-3.199,7.163-4.534,9.387-2.971l4.017,2.829c1.376,0.965,3.164,1.496,5.043,1.496c1.863,0,3.641-0.53,5.007-1.488 c0,0,2.016-1.407,4.494-3.136c2.482-1.729,6.9-0.546,9.861,2.664l21.937,23.704c2.966,3.204,3.336,7.47,0.341,8.495 c-1.768,0.609-3.667,0.93-5.643,0.93h-71.635V254.14z M298.896,236.539c0,2.148-0.392,4.22-1.103,6.124 c-1.208,3.24-5.586,2.758-8.551-0.441l-21.566-23.313c-2.961-3.199-2.463-7.823,1.117-10.326l21.881-15.294 c3.58-2.496,7.267-3.604,7.765-2.234c0.294,0.812,0.457,1.681,0.457,2.589V236.539z M287.673,190.949l-39.456,27.556 c-1.493,1.051-4.367,1.044-5.85-0.008l-39.083-27.515c-3.572-2.514-5.799-4.859-4.923-5.05c0.523-0.109,1.059-0.165,1.612-0.165 h91.04c0.554,0,1.087,0.056,1.604,0.165C293.488,186.115,291.253,188.446,287.673,190.949z'></path>{" "}
            <path d='M175.98,161.908h89.665c1.188,0,2.153-0.962,2.153-2.147c0-1.186-0.976-2.148-2.153-2.148H175.98 c-1.183,0-2.148,0.962-2.148,2.148C173.833,160.946,174.797,161.908,175.98,161.908z'></path>{" "}
            <path d='M153.673,170.614h89.665c1.184,0,2.158-0.962,2.158-2.147c0-1.186-0.975-2.148-2.158-2.148h-89.665 c-1.186,0-2.15,0.962-2.15,2.148C151.522,169.652,152.487,170.614,153.673,170.614z'></path>{" "}
            <path d='M153.673,274.716h89.665c1.184,0,2.148-0.965,2.148-2.148c0-1.188-0.965-2.152-2.148-2.152h-89.665 c-1.186,0-2.158,0.965-2.158,2.152C151.515,273.751,152.487,274.716,153.673,274.716z'></path>{" "}
            <path d='M265.645,279.113H175.98c-1.183,0-2.148,0.965-2.148,2.147c0,1.188,0.973,2.148,2.148,2.148h89.665 c1.188,0,2.153-0.96,2.153-2.148C267.798,280.078,266.833,279.113,265.645,279.113z'></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default PhoneEmailicon;
