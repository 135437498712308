import React from "react";

const FloatingWhatsAppButton = () => {
  const whatsappNumber = "5618391680"; // Reemplaza con el número de WhatsApp.
  const message = "¡Hola! necesito de su ayuda ¿Podrian ayudarme?";
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <a
      href={whatsappUrl}
      target='_blank'
      rel='noopener noreferrer'
      style={floatingButtonStyle}
    >
      <img
        src='https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg'
        alt='WhatsApp'
        style={iconStyle}
      />
    </a>
  );
};

const floatingButtonStyle = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  backgroundColor: "#25D366",
  borderRadius: "50%",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
  padding: "10px",
};

const iconStyle = {
  width: "35px",
  height: "35px",
};

export default FloatingWhatsAppButton;
