import { Grid } from "@mui/material";
import React from "react";
import CurrencyFormat from "../../utils/FormatCurrency";
const SaleData = ({ order, client, discount }) => {
  return (
    <>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Fecha Compra:</b>
        {order.fecha}{" "}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Folio:</b>
        {order.id}{" "}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Cliente:</b>
        {client ? client.name : ""}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Subtotal:</b> {CurrencyFormat(order.subtotal ?? 0)}
      </Grid>
      {order.aditional_discount !== 0 && (
        <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
          <b>Descuento Adicional:</b> {order.aditional_discount} %
        </Grid>
      )}
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Total:</b> {CurrencyFormat(order.total ?? 0)}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Descuento:</b>
        {discount ? discount.discount : 0} %
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Costo Envio:</b> {CurrencyFormat(order.shipping_cost ?? 0)}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Total + G.E:</b> {CurrencyFormat(order.total + order.shipping_cost)}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>N° Guia:</b> {order.no_guide}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Paqueteria:</b> {order ? order.package : "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Recibio apoyo de un vendedor:</b>{" "}
        {order ? order.help_for_seller : "N/A"}
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
        <b>Requiere Factura:</b> {order ? order.invoice : "N/A"}
      </Grid>
    </>
  );
};

export default SaleData;
