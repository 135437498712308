import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthContext from "../../Context/auth/AuthContext";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import loginbg from "../../assets/img/login-form__bg.png";

const LogIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { iniciarSesion } = useContext(AuthContext);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const formik = useFormik({
    initialValues: {
      number_phone: "",
      password: "",
    },
    validationSchema: Yup.object({
      number_phone: Yup.string()
        .matches(/^\d{10}$/, "El número debe tener exactamente 10 dígitos")
        .required("Teléfono requerido"),
      password: Yup.string()
        .min(8, "La contraseña debe tener al menos 8 caracteres")
        .required("Contraseña requerida"),
    }),
    onSubmit: (values) => {
      iniciarSesion(values);
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50vh",
        backgroundColor: "#FAF9FF",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 2,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          padding: 4,
          width: "100%",
          backgroundImage: `url(${loginbg})`,
          maxWidth: 400,
          borderRadius: 10,
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Typography variant='h5' fontWeight='bold' mb={2}>
          Iniciar Sesión
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          {/* Campo Número de Teléfono */}
          <FormControl fullWidth margin='normal'>
            <TextField
              id='number_phone'
              name='number_phone'
              label='Número Telefónico'
              variant='outlined'
              value={formik.values.number_phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.number_phone &&
                Boolean(formik.errors.number_phone)
              }
              helperText={
                formik.touched.number_phone && formik.errors.number_phone
              }
              type='tel'
            />
          </FormControl>

          {/* Campo Contraseña */}
          <FormControl fullWidth margin='normal' variant='outlined'>
            <InputLabel
              htmlFor='password'
              error={formik.touched.password && Boolean(formik.errors.password)}
            >
              Contraseña
            </InputLabel>
            <OutlinedInput
              id='password'
              name='password'
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Contraseña'
            />
          </FormControl>
          {formik.touched.password && formik.errors.password && (
            <Typography variant='caption' color='error'>
              {formik.errors.password}
            </Typography>
          )}

          {/* Botón de Enviar */}
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            sx={{
              mt: 3,
              backgroundColor: "#801F56",
              "&:hover": { backgroundColor: "#801F56" },
            }}
          >
            Iniciar
          </Button>
        </form>

        <Box mt={2}>
          <Typography variant='body2'>
            ¿No tienes cuenta?{" "}
            <Link
              to='/registro'
              style={{ textDecoration: "none", color: "#801F56" }}
            >
              Regístrate ahora
            </Link>
          </Typography>
          <Typography variant='body2'>
            <Link
              to='/reset-password'
              style={{ textDecoration: "none", color: "#801F56" }}
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default LogIn;
