import React from "react";
import Select from "react-select";

const CfdiSelect = (props) => {
  const cfdi = [
    { name: "Adquisición de mercancías.", value: "G01" },
    { name: "Gastos en general.", value: "G03" },
    { name: "Sin efectos fiscales.", value: "S01" },
  ];

  const detectarCambiosCfdi = (value) => {
    props.detectarCambiosCfdi(value);
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  // Configurar el valor por defecto desde props o usar un valor estático
  const defaultCfdi = props.defaultCfdi || "G03"; // Valor por defecto si no se pasa un prop

  // Encontrar la opción correspondiente en la lista
  const defaultValue = cfdi
    .map((value) => ({
      label: `${value.value}-${value.name}`,
      value: value.value,
    }))
    .find((option) => option.value === defaultCfdi);

  return (
    <>
      <Select
        onChange={(value) => detectarCambiosCfdi(value)}
        className='basic-single'
        classNamePrefix='select'
        styles={selectStyles}
        name='cfdi_select'
        placeholder='Uso de CFDI'
        options={cfdi.map((value) => ({
          label: `${value.value}-${value.name}`,
          value: value.value,
        }))}
        defaultValue={defaultValue} // Asignar el valor por defecto aquí
      />
    </>
  );
};

export default CfdiSelect;
