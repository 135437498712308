import { Checkout } from "../../components/Checkout/Checkout";
import { PublicLayout } from "../../components/Layout/PublicLayout";

const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Pagar",
    path: "/pagar",
  },
];
const CheckoutPage = () => {
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Pagar'>
      <Checkout />
    </PublicLayout>
  );
};

export default CheckoutPage;
