import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DirectionsContext from "../../Context/Directions/DirectionsContext";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import MethodGet from "../../config/Service";
import { Grid } from "@mui/material";
import StateSelect from "../SelectOptions/StateSelect";
import MunicipalitySelect from "../SelectOptions/MunicipalitySelect";
import Swal from "sweetalert2";
import Spinner from "../../components/loading/Spinner";
export default function UpdateDirection({ open, handleClose, id }) {
  const { updateDirection } = useContext(DirectionsContext);
  const [direction, setDirection] = useState(null);
  const [state, setState] = useState(null);
  const [municipality, setMunicipality] = useState(null);
  const detectarCambiosState = (e) => {
    setState(e.target.value);
  };
  const detectarCambiosMunicipality = (e) => {
    setMunicipality(e.target.value);
  };
  useEffect(() => {
    let url = `/distribucion/Directions/${id}`;
    MethodGet(url)
      .then((res) => {
        setDirection(res.data);
        setState(res.data.state_id);
        setMunicipality(res.data.municipality_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const name = formJson.name;
            const country = formJson.country;
            const street = formJson.street;
            const between_street = formJson.between_street;
            const and_between_street = formJson.and_between_street;
            const number_int = formJson.number_int;
            const number_ext = formJson.number_ext;
            const city = formJson.city;
            const zone = formJson.zone;
            const postal_code = formJson.postal_code;
            const references = formJson.references;
            const phone = formJson.phone;
            const email = formJson.email;
            const data = {};
            data.name = name;
            data.street = street;
            data.country = country;
            data.between_street = between_street;
            data.and_between_street = and_between_street;
            data.number_int = number_int;
            data.number_ext = number_ext;
            data.state = state;
            data.municipality = municipality;
            data.city = city;
            data.zone = zone;
            data.state_id = state;
            data.municipality_id = municipality;
            data.postal_code = postal_code;
            data.references = references;
            data.phone = phone;
            data.email = email;
            data.id = id;
            if (state === null) {
              handleClose();
              Swal.fire({
                title: "¡Cuidado!",
                text: "El campo estado es requerido",
                timer: 2000,
                showConfirmButton: false,
                icon: "warning",
              });
              return;
            }
            if (municipality === null) {
              handleClose();
              Swal.fire({
                title: "¡Cuidado!",
                text: "El campo municipio es requerido",
                timer: 2000,
                showConfirmButton: false,
                icon: "warning",
              });
              return;
            }
            updateDirection(data);
            handleClose();
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
          Editar dirección
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 4 }}>
            Actualiza la dirección en el siguiente cuadro de texto
          </DialogContentText>
          {direction !== null ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Nombre de direccion'
                  name='name'
                  defaultValue={direction.name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Pais'
                  name='country'
                  defaultValue={direction.country}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Calle'
                  name='street'
                  defaultValue={direction.street}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Entre calle'
                  name='between_street'
                  defaultValue={direction.between_street}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Y entre calle'
                  name='and_between_street'
                  defaultValue={direction.and_between_street}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Numero Interior'
                  name='number_int'
                  defaultValue={direction.number_int}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Numero ext.'
                  name='number_ext'
                  defaultValue={direction.number_ext}
                  required
                />
              </Grid>
              {state !== null && (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <StateSelect
                    detectarCambiosState={detectarCambiosState}
                    state_id={state}
                  />
                </Grid>
              )}
              {state !== null && municipality !== null && (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <MunicipalitySelect
                    detectarCambiosMunicipality={detectarCambiosMunicipality}
                    municipality_id={municipality}
                    state_id={state}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Ciudad'
                  name='city'
                  defaultValue={direction.city}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='zona / barrio'
                  name='zone'
                  defaultValue={direction.zone}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <TextField
                  fullWidth
                  label='Codigo Postal'
                  name='postal_code'
                  defaultValue={direction.postal_code}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label='referencias'
                  name='references'
                  defaultValue={direction.references}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  label='Numero de telefono'
                  name='phone'
                  defaultValue={direction.phone}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  label='Correo Electronico'
                  name='email'
                  defaultValue={direction.email}
                  required
                />
              </Grid>
            </Grid>
          ) : (
            <Spinner />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='error' variant='contained'>
            Cancelar
          </Button>
          <Button type='submit' color='success' variant='contained'>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
