import React, { useContext } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import EventsContext from "../../Context/Events/EventsContext";

const Events = () => {
  const { addToCart } = useContext(EventsContext);

  const eventsList = [
    {
      id: 1,
      name: "Concierto de Rock 🎸",
      date: "15 de Febrero, 2025",
      price: 500,
      image:
        "https://i.pinimg.com/736x/98/e6/c0/98e6c05b74c4343e5a37becd3a682dc6.jpg",
    },
    {
      id: 2,
      name: "Festival de Jazz 🎷",
      date: "10 de Marzo, 2025",
      price: 350,
      image:
        "https://i.pinimg.com/236x/10/7f/1e/107f1ea8747d22c5b5f7c17baa879dab.jpg",
    },
    {
      id: 3,
      name: "Obra de Teatro 🎭",
      date: "22 de Abril, 2025",
      price: 250,
      image:
        "https://i.pinimg.com/236x/5d/71/c3/5d71c3e244282a957028aae8ecfc87df.jpg",
    },
  ];

  return (
    <Grid
      container
      spacing={3}
      sx={{ mt: 3, px: 2, mb: 5, backgroundColor: "#FBFAFF" }}
    >
      {eventsList.map((event) => (
        <Grid item xs={12} sm={6} md={4} key={event.id}>
          <Card sx={{ maxWidth: 400, boxShadow: 3, borderRadius: 3 }}>
            <CardMedia
              component='img'
              height='200'
              image={event.image}
              alt={event.name}
            />
            <CardContent>
              <Typography variant='h6' fontWeight='bold'>
                {event.name}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                📅 {event.date}
              </Typography>
              <Typography variant='h6' color='primary' sx={{ mt: 1 }}>
                💰 ${event.price} MXN
              </Typography>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                sx={{ mt: 2, borderRadius: 2 }}
                onClick={() => addToCart(event, 1)}
              >
                Comprar Boleto 🎟️
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Events;
