import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import { IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D8708E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ListaProductos({ productsList, saveProductsList }) {
  // localStorage.setItem("EnterProducts", JSON.stringify(productsList));
  const classes = useStyles();
  const eliminarProducto = (value) => {
    const todosProducto = productsList.filter((p) => p.product_id !== value);
    saveProductsList(todosProducto);
  };

  const handleChangeQuantity = (e, i) => {
    const newQuantity = Number(e.target.value);
    const todosProducto = [...productsList];
    todosProducto[i].quantity = newQuantity;
    localStorage.setItem("EnterProducts", JSON.stringify(todosProducto));
    saveProductsList(todosProducto);
  };
  return (
    <TableContainer component={Paper}>
      <div style={{ height: "380px", overflowY: "auto" }}>
        <Table stickyHeader aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'>Codigo de barras</StyledTableCell>
              <StyledTableCell align='center'>Producto</StyledTableCell>
              <StyledTableCell align='center'>Precio</StyledTableCell>
              <StyledTableCell align='center'>Cantidad</StyledTableCell>
              <StyledTableCell align='center'>Stock Actual</StyledTableCell>
              <StyledTableCell align='center'>Status</StyledTableCell>
              <StyledTableCell align='center'>Opciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsList.map((row, index) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell align='center'>{row.barcode}</StyledTableCell>
                <StyledTableCell align='center'>{row.name}</StyledTableCell>
                <StyledTableCell align='center'>{row.price}</StyledTableCell>
                <StyledTableCell align='center'>
                  <input
                    //type="number"
                    //onChange={detectarCambiosCantidadComprada}
                    className={classes.jss59}
                    placeholder='Agrega la Cantidad Inicial'
                    name='quantityProduct'
                    min={1}
                    max={row.stock_product}
                    value={row.quantity}
                    onChange={(e) => handleChangeQuantity(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.stock_product}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.stock_product > 0 ? (
                    <Typography
                      sx={{
                        backgroundColor: "green",
                        color: "white",
                        padding: 2,
                        borderRadius: 2,
                      }}
                    >
                      Activo
                    </Typography>
                  ) : (
                    row.stock_product === 0 && (
                      <Typography
                        sx={{
                          backgroundColor: "red",
                          color: "white",
                          padding: 2,
                          borderRadius: 2,
                        }}
                      >
                        Desactivado
                      </Typography>
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  <Tooltip title='Borrar' placement='top'>
                    <IconButton
                      onClick={() => eliminarProducto(row.product_id)}
                      variant='outlined'
                      sx={{
                        color: "red",
                        borderColor: "red",
                        "&:hover": { color: "red", borderColor: "red" },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
}
