import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@material-ui/core";
import FormatCurrency from "../../utils/FormatCurrency";
import { Button, Stack, Typography } from "@mui/material";

import ImageIcon from "@mui/icons-material/Image";
import ModalForImage from "./ModalImage";
import { useState } from "react";
import { CalUnitPrice } from "../../utils/CalUnitPrice";
const TableProductSale = ({ product, activeTab }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell align='center'>
          <Stack direction='row' spacing={2}>
            <Button
              variant='contained'
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#EA90BD",
                color: "white",
                "&:hover": { backgroundColor: "#EA90BD", color: "white" },
              }}
              endIcon={<ImageIcon />}
            >
              Ver
            </Button>
          </Stack>
        </TableCell>
        <TableCell align='center'>{product?.product?.barcode}</TableCell>{" "}
        {/* Codigo de barras */}
        <TableCell align='center'>{product?.product?.name}</TableCell>{" "}
        {/* Nombre del producto */}
        <TableCell align='center'>
          <>
            <Typography>
              {" "}
              {FormatCurrency(product.product.retail_price ?? 0)}{" "}
              {/* Precio unitario */}
            </Typography>
          </>
        </TableCell>
        <TableCell align='center'>
          <Typography fontWeight='bold'>{product?.quantity} </Typography>{" "}
          {/* Cantidad */}
        </TableCell>
        <TableCell align='center'>
          <Typography>
            {FormatCurrency(
              product?.product.retail_price * product.quantity ?? 0
            )}{" "}
            {/* Subtotal */}
          </Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
            {FormatCurrency(product.final_price ?? 0)}
          </Typography>
        </TableCell>
        <TableCell align='center'>
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
            $ {product.final_price * product.quantity ?? 0}
          </Typography>
        </TableCell>
      </TableRow>
      <ModalForImage
        open={open}
        handleClose={handleClose}
        image={product?.product?.image}
      />
    </>
  );
};
TableProductSale.propTypes = {
  product: PropTypes.object.isRequired,
};
export default TableProductSale;
