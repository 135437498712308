import * as Yup from "yup";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import imgbgregister from "../../assets/img/registration-form__bg.png";

import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button,
  Typography,
} from "@mui/material";
import AuthContext from "../../Context/auth/AuthContext";

const ResetPassword = () => {
  const { resetPassword } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const formik = useFormik({
    initialValues: {
      number_phone: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      number_phone: Yup.string()
        .length(10, "El número de teléfono debe tener exactamente 10 dígitos")
        .required("Teléfono requerido"),
      password: Yup.string()
        .min(8, "Mínimo 8 caracteres")
        .required("Requerido"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
        .required("Requerido"),
    }),
    onSubmit: (formData) => {
      resetPassword(formData);
    },
  });

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        minHeight: "70vh",
        backgroundSize: "cover",
      }}
    >
      <Box
        component='form'
        onSubmit={formik.handleSubmit}
        sx={{
          p: 4,
          maxWidth: 400,
          bgcolor: "white",
          boxShadow: 3,
          backgroundImage: `url(${imgbgregister})`,
          borderRadius: 2,
        }}
      >
        <Typography variant='h5' textAlign='center' gutterBottom>
          Recuperar Contraseña
        </Typography>

        <TextField
          fullWidth
          id='number_phone'
          label='Número Telefónico'
          name='number_phone'
          type='tel'
          value={formik.values.number_phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.number_phone && Boolean(formik.errors.number_phone)
          }
          helperText={formik.touched.number_phone && formik.errors.number_phone}
          margin='normal'
        />

        <FormControl
          fullWidth
          variant='outlined'
          margin='normal'
          error={formik.touched.password && Boolean(formik.errors.password)}
        >
          <InputLabel htmlFor='password'>Nueva Contraseña</InputLabel>
          <OutlinedInput
            id='password'
            name='password'
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={toggleShowPassword} edge='end'>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label='Nueva Contraseña'
          />
          {formik.touched.password && formik.errors.password && (
            <FormHelperText>{formik.errors.password}</FormHelperText>
          )}
        </FormControl>

        <FormControl
          fullWidth
          variant='outlined'
          margin='normal'
          error={
            formik.touched.password_confirmation &&
            Boolean(formik.errors.password_confirmation)
          }
        >
          <InputLabel htmlFor='password_confirmation'>
            Confirmar Contraseña
          </InputLabel>
          <OutlinedInput
            id='password_confirmation'
            name='password_confirmation'
            type={showConfirmPassword ? "text" : "password"}
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={toggleShowConfirmPassword} edge='end'>
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label='Confirmar Contraseña'
          />
          {formik.touched.password_confirmation &&
            formik.errors.password_confirmation && (
              <FormHelperText>
                {formik.errors.password_confirmation}
              </FormHelperText>
            )}
        </FormControl>

        <Button
          fullWidth
          type='submit'
          variant='contained'
          color='primary'
          sx={{
            backgroundColor: "#801F56",
            "&:hover": { backgroundColor: "#801F56" },
          }}
        >
          Reestablecer Contraseña
        </Button>

        <Typography textAlign='center' sx={{ mt: 2 }}>
          ¿Ya la recordaste?{" "}
          <Link to='/iniciar-sesion' style={{ color: "#801F56" }}>
            Inicia Ahora
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ResetPassword;
