import { Faq } from "../../components/Faq/Faq";
import { PublicLayout } from "../../components/Layout/PublicLayout";

const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Preguntas Frecuentes",
    path: "/preguntas-frecuentes",
  },
];
const FaqPage = () => {
  return (
    <PublicLayout
      breadcrumb={breadcrumbsData}
      breadcrumbTitle='Preguntas Frecuentes'
    >
      <Faq />
    </PublicLayout>
  );
};

export default FaqPage;
