import React, { useEffect, useState } from "react";
import Select from "react-select";
import MethodGet from "../../config/Service";
const ProductsSelect = (props) => {
  const [products, setProducts] = useState();
  useEffect(() => {
    let url = "/allProducts";
    MethodGet(url)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const detectarCambiosProduct = (value) => {
    props.detectarCambiosProduct(value);
  };
  const nuevoArreglo =
    products &&
    products.map((prod) => {
      const accountName = `${prod.tag} - ${prod.name} `;
      return { value: prod.id, label: accountName };
    });
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  return (
    <Select
      fullwith
      styles={selectStyles}
      onChange={(value) => detectarCambiosProduct(value)}
      className='basic-single'
      classNamePrefix='select'
      // isLoading={isLoading}
      name='help_for_seller'
      placeholder='Selecciona un producto'
      options={nuevoArreglo}
    />
  );
};

export default ProductsSelect;
