import React, { useContext } from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@material-ui/core";
import FormatCurrency from "../../../utils/FormatCurrency";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ModalTicketPayment from "../../../components/Cart/ModalTicketPayment";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { PackageIcon } from "../../../components/icons/PackageIcon";
import { ShippIcon } from "../../../components/icons/ShippIcon";
import { UploadFile } from "@mui/icons-material";
import { UploadFileIcon } from "../../../components/icons/UploadFileIcon";
import OrdersContext from "../../../Context/Orders/OrdersContext";
import ModalShipping from "./ModalShipping";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "20%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
const TableOrdersAdmin = ({ product, activeTab }) => {
  const { AproveOrder, TakeOrder, PdfOrder } = useContext(OrdersContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [order_id, setOrderId] = useState(null);
  const handleClickOpen = (id) => {
    setOrderId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOrderId(null);
    setOpen(false);
  };

  const [openM, setOpenM] = useState(false);
  const [total, setTotal] = useState(null);
  const [envio, setEnvio] = useState(null);

  const handleClickOpenM = (id, total, envio) => {
    setOrderId(id);
    setTotal(total);
    setEnvio(envio);
    setOpenM(true);
  };

  const handleCloseM = () => {
    setOrderId(null);
    setTotal(null);
    setOpenM(false);
  };

  const miImagen = require("../../../assets/img/default.webp");
  return (
    <>
      <TableRow>
        <TableCell align='center'># {product.id}</TableCell>{" "}
        {/* Codigo de barras */}
        <TableCell align='center'>{product.name}</TableCell>{" "}
        <TableCell align='center'>{product.number_phone}</TableCell>{" "}
        {/* Nombre del producto */}
        <TableCell align='center'>
          <>
            <Typography>
              {" "}
              {FormatCurrency(product.subtotal ?? 0)} {/* Precio unitario */}
            </Typography>
          </>
        </TableCell>
        <TableCell align='center'>
          <>
            <Typography>
              {" "}
              {FormatCurrency(product.envio ?? 0)} {/* Precio unitario */}
            </Typography>
          </>
        </TableCell>
        <TableCell align='center'>
          <Typography fontWeight='bold'>
            {FormatCurrency(product.total)}{" "}
          </Typography>{" "}
          {/* Cantidad */}
        </TableCell>
        <TableCell align='center'>
          <Typography>{product.fecha} </Typography> {/* Cantidad */}
        </TableCell>
        <TableCell align='center'>
          <Typography>{product.vendedor ?? "N/A"} </Typography> {/* Cantidad */}
        </TableCell>
        <TableCell align='center'>
          <Tooltip title='Detalle' placement='top'>
            <Link to={`Detalle-venta/${product.id}`}>
              <IconButton>
                <RemoveRedEyeIcon sx={{ color: "#E6A2B6" }} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title='Descargar PDF' placement='top'>
            <IconButton onClick={() => PdfOrder(product.id)}>
              <PictureAsPdfIcon sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
          {product.status === 0 &&
            product.payments
              .map((payment) => payment.amount)
              .reduce((total, amount) => total + amount, 0) < product.total && (
              <Tooltip title='Subir comprobante' placement='top'>
                <IconButton
                  onClick={() =>
                    handleClickOpenM(product.id, product.total, product.envio)
                  }
                >
                  <UploadFileIcon width={30} />
                </IconButton>
              </Tooltip>
            )}
          {product.status === 1 && product.date_validate_payment !== null && (
            <Tooltip title='Tomar Pedido' placement='top'>
              <IconButton onClick={() => TakeOrder(product.id)}>
                <PackageIcon width={30} />
              </IconButton>
            </Tooltip>
          )}
          {product.status === 2 && (
            <Tooltip title='Enviar Pedido' placement='top'>
              <IconButton onClick={() => handleClickOpen(product.id)}>
                <ShippIcon width={30} />
              </IconButton>
            </Tooltip>
          )}
          {product.status === 1 && product.date_validate_payment === null && (
            <Tooltip title='Aprobar pago'>
              <IconButton onClick={() => AproveOrder(product.id)}>
                <DoneAllIcon sx={{ "&:hover": { color: "#0277bd" } }} />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <>
        {order_id && (
          <ModalShipping
            open={open}
            handleClose={handleClose}
            order_id={order_id}
          />
        )}
        {order_id !== null && total !== null && (
          <ModalTicketPayment
            open={openM}
            handleClose={handleCloseM}
            order_id={order_id}
            total={total}
            envio={envio}
            total_pagado={
              product.payments
                ? product.payments.reduce((total, p) => total + p.amount, 0)
                : 0
            }
          />
        )}
      </>
    </>
  );
};
TableOrdersAdmin.propTypes = {
  product: PropTypes.object.isRequired,
};
export default TableOrdersAdmin;
