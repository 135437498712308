export const SalesDayIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 511.998 511.998'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <circle
          style={{ fill: "#CFF09E" }}
          cx='255.994'
          cy='255.995'
          r='163.504'
        ></circle>{" "}
        <g>
          {" "}
          <path
            style={{ fill: "#507C5C" }}
            d='M496.108,240.448c-8.588,0-15.553,6.963-15.553,15.553c0,124.008-100.888,224.896-224.896,224.896 c-49.907,0-98.088-16.509-137.309-46.773l32.959-32.959c29.463,21.307,65.632,33.886,104.689,33.886 c98.727,0,179.05-80.321,179.05-179.048c0-42.094-14.61-80.834-39.012-111.437l95.368,25.554c1.33,0.356,2.683,0.53,4.023,0.53 c0.059,0,0.118,0,0.177,0c8.521-0.078,15.406-7.011,15.406-15.553c0-1.754-0.291-3.44-0.824-5.014L477.274,27.248 c-1.439-5.367-5.63-9.559-10.997-10.997c-5.37-1.442-11.094,0.096-15.022,4.025l-35.463,35.461C370.585,19.707,314.491,0,256.337,0 C115.18,0,0.338,114.841,0.338,256.001c0,8.59,6.964,15.553,15.553,15.553s15.553-6.963,15.553-15.553 c0-124.008,100.888-224.896,224.894-224.896c49.906,0,98.088,16.509,137.309,46.773l-32.959,32.959 c-29.463-21.307-65.632-33.886-104.689-33.886c-98.727,0-179.05,80.321-179.05,179.048c0,42.094,14.61,80.834,39.012,111.437 l-95.368-25.554c-5.37-1.44-11.094,0.098-15.022,4.025c-3.929,3.929-5.464,9.655-4.025,15.022l33.178,123.826 c1.439,5.367,5.63,9.559,10.997,10.997c1.33,0.356,2.683,0.53,4.023,0.53c4.072,0,8.044-1.6,10.999-4.555l35.463-35.461 c45.207,36.026,101.3,55.732,159.454,55.732c141.159,0,256.001-114.841,256.001-256.001 C511.66,247.411,504.697,240.448,496.108,240.448z M454.2,61.314l19.235,71.786l-71.786-19.235L454.2,61.314z M256,108.056 c81.578,0,147.945,66.367,147.945,147.943S337.578,403.943,256,403.943s-147.945-66.367-147.945-147.943 S174.421,108.056,256,108.056z M57.799,450.686L38.563,378.9l71.786,19.235L57.799,450.686z'
          ></path>{" "}
          <path
            style={{ fill: "#507C5C" }}
            d='M207.433,188.893c21.299,0,42.78,13.402,42.78,38.006c0,39.842-56.734,52.879-56.734,69.585v1.653 h48.106c4.588,0,8.629,5.691,8.629,12.3c0,6.61-4.039,12.669-8.629,12.669H176.22c-5.14,0-12.486-3.488-12.486-8.814v-17.809 c0-28.825,57.835-45.35,57.835-68.667c0-5.876-3.674-13.035-13.954-13.035c-7.345,0-13.77,3.672-13.77,13.769 c0,5.325-5.694,10.467-15.056,10.467c-7.345,0-12.853-3.305-12.853-14.871C165.938,201.746,186.502,188.893,207.433,188.893z'
          ></path>{" "}
          <path
            style={{ fill: "#507C5C" }}
            d='M302.914,294.832h-42.965c-5.324,0-9.546-3.488-9.546-9.913c0-1.47,0.365-3.49,1.468-5.509 l42.412-83.173c2.754-5.324,7.345-7.344,11.933-7.344c4.96,0,14.322,4.223,14.322,10.832c0,1.101-0.365,2.202-0.918,3.488 l-32.499,65.179h15.79V255.54c0-6.058,7.16-8.629,14.319-8.629c7.162,0,14.322,2.569,14.322,8.629v12.853h7.894 c5.876,0,8.814,6.61,8.814,13.22c0,6.61-4.406,13.22-8.814,13.22h-7.894v19.462c0,5.876-7.16,8.814-14.322,8.814 c-7.16,0-14.319-2.938-14.319-8.814v-19.462H302.914z'
          ></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
