import React from "react";
import MethodGet, { MethodDelete, MethodPost } from "../../config/Service";
import headerConfig from "../../config/imageHeaders";
import { useReducer } from "react";
import DepartureProductsReducer from "./DepartureProductsReducer";
import DepartureProductsContext from "./DepartureProductsContext";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";
import clienteAxios from "../../config/Axios";
import {
  CREATE_DEPARTURE_PRODUCTS,
  GET_ALL_DEPARTURE_PRODUCTS,
} from "../../types";
const DepartureProductsState = ({ children }) => {
  const initialState = {
    departure_products: [],
    products_b: [],
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(DepartureProductsReducer, initialState);
  //Funcion para obtener todos los productos auth
  const GetDepartureProducts = () => {
    let url = `/departures`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_DEPARTURE_PRODUCTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CreateDepartureProducts = (data) => {
    let url = "/departures";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: CREATE_DEPARTURE_PRODUCTS,
          payload: res.data,
        });
        Swal.fire({
          title: "Registrado",
          text: "La salida de productos se ha creado exitosamente!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "error",
          showConfirmButton: false,
          icon: "error",
          text: error.response.data.message,
          timer: 1500,
        });
      });
  };

  const DownloadPdfDepartureProducts = (id) => {
    Swal.fire({
      title: "¿Descargar PDF?",
      text: "Se descargará el reporte de salidas en PDF",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/departures/${id}/report`;
        clienteAxios
          .get(url, { responseType: "blob" })
          .then((res) => {
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
              showLoading: true,
            }).then((result) => {
              fileDownload(res.data, `Reporte_salidas_${id}.pdf`);
              Swal.fire({
                title: "Descargado",
                text: "El PDF de las salidas se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: error.response.data.message,
            });
          });
      }
    });
  };
  return (
    <DepartureProductsContext.Provider
      value={{
        departure_products: state.departure_products,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetDepartureProducts,
        CreateDepartureProducts,
        DownloadPdfDepartureProducts,
      }}
    >
      {children}
    </DepartureProductsContext.Provider>
  );
};

export default DepartureProductsState;
