import React, { useReducer } from "react";
import DirectionsReducer from "./DirectionsReducer";
import DirectionsContext from "./DirectionsContext";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  DELETE_DIRECTION,
  GET_ALL_DIRECTIONS_USER,
  SET_DIRECTION_DEFAULT,
  STORE_DIRECTION,
  UPDATE_DIRECTION,
} from "../../types";
import Swal from "sweetalert2";
const DirectionsState = ({ children }) => {
  const initialState = {
    directions: [],
    success: false,
    ErrorsApi: [],
  };
  const [state, dispatch] = useReducer(DirectionsReducer, initialState);
  const GetDirections = () => {
    let url = "/distribucion/Directions";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_DIRECTIONS_USER,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const storeDirection = (data) => {
    let url = "/distribucion/Directions";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: STORE_DIRECTION,
          payload: res.data,
        });
        Swal.fire({
          title: "Creada",
          text: "La Direccion se ha creado correctamente!",
          icon: "success",
          timer: 1700,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error.message, "error store direccion");
        Swal.fire({
          title: "Error",
          showConfirmButton: false,
          timer: 2500,
          icon: "error",
          text: error.response.data.message,
        });
      });
  };
  const updateDirection = (data) => {
    let url = `/distribucion/Directions/${data.id}`;
    MethodPut(url, data)
      .then((res) => {
        console.log(res);
        dispatch({
          type: UPDATE_DIRECTION,
          payload: res.data,
        });
        Swal.fire({
          title: "Actualizada",
          text: "La dirección se ha actualizado correctamente!",
          icon: "success",
          timer: 1700,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Atención",
          text: "Ocurrio un error, mientras se actualizaba la información",
          timer: 2500,
          showConfirmButton: false,
          icon: "warning",
        });
        console.log(error, "error update direccion");
      });
  };
  const DeleteDirection = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La Direccion seleccionada será eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/distribucion/Directions/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_DIRECTION,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const setIsDefaultDirection = (id) => {
    Swal.fire({
      title: "Direccion por defecto",
      text: "La dirección seleccionada se marcara como direcion predeterminada para los envios ¿Quieres continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/distribucion/setIsDefaultDirection/${id}`;
        MethodPost(url)
          .then((res) => {
            dispatch({
              type: SET_DIRECTION_DEFAULT,
              payload: res.data,
            });
            Swal.fire({
              icon: "success",
              timer: 2000,
              text: "la direccion se ha establecido como predeterminada correctamente",
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  return (
    <DirectionsContext.Provider
      value={{
        directions: state.directions,
        success: state.success,
        ErrorsApi: state.ErrorsApi,
        GetDirections,
        storeDirection,
        updateDirection,
        DeleteDirection,
        setIsDefaultDirection,
      }}
    >
      {children}
    </DirectionsContext.Provider>
  );
};

export default DirectionsState;
