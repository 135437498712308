import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
export const CheckoutStep3 = () => {
  return (
    <Box sx={{ p: 3 }}>
      {/* Título */}
      <Typography variant='h4' gutterBottom>
        MerryColor
        <br />
        Gracias por su compra!
      </Typography>

      {/* Descripción */}
      <Typography variant='body1' paragraph>
        Te agradecemos sinceramente por elegirnos. En MerryColor, nos
        enorgullece ofrecerte productos de calidad y un servicio excepcional. Tu
        pedido está siendo procesado, y pronto estará en camino hacia ti. Si
        tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos.
        ¡Esperamos verte pronto nuevamente!
      </Typography>

      {/* Información de la orden */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body1'>
            <strong>Status de Orden:</strong> Pendiente de pago
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to='/profile'>
            <Button
              variant='contained'
              sx={{
                backgroundColor: "#E59DB0",
                color: "white",
                "&:hover": { backgroundColor: "#E59DB0", color: "white" },
              }}
            >
              ver mis pedidos
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
