import { useContext, useEffect, useState } from "react";
import MethodGet from "../../../config/Service";
import CartContext from "../../../Context/Cart/CartContext";
import Swal from "sweetalert2";
import showToast from "../../../utils/ShowToast";
import CurrencyFormat from "../../../utils/FormatCurrency";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ShoppingCart } from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Thumbs } from "swiper/modules";
import Spinner from "../../loading/Spinner";
import CardProduct from "../CardProduct";
import RelatedProductsSlider from "./RelatedProducts";

export const ProductDetails = ({ id }) => {
  const defaultImage = require("../../../assets/img/default .png");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { cart, setCart } = useContext(CartContext);

  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState(null);
  const [randomProducts, setRandomProducts] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (id) {
      MethodGet(`/distribucion/products/${id}`)
        .then((res) => {
          setProduct(res.data.product);
          setRelatedProducts(res.data.relatedProducts);
          setRandomProducts(res.data.randomProducts);
        })
        .catch((error) =>
          console.error("Error al obtener la información del producto:", error)
        );
    }
  }, [id]);
  const handleAddToCart = (prod) => {
    Swal.fire({
      title: "¿Cuántas piezas deseas agregar?",
      html: `
        <input
          type="number"
          id="quantity"
          class="swal2-input"
          placeholder="1"
          min="1"
          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
        />
      `,
      showCancelButton: true,
      confirmButtonText: "Agregar",
      preConfirm: () => {
        const quantity = parseInt(
          Swal.getPopup().querySelector("#quantity").value,
          10
        );
        if (!quantity || quantity <= 0) {
          Swal.showValidationMessage("La cantidad debe ser mayor a 0");
        }
        return { quantity };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const quantity = result.value.quantity;
        if (quantity > prod.stock) {
          showToast("warning", "Stock insuficiente", "No hay suficiente stock");
          return;
        }

        const updatedCart = [...cart];
        const productIndex = updatedCart.findIndex(
          (item) => item.product_id === prod.id
        );

        if (productIndex !== -1) {
          // Producto ya en el carrito
          updatedCart[productIndex].cantidad += quantity;
        } else {
          // Producto nuevo
          updatedCart.push({
            product_id: prod.id,
            name: prod.name,
            price: prod.price,
            cantidad: quantity,
            image: prod.img?.[0]?.image || defaultImage,
            final_price: prod.price * quantity,
          });
        }

        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));

        showToast(
          "success",
          "Producto agregado",
          `Se agregaron ${quantity} piezas`
        );
      }
    });
  };

  if (!product) return <Spinner />;

  const {
    img,
    category,
    subcategory,
    name,
    price_purchase,
    description,
    stock,
    price,
  } = product;

  const renderProductImages = () =>
    img?.length ? (
      img.map((image, index) => (
        <SwiperSlide key={index}>
          <img
            src={image.image}
            alt={`Imagen ${index + 1}`}
            style={{ width: "50%", borderRadius: "8px" }}
          />
        </SwiperSlide>
      ))
    ) : (
      <SwiperSlide>
        <img
          src={defaultImage}
          alt='Imagen por defecto'
          style={{ width: "50%", borderRadius: "8px" }}
        />
      </SwiperSlide>
    );

  const renderProductSection = (label, products) => (
    <Box sx={{ mt: 3 }}>
      <Divider>
        <Chip
          label={label}
          sx={{
            p: 2,
            mb: 3,
            mt: 3,
            fontWeight: "bold",
            backgroundColor: "#801F56",
            color: "white",
          }}
        />
      </Divider>
      <Box sx={{ mb: 5 }}>
        <RelatedProductsSlider relatedProducts={products} />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ padding: 3, backgroundColor: "transparent" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Swiper
            modules={[Navigation, Thumbs]}
            navigation
            thumbs={{ swiper: thumbsSwiper }}
          >
            {renderProductImages()}
          </Swiper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant='h4'>{name}</Typography>
            <Typography color={stock > 0 ? "green" : "red"}>
              {stock > 0 ? "En stock" : "Sin stock"}
            </Typography>
            <Typography variant='h6' sx={{ mt: 1 }}>
              Precio: {CurrencyFormat(price)}
            </Typography>
            <Typography>Categoría: {category?.name}</Typography>
            <Typography>Subcategoría: {subcategory?.name}</Typography>
            <Typography sx={{ mt: 2 }}>{description}</Typography>
            <Button
              variant='contained'
              sx={{
                mt: 3,
                backgroundColor: "#D87390",
                "&:hover": { backgroundColor: "#C0627D" },
              }}
              onClick={() => handleAddToCart(product)}
            >
              <ShoppingCart sx={{ mr: 1 }} />
              Agregar al carrito
            </Button>
          </Box>
        </Grid>
      </Grid>
      {renderProductSection("Productos Relacionados", relatedProducts)}
      {renderProductSection("Podrían interesarte", randomProducts)}
    </Box>
  );
};
