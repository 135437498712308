import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CurrencyFormat from "../../utils/FormatCurrency";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MethodGet from "../../config/Service";
import ModalImage from "react-modal-image-responsive";
import Direction from "./Direction";
import { PublicLayout } from "../../components/Layout/PublicLayout";
import SaleData from "./SaleData";
import TableSaleProducts from "./TableSaleProducts";
import { makeStyles } from "@mui/styles";
import Spinner from "../../components/loading/Spinner";
const useStyles = makeStyles({
  img: {
    width: "100%",
    height: 350,
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
const ShowOrder = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [order, saveOrder] = useState([]);
  const [payments, setPayments] = useState(null);
  const [client, setClient] = useState(null);
  const [products, setProducts] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [direction, setDirection] = useState(null);
  useEffect(() => {
    let url = `/distribucion/Sales/${id}`;
    MethodGet(url)
      .then((res) => {
        saveOrder(res.data.venta);
        setPayments(res.data.venta.payments);
        setClient(res.data.venta.client);
        setProducts(res.data?.venta?.details);
        setDiscount(res.data.venta.business);
        setDirection(res.data.venta.direction);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  const breadcrumbsData = [
    {
      label: "Inicio",
      path: "/",
    },
    {
      label: "Detalle de venta",
      path: "",
    },
  ];

  return (
    <PublicLayout
      breadcrumb={breadcrumbsData}
      breadcrumbTitle='Detalle de venta'
    >
      <Paper elevation={6} padding={2}>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": { backgroundColor: "black", color: "white" },
              }}
              onClick={() => props.history.goBack()}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider>
              <Chip label='Detalle de venta' sx={{ fontWeight: "bold" }} />
            </Divider>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {order ? (
              <Card boxShadow={10} sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <SaleData order={order} client={client} discount={discount} />

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider>
                      <Chip
                        label='Dirección de envío'
                        sx={{ fontWeight: "bold" }}
                      />
                    </Divider>
                  </Grid>
                  <Divider />
                  {direction !== null && <Direction direction={direction} />}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider>
                      <Chip
                        label='Detalle de pedido'
                        sx={{ fontWeight: "bold" }}
                      />
                    </Divider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {products !== null && (
                      <TableSaleProducts
                        products_list={products}
                        discount={discount}
                      />
                    )}
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <Spinner />
            )}
          </Grid>
          {payments !== null && payments.length > 0 && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider>
                  <Chip label='Detalles de pagos' sx={{ fontWeight: "bold" }} />
                </Divider>
              </Grid>
              {payments.map((payment, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        Monto Pagado: {CurrencyFormat(payment.amount)}
                      </Typography>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        Referencia: {payment.reference}
                      </Typography>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        Comprobante:
                      </Typography>
                      <ModalImage
                        className={classes.img}
                        small={payment.image}
                        large={payment.image}
                        alt={payment.reference}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Paper>
    </PublicLayout>
  );
};

export default ShowOrder;
