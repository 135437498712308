import Login from "../../../components/Auth/LogIn";
import { PublicLayout } from "../../../components/Layout/PublicLayout";

const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Iniciar Sesión",
    path: "/login",
  },
];
const LoginPage = () => {
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Iniciar Sesión'>
      <Login />
    </PublicLayout>
  );
};

export default LoginPage;
