import { PublicLayout } from "../../components/Layout/PublicLayout";
import PoliciesBack from "../../components/PoliciesBack/PoliciesBack";

const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Politicas de devolución",
    path: "/policies-privacy",
  },
];
const PoliciesBackPage = () => {
  return (
    <PublicLayout
      breadcrumb={breadcrumbsData}
      breadcrumbTitle='Politicas de devolución'
    >
      <PoliciesBack />
    </PublicLayout>
  );
};

export default PoliciesBackPage;
