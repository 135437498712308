import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Grid,
  CircularProgress,
  Typography,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import TableProductsAdmin from "./TableProductsAdmin";
import { Link } from "react-router-dom";
import ProductsContext from "../../../Context/Products/ProductsContext";

const AllProductsAdmin = () => {
  const [loading, setLoading] = useState(false);
  const { GetAllProducts, products } = useContext(ProductsContext);

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // Buscador
  const [searchTerm, setSearchTerm] = useState("");

  // Manejo de cambios en el buscador
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reinicia la paginación al realizar una búsqueda
  };

  // Filtrar productos basados en el término de búsqueda
  const filteredProducts = products.filter((product) => {
    const term = searchTerm.toLowerCase();
    return (
      product.name?.toLowerCase().includes(term) ||
      product.description?.toLowerCase().includes(term) ||
      product.barcode?.toLowerCase().includes(term)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      await GetAllProducts();
      setLoading(false);
    };

    fetchProducts();
  }, []);

  return (
    <Grid container spacing={2} sx={{ padding: 2, marginTop: -5 }}>
      {/* Botón Agregar Producto */}
      <Grid
        item
        xs={9}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          label='Buscar productos'
          variant='outlined'
          size='small'
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <Link to='/Registrar-producto'>
          <Button variant='contained'>Agregar Producto</Button>
        </Link>
      </Grid>

      {/* Spinner de carga */}
      {loading ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size={50} />
        </Grid>
      ) : filteredProducts.length > 0 ? (
        <>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label='simple table' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Imagen</TableCell>
                    <TableCell align='center'>C.barras</TableCell>
                    <TableCell align='center'>Producto</TableCell>
                    <TableCell align='center'>Descripción</TableCell>
                    <TableCell align='center'>
                      <Typography sx={{ fontSize: 12 }}>
                        Precio Unit.
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>Stock</TableCell>
                    <TableCell align='center'>Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((product, index) => (
                      <TableProductsAdmin
                        key={index}
                        product={product}
                        discount={0}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component='div'
              count={filteredProducts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Registros por página'
            />
          </Grid>
        </>
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Typography variant='h6' color='textSecondary'>
            No se encontraron productos.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AllProductsAdmin;
