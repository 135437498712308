import { useEffect } from "react";
import { Box, Typography, Divider, Grid, Paper } from "@mui/material";
import FormatCurrency from "../../../utils/FormatCurrency";
import { Card } from "./Card/Card";
import MethodGet from "../../../config/Service";

export const CheckoutOrders = ({
  total,
  setTotal,
  discount,
  setDiscount,
  couponDiscount,
  setCouponDiscount,
  shippingCost,
  setShippingCost,
  setIdBusiness,
  cart,
  sumaSubtotal,
  setAditional,
  setCouponId,
}) => {
  // Calcular subtotal
  sumaSubtotal = cart.reduce((acc, item) => acc + Number(item.final_price), 0);

  // Obtener descuento por reglas de negocio
  useEffect(() => {
    const url = `/distribucion/Discount/${sumaSubtotal}`;
    MethodGet(url)
      .then((res) => {
        setDiscount(res.data.discount || 0);
        setIdBusiness(res.data.id || null);
      })
      .catch((error) => {
        console.error("Error fetching discount:", error);
      });
  }, [sumaSubtotal]);

  // Obtener descuento por cupón
  useEffect(() => {
    const storedCoupon = localStorage.getItem("appliedCoupon");
    const { discount, id } = JSON.parse(storedCoupon) || {};
    setAditional(discount || 0); // Guardar el cupón en el estado
    setCouponId(id || null); // Guardar el id del cupón en el estado
    if (storedCoupon && discount !== undefined) {
      const couponValue = Number(discount) || 0;
      setCouponDiscount(couponValue);
    } else {
      setCouponDiscount(0);
    }
  }, [sumaSubtotal]);

  // Calcular total incluyendo descuentos
  useEffect(() => {
    const discountFromBusiness = sumaSubtotal * (discount / 100);
    const discountFromCoupon =
      (sumaSubtotal - discountFromBusiness) * (couponDiscount / 100);
    const finalTotal = sumaSubtotal - discountFromBusiness - discountFromCoupon;
    setTotal(finalTotal);
  }, [sumaSubtotal, discount, couponDiscount]);

  // Calcular costo de envío
  useEffect(() => {
    if (total > 0 && total < 7999) {
      setShippingCost(200);
    } else if (total >= 8000) {
      setShippingCost(400);
    } else {
      setShippingCost(0);
    }
  }, [total]);

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      {/* Título */}
      <Typography variant='h5' gutterBottom>
        Tu pedido
      </Typography>
      <Divider sx={{ mb: 2 }} />

      {/* Items del carrito */}
      <Box sx={{ maxHeight: 450, overflowY: "auto" }}>
        {cart.map((order, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Card order={order} />
          </Box>
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Resumen */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant='body1'>Subtotal:</Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <Typography variant='body1' fontWeight='bold'>
            {FormatCurrency(sumaSubtotal)}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='body1'>
            Descuento por reglas de negocio:
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <Typography variant='body1' fontWeight='bold'>
            {discount + " % "}/{" "}
            {FormatCurrency(sumaSubtotal * (discount / 100))}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='body1'>
            Descuento adicional por cupón:
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <Typography variant='body1' fontWeight='bold'>
            {FormatCurrency(
              (sumaSubtotal - sumaSubtotal * (discount / 100)) *
                (couponDiscount / 100)
            )}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='body1'>Costo de envío:</Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <Typography variant='body1' fontWeight='bold'>
            {FormatCurrency(shippingCost)}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='h6'>Total:</Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <Typography variant='h6' fontWeight='bold'>
            {FormatCurrency(total + shippingCost)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
