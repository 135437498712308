const EventsReducer = (state, action) => {
  switch (action.type) {
    case "ADD_EVENT_TO_CART":
      return {
        ...state,
        cartEvents: [...state.cartEvents, action.payload],
      };

    case "CLEAR_EVENT_FROM_CART":
      return {
        ...state,
        cartEvents: [],
      };

    default:
      return state;
  }
};

export default EventsReducer; // ✅ Asegurar que se exporta correctamente
