import React, { useContext, useEffect, useState } from "react";
import DirectionsContext from "../../../Context/Directions/DirectionsContext";
import { Card } from "../ProfileDirections/Card/Card";
const ProfileDirections = () => {
  const { GetDirections, directions } = useContext(DirectionsContext);
  useEffect(() => {
    GetDirections();
  }, []);
  const handleCollapse = (indx) => {
    if (active === indx) {
      setActive(-1);
    } else {
      setActive(indx);
    }
  };
  const [active, setActive] = useState(-1);
  return (
    <>
      <div className='profile-orders'>
        <div className='profile-orders__row profile-orders__row-head'></div>
        {directions.map((direction, index) => (
          <Card
            key={index}
            index={index}
            onCollapse={handleCollapse}
            order={direction}
            active={active}
          />
        ))}
      </div>
    </>
  );
};

export default ProfileDirections;
