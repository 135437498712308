import React, { useEffect, useState } from "react";
import CartContext from "./CartContext";
import showToast from "../../utils/ShowToast";

const CartState = ({ children }) => {
  // Estado inicial
  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  // Actualizar localStorage cada vez que cambie el carrito
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  // Función para agregar un producto al carrito
  const agregarProducto = (prod, quantityToAdd) => {
    const existingProductIndex = cart.findIndex(
      (item) => item.product_id === prod.id
    );

    let updatedCart = [...cart];

    if (existingProductIndex === -1) {
      // Producto nuevo al carrito
      const newProduct = {
        product_id: prod.id,
        barcode: prod.tag,
        price: Number(prod.price),
        stock: prod.stock,
        name: prod.name,
        image: prod.img[0]?.image ?? null,
        brand_id: prod.brand_id,
        cantidad: quantityToAdd,
        final_price: Number(prod.price) * quantityToAdd,
        image: prod?.img[0]?.image ?? [],
      };

      if (newProduct.cantidad > prod.stock) {
        showToast("El stock es insuficiente");
        return;
      }

      updatedCart = [newProduct, ...cart];
    } else {
      // Producto existente, actualizar la cantidad
      const existingProduct = updatedCart[existingProductIndex];
      const newQuantity = existingProduct.cantidad + quantityToAdd;

      if (newQuantity > prod.stock) {
        showToast("El stock es insuficiente");
        return;
      }

      existingProduct.cantidad = newQuantity;
      existingProduct.final_price = newQuantity * existingProduct.price;
    }

    setCart(updatedCart);
  };

  // Función para actualizar la cantidad de un producto en el carrito
  const actualizarProducto = (productId, newQuantity) => {
    const updatedCart = [...cart];
    const productIndex = updatedCart.findIndex(
      (item) => item.product_id === productId
    );

    if (productIndex !== -1) {
      const product = updatedCart[productIndex];
      if (newQuantity > product.stock) {
        showToast("El stock es insuficiente");
        return;
      }

      product.cantidad = newQuantity;
      product.final_price = product.price * newQuantity;
      setCart(updatedCart);
    } else {
      showToast("Producto no encontrado en el carrito");
    }
  };

  // Función para obtener la longitud del carrito
  const cartLength = cart.length;

  const clearCart = () => {
    setCart([]); // Vacía el carrito
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        agregarProducto,

        actualizarProducto,
        cartLength,
        setCart,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartState;
