import React from "react";

const StoreIcon = () => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <rect
              x='32'
              y='132'
              style={{ fill: "#FFE1B2" }}
              width='448'
              height='312'
            ></rect>{" "}
          </g>{" "}
          <g>
            {" "}
            <rect
              x='32'
              y='396'
              style={{ fill: "#FFB980" }}
              width='448'
              height='48'
            ></rect>{" "}
          </g>{" "}
          <g>
            {" "}
            <rect
              x='32'
              y='116'
              style={{ fill: "#FFB980" }}
              width='448'
              height='56'
            ></rect>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#7E5C62" }}
              d='M192,188H80c-4.418,0-8,3.582-8,8v240h128V196C200,191.582,196.418,188,192,188z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <g>
              {" "}
              <path
                style={{ fill: "#5C546A" }}
                d='M504,452H8c-4.422,0-8-3.578-8-8s3.578-8,8-8h496c4.422,0,8,3.578,8,8S508.422,452,504,452z'
              ></path>{" "}
            </g>{" "}
          </g>{" "}
          <g>
            {" "}
            <g>
              {" "}
              <path
                style={{ fill: "#5C546A" }}
                d='M176,340c-4.422,0-8-3.578-8-8v-16c0-4.422,3.578-8,8-8s8,3.578,8,8v16 C184,336.422,180.422,340,176,340z'
              ></path>{" "}
            </g>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#FF4F19" }}
              d='M40,156L40,156c-13.255,0-24-10.745-24-24V76c0-8.837,7.163-16,16-16h32v72 C64,145.255,53.255,156,40,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#E9001E" }}
              d='M88,156L88,156c-13.255,0-24-10.745-24-24V60h48v72C112,145.255,101.255,156,88,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#FF4F19" }}
              d='M136,156L136,156c-13.255,0-24-10.745-24-24V60h48v72C160,145.255,149.255,156,136,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#E9001E" }}
              d='M184,156L184,156c-13.255,0-24-10.745-24-24V60h48v72C208,145.255,197.255,156,184,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#FF4F19" }}
              d='M232,156L232,156c-13.255,0-24-10.745-24-24V60h48v72C256,145.255,245.255,156,232,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#E9001E" }}
              d='M280,156L280,156c-13.255,0-24-10.745-24-24V60h48v72C304,145.255,293.255,156,280,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#FF4F19" }}
              d='M328,156L328,156c-13.255,0-24-10.745-24-24V60h48v72C352,145.255,341.255,156,328,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#E9001E" }}
              d='M376,156L376,156c-13.255,0-24-10.745-24-24V60h48v72C400,145.255,389.255,156,376,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#FF4F19" }}
              d='M424,156L424,156c-13.255,0-24-10.745-24-24V60h48v72C448,145.255,437.255,156,424,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#E9001E" }}
              d='M472,156L472,156c-13.255,0-24-10.745-24-24V60h32c8.837,0,16,7.163,16,16v56 C496,145.255,485.255,156,472,156z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#53DCFF" }}
              d='M440,196H240c-4.418,0-8,3.582-8,8v160c0,4.418,3.582,8,8,8h200c4.418,0,8-3.582,8-8V204 C448,199.582,444.418,196,440,196z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#B3F4FF" }}
              d='M397.588,372H440c4.418,0,8-3.582,8-8v-9.47L289.47,196H232c-2.835,0-5.207,1.56-6.628,3.784 L397.588,372z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <polygon
              style={{ fill: "#FFFFFF" }}
              points='334.059,196 448,309.941 448,276 368,196 '
            ></polygon>{" "}
          </g>{" "}
          <g>
            {" "}
            <g>
              {" "}
              <path
                style={{ fill: "#7E5C62" }}
                d='M440,380H240c-8.82,0-16-7.18-16-16V204c0-8.82,7.18-16,16-16h200c8.82,0,16,7.18,16,16v160 C456,372.82,448.82,380,440,380z M240,204v160h200.016L440,204H240z'
              ></path>{" "}
            </g>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#53DCFF" }}
              d='M176,292H96c-4.418,0-8-3.582-8-8v-72c0-4.418,3.582-8,8-8h80c4.418,0,8,3.582,8,8v72 C184,288.418,180.418,292,176,292z'
            ></path>{" "}
          </g>{" "}
          <g>
            {" "}
            <path
              style={{ fill: "#B3F4FF" }}
              d='M136.099,204H96c-4.418,0-8,3.582-8,8v11.784L156.216,292H176c4.418,0,8-3.582,8-8v-32.099 L136.099,204z'
            ></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default StoreIcon;
