import Register from "../../../components/Auth/Register";
import { PublicLayout } from "../../../components/Layout/PublicLayout";
const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Registro",
    path: "/registration",
  },
];
const RegistrationPage = () => {
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Registro'>
      <Register />
    </PublicLayout>
  );
};

export default RegistrationPage;
