import React from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"; // Íconos de Material-UI
import CardProduct from "../CardProduct";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RelatedProductsSlider = ({ relatedProducts }) => {
  // Configuración del slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Habilitar autoplay
    autoplaySpeed: 3000, // Velocidad del autoplay en ms (3 segundos)
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ padding: "20px 10px" }}>
      <Slider {...settings}>
        {relatedProducts &&
          relatedProducts.map((product, index) => (
            <Box key={index} sx={{ padding: "0 10px" }}>
              <CardProduct product={product} />
            </Box>
          ))}
      </Slider>
    </Box>
  );
};

// Flecha personalizada con ícono (Next)
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.7)",
        borderRadius: "50%",
        right: "-15px",
        zIndex: 10,
        border: "2px solid white",
        height: "40px",
        width: "40px",
      }}
      onClick={onClick}
    >
      <ArrowForwardIos
        style={{
          fontSize: "16px",
          color: "white",
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
};

// Flecha personalizada con ícono (Prev)
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.7)",
        borderRadius: "50%",
        left: "-15px",
        zIndex: 10,
        border: "2px solid white",
        height: "40px",
        width: "40px",
      }}
      onClick={onClick}
    >
      <ArrowBackIos style={{ fontSize: "16px", color: "white" }} />
    </div>
  );
};

export default RelatedProductsSlider;
