import React, { useContext, useEffect } from "react";
import { SalesDayIcon } from "../icons/SalesDayIcon";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
import { Box, Paper, Stack, Typography } from "@mui/material";
const CardSalesDay = () => {
  const { ventas_del_dia, obtenerVentasDelDia } = useContext(DashboardContext);
  useEffect(() => {
    obtenerVentasDelDia();
  }, []);

  console.log(ventas_del_dia);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        borderRadius: 2,
        backgroundColor: "#f5f5f5",
        maxWidth: 400,
        margin: "0 auto",
      }}
    >
      {/* Icono de la tarjeta */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{
          position: "relative",
          height: 120,
          backgroundColor: "#c8e6c9",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SalesDayIcon width={165} />
        </Box>
      </Box>

      {/* Encabezado */}
      <Box sx={{ textAlign: "center", marginTop: 6 }}>
        <Typography variant='h6' sx={{ fontWeight: "bold", color: "#424242" }}>
          Ventas del día
        </Typography>
        <Typography
          variant='subtitle2'
          sx={{ color: "#757575", marginTop: 0.5 }}
        >
          {ventas_del_dia?.dia || "Cargando rango de fechas..."}
        </Typography>
      </Box>

      {/* Información de ventas */}
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{
          backgroundColor: "#c8e6c9",
          color: "#fff",
          padding: 1.5,
          borderRadius: 1,
          marginTop: 2,
        }}
      >
        <Typography variant='h4' sx={{ fontWeight: "bold", color: "#517C5C" }}>
          {ventas_del_dia?.ventas_del_dia || 0}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default CardSalesDay;
