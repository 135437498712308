import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Terms = () => {
  return (
    <Container maxWidth='md' sx={{ mt: 5, mb: 5 }}>
      <Typography
        variant='body1'
        align='center'
        gutterBottom
        sx={{ color: "#555" }}
      >
        Por favor, lee atentamente estos términos y condiciones antes de
        utilizar nuestros servicios.
      </Typography>

      {/* Sección 1: Introducción */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            1. Introducción
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Estos términos y condiciones regulan el uso de este sitio web y de
            nuestros servicios.
            <br />
            - Al acceder o utilizar nuestro sitio web, aceptas estos términos en
            su totalidad.
            <br />- Si no estás de acuerdo con alguna parte de estos términos,
            por favor no utilices nuestros servicios.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 2: Uso del sitio */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            2. Uso del sitio
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            <br />
            - Está prohibido copiar, modificar o distribuir cualquier contenido
            del sitio sin nuestro consentimiento previo.
            <br />- No debes utilizar este sitio para actividades ilegales o no
            autorizadas.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 3: Propiedad intelectual */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            3. Propiedad Intelectual
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Todo el contenido de este sitio web, incluyendo textos, imágenes,
            logotipos y diseño, es propiedad de nuestra empresa o de sus
            respectivos dueños.
            <br />- Queda estrictamente prohibido utilizar este contenido sin
            autorización previa por escrito.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 4: Limitación de responsabilidad */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            4. Limitación de Responsabilidad
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - No nos hacemos responsables de cualquier daño directo o indirecto
            derivado del uso de este sitio.
            <br />- Aunque tomamos precauciones para asegurar que la información
            en el sitio sea precisa, no garantizamos su exactitud o integridad.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 5: Modificaciones */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            5. Modificaciones a los Términos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Nos reservamos el derecho de modificar estos términos en cualquier
            momento.
            <br />
            - Te recomendamos revisar esta página periódicamente para estar al
            tanto de los cambios.
            <br />- El uso continuo del sitio después de cualquier modificación
            constituye tu aceptación de los términos actualizados.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 6: Contacto */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            6. Contacto
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Si tienes alguna pregunta sobre estos términos y condiciones,
            contáctanos a través de nuestro correo electrónico:{" "}
            <strong>merrycolor.oficial@gmail.com</strong>.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default Terms;
