import React, { useContext, useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import AuthContext from "../../Context/auth/AuthContext";
import CartContext from "../../Context/Cart/CartContext";

export const Header1 = () => {
  const { autenticado } = useContext(AuthContext);
  const { cartLength } = useContext(CartContext);
  const [promo, setPromo] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [fixedNav, setFixedNav] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const navItems = [
    { name: "Inicio", path: "/", auth: false },
    { name: "Contactanos", path: "/contacto", auth: false },
    { name: "Distribuidores", path: "/distribuidores", auth: false },
    // { name: "Eventos", path: "/events", auth: false },
    { name: "Ayuda", path: "/ayuda", auth: false },
    { name: "Inicio", path: "/", auth: true },
    { name: "Contactanos", path: "/Contacto-Merry", auth: true },
    { name: "Distribuidores", path: "/distribuidores-merry", auth: true },
    // { name: "Eventos", path: "/Eventos", auth: true },
    { name: "Ayuda", path: "/preguntas-frecuentes", auth: true },
  ];

  const filteredNavItems = autenticado
    ? navItems.filter((item) => item.auth)
    : navItems.filter((item) => !item.auth);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleScroll = () => {
    setFixedNav(window.scrollY > 10);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Promo Banner */}
      {promo && (
        <Box
          sx={{
            backgroundColor: "#801F56",
            color: "white",
            textAlign: "center",
            padding: "8px 16px",
          }}
        >
          <span>¡Descubre nuestra nueva imagen renovada!</span>
          <IconButton
            sx={{ color: "white", marginLeft: 2 }}
            onClick={() => setPromo(false)}
          >
            ✕
          </IconButton>
        </Box>
      )}

      {/* Header */}
      <AppBar
        position={fixedNav ? "fixed" : "static"}
        sx={{
          backgroundColor: "rgba(240, 192, 200, 0.8)", // El último valor es la opacidad (0.8 = 80% opaco)
          height: "90px",
          boxShadow: fixedNav ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Logo */}
          <Box>
            <Link to='/'>
              <img
                src={
                  isMobile
                    ? require("../../assets/img/merry-md.png") // Imagen para pantallas pequeñas
                    : require("../../assets/img/MERRY COLOR D8708E.png") // Imagen para pantallas grandes
                }
                alt='Logo'
                style={{
                  height: isMobile ? 80 : 100, // Ajusta el tamaño del logo según la pantalla
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </Link>
          </Box>

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            {filteredNavItems.map((item) => (
              <Button
                key={item.name}
                component={Link}
                to={item.path}
                sx={{ color: "#802257", fontWeight: "bold" }}
              >
                {item.name}
              </Button>
            ))}
          </Box>

          {/* Icons */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <IconButton
              component={Link}
              to={autenticado ? "/profile" : "/iniciar-sesion"}
              sx={{ color: "#802257" }}
            >
              <PersonIcon />
            </IconButton>
            <IconButton
              component={Link}
              to={autenticado ? "Carrito-compras" : "/carrito"}
              sx={{ color: "#802257" }}
            >
              <Badge badgeContent={cartLength} color='secondary'>
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <IconButton
              sx={{ display: { xs: "flex", md: "none" }, color: "white" }}
              onClick={() => toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <Box sx={{ width: 250, padding: 2 }}>
          <List>
            {filteredNavItems.map((item) => (
              <ListItem
                button
                key={item.name}
                component={Link}
                to={item.path}
                onClick={() => toggleDrawer(false)}
              >
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <Box sx={{ textAlign: "center", marginTop: 2 }}>
            <IconButton
              component={Link}
              to={autenticado ? "/profile" : "/iniciar-sesion"}
              sx={{ color: "black" }}
            >
              <PersonIcon />
            </IconButton>
            <IconButton component={Link} to='/carrito' sx={{ color: "black" }}>
              <Badge badgeContent={cartLength} color='secondary'>
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
