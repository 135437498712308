import React, { useState, useEffect, useContext } from "react";
import MethodGet from "../../config/Service";
import { SectionTitle } from "../../components/SectionTitle";
import CardProduct from "../../components/Products/CardProduct";
import ProductsContext from "../../Context/Products/ProductsContext";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Pagination,
  useTheme,
  ButtonGroup,
  Button,
  useMediaQuery,
} from "@mui/material";
import SearchProducts from "./SearchProducts";

const useStyles = makeStyles((theme) => ({
  containerPagination: {
    padding: "0px 10px",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}));

const CategoriesIndex = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { products, ProductsByCategory, current_page, total, per_page } =
    useContext(ProductsContext);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [filterItem, setFilterItem] = useState("");
  const [filterItemId, setFilterItemId] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    MethodGet("/cate")
      .then((res) => {
        setCategories(res.data.categories);
        if (res.data.categories.length > 0) {
          setFilterItemId(res.data.categories[0]?.id);
          setFilterItem(res.data.categories[0]?.name);
        }
      })
      .catch((error) => {
        console.error("Error al cargar categorías:", error);
      });
  }, []);

  useEffect(() => {
    if (filterItemId) {
      setPage(1); // Reinicia a la página 1
      ProductsByCategory(filterItemId, 1); // Carga la nueva categoría desde la primera página
    }
  }, [filterItemId]);

  useEffect(() => {
    if (filterItemId) {
      ProductsByCategory(filterItemId, page);
    }
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box
      component='section'
      sx={{
        padding: theme.spacing(4),
        backgroundColor: "#FBFAFF",
      }}
      className='trending'
    >
      <Box className='trending-content' sx={{ marginBottom: theme.spacing(4) }}>
        <SectionTitle
          subTitle='Llegaron los'
          title='Productos más vendidos'
          body='Descubre los productos más vendidos de la temporada, y nunca pierdas la esencia y el arte de florecer en conjunto con Merrycolor'
        />
        <Box
          className='tab-wrap trending-tabs'
          sx={{
            marginBottom: theme.spacing(4),
            display: "flex",
            justifyContent: "center",
            overflowX: isMobile ? "auto" : "visible",
            whiteSpace: isMobile ? "nowrap" : "normal",
          }}
        >
          <ButtonGroup
            orientation={isMobile ? "vertical" : "horizontal"}
            sx={{
              display: "flex",
              flexWrap: isMobile ? "nowrap" : "wrap",
              gap: isMobile ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            {categories.map((item) => (
              <Button
                key={item.id}
                size='large'
                sx={{
                  color: "#801F56",
                  "&:hover": {
                    backgroundColor: "#F0C9D5",
                    border: "1px solid #801F56",
                  },
                  border: "1px solid #801F56",
                  backgroundColor:
                    filterItem === item.name ? "#F0C9D5" : "transparent",
                  minWidth: isMobile ? "auto" : "100px",
                }}
                onClick={() => {
                  setFilterItem(item.name);
                  setFilterItemId(item.id);
                }}
              >
                {item.name}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchProducts results={results} setResults={setResults} />
          </Grid>
        </Grid>
        {results.length === 0 && (
          <Box
            // className='shop'
            sx={{ marginTop: theme.spacing(1), backgroundColor: "#FBFAFF" }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                position: "relative",
                backgroundColor: "#FBFAFF",
              }}
            >
              {products &&
                products.map((product, index) => (
                  <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={index}>
                    <CardProduct product={product} />
                  </Grid>
                ))}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: theme.spacing(2),
                }}
              >
                {total > 0 && (
                  <Pagination
                    count={Math.ceil(total / per_page)}
                    page={current_page}
                    onChange={handlePageChange}
                    color='secondary'
                    shape='rounded'
                    variant='outlined'
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CategoriesIndex;
