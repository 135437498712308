import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { makeStyles } from "@mui/styles";
import PedidosContext from "../../Context/Pedidos/PedidosContext";
import { useContext } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import CurrencyFormat from "../../utils/FormatCurrency";
const useStyles = makeStyles({
  input: {
    display: "none",
  },
  logoimagen: {
    height: "250px",
    width: "100%",
    objectFit: "cover",
  },
});
export default function FormDialog({
  open,
  handleClose,
  order_id,
  total,
  envio,
  total_pagado,
}) {
  const classes = useStyles();
  const { UploadTicket } = useContext(PedidosContext);
  const img = require("../../assets/img/default .png");
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [image, setImage] = useState({
    urlPhoto: img,
    image: "",
  });
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };
  const reset = () => {
    setValue("reference", "", { shouldDirty: true });
    setValue("amount", "", { shouldDirty: true });
    setValue("image", "", { shouldDirty: true });
    setImage({
      urlPhoto: img,
      image: "",
    });
  };
  const onSubmit = (data, e) => {
    data.image = image.image;
    data.id_sale = order_id;
    if (data !== "") {
      UploadTicket(data);
      reset();
      handleClose();
    } else {
      Swal.fire({
        title: "error",
        text: "Todos los campos son obligatorios",
        icon: "error",
      });
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          // Prevenir que se cierre al hacer clic fuera del modal
          if (reason === "backdropClick") return;
          handleClose();
        }}
      >
        <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
          Subir comprobante
        </DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete='off'
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
        >
          <DialogContent>
            {/* <DialogContentText>
              En esta ventana subiras tu comprobante de transferencia junto con
              algunos datos adicionales que se solicitaran
            </DialogContentText> */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography textAlign='center'>
                  <strong> T. a pagar: {CurrencyFormat(total + envio)} </strong>
                </Typography>
              </Grid>
              <Divider orientation='vertical' variant='middle' flexItem />
              <Grid item xs={3}>
                <Typography textAlign='center'>
                  {" "}
                  <strong> T. pagado: {CurrencyFormat(total_pagado)} </strong>
                </Typography>
              </Grid>
              <Divider orientation='vertical' variant='middle' flexItem />
              <Grid item xs={4}>
                <Typography textAlign='center'>
                  <strong>
                    {" "}
                    Por pagar: {CurrencyFormat(
                      total + envio - total_pagado
                    )}{" "}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <img
                      src={image.urlPhoto}
                      className={classes.logoimagen}
                      alt='agrega evidencia'
                    />
                  </div>
                </Box>
                <input
                  className={classes.input}
                  id='icon-button-file-first'
                  type='file'
                  name='image'
                  accept='image/png, image/jpg, image/jpeg, image/webp'
                  onChange={handleChangeImage}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  <label htmlFor='icon-button-file-first'>
                    Subir comprobante
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <Tooltip
                        title='seleccionar imagen'
                        aria-label='seleccionar imagen'
                        placement='top'
                      >
                        <PhotoCameraIcon />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Folio de operación'
                  placeholder='4656453168'
                  variant='outlined'
                  name='reference'
                  type='text'
                  error={errors.reference ? true : false}
                  helperText={errors?.reference?.message}
                  {...register("reference", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 1,
                      message: "Minimo 1 caracter",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='outlined-basic'
                  label='Monto de la operación'
                  placeholder='1500'
                  variant='outlined'
                  name='amount'
                  type='text'
                  error={errors.amount ? true : false}
                  helperText={errors?.amount?.message}
                  {...register("amount", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 1,
                      message: "Minimo 1 caracteres",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                    pattern: {
                      value: /^[-+]?\d*\.?\d+$/,
                      message: "El campo debe ser numerico",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='error' variant='contained'>
              Cancelar
            </Button>
            <Button
              type='submit'
              sx={{
                backgroundColor: "#802257",
                color: "white",
                "&:hover": { backgroundColor: "#802257" },
              }}
              variant='contained'
            >
              Subir comprobante
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
