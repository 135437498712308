import React, { useContext, useEffect } from "react";
import { SalesMontlyIcon } from "../icons/SalesMontlyIcon"; // Asegúrate de que este ícono esté disponible
import DashboardContext from "../../Context/Dashboard/DashboardContext";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  Stack,
} from "@mui/material";
import FormatCurrency from "../../utils/FormatCurrency";
const CardSalesMonthly = () => {
  const { ventas_mes, obtenerVentasDelMes } = useContext(DashboardContext);

  useEffect(() => {
    obtenerVentasDelMes();
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        borderRadius: 2,
        backgroundColor: "#f5f5f5",
        maxWidth: 400,
        margin: "0 auto",
      }}
    >
      {/* Icono de la tarjeta */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{
          position: "relative",
          height: 120,
          backgroundColor: "#e0e0e0",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SalesMontlyIcon width={170} sx={{ fontSize: 40, color: "#fff" }} />
        </Box>
      </Box>

      {/* Encabezado */}
      <Box sx={{ textAlign: "center", marginTop: 6 }}>
        <Typography variant='h6' sx={{ fontWeight: "bold", color: "#424242" }}>
          Ventas del mes
        </Typography>
        <Typography
          variant='subtitle2'
          sx={{ color: "#757575", marginTop: 0.5 }}
        >
          {ventas_mes?.rango_fechas || "Cargando rango de fechas..."}
        </Typography>
      </Box>

      {/* Información de ventas */}
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{
          backgroundColor: "#e0e0e0",
          color: "#fff",
          padding: 1.5,
          borderRadius: 1,
          marginTop: 2,
        }}
      >
        <Typography variant='h4' sx={{ fontWeight: "bold", color: "#999999" }}>
          {FormatCurrency(ventas_mes?.total_pagos || 0)}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default CardSalesMonthly;
