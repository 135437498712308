import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Grid,
  IconButton,
  AppBar,
} from "@mui/material";
import Layout from "../../../components/Layout/Layout";
import { BackIcon } from "../../../components/icons/BackIcon";
import ProductsWithoutImage from "./ProductsWitoutImages";
import AllProductsAdmin from "./AllProductsAdmin";
import SearchProduct from "./SearchProduct";
import ProductsDesactivated from "./ProductsDesactivated";
import EnterProducts from "../EnterProducts/EnterProducts";
import DepartureProducts from "../DepartureProducts/DepartureProducts";
import ProductsMovements from "../ProductsMovements/ProductsMovements";

// TabPanel utility component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Accessibility props for tabs
function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

// Main component
export default function IndexProductsAdmin({ history }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { label: "Productos", component: <AllProductsAdmin /> },
    { label: "Entradas de productos", component: <EnterProducts /> },
    { label: "Salidas de productos", component: <DepartureProducts /> },
    {
      label: "Movimientos de stock productos",
      component: <ProductsMovements />,
    },
  ];

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {/* Back Button */}
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
          <IconButton aria-label='Volver' onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
        </Grid>

        {/* Tabs Header */}
        <Grid item xs={12}>
          <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
            <AppBar position='static' sx={{ bgcolor: "#D8708E" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor='secondary'
                textColor='inherit'
                variant='scrollable'
                scrollButtons='auto'
                aria-label='Product management tabs'
              >
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} {...a11yProps(index)} />
                ))}
              </Tabs>
            </AppBar>
          </Box>
        </Grid>

        {/* Tabs Content */}
        <Grid item xs={12}>
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              {tab.component}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
}

IndexProductsAdmin.propTypes = {
  history: PropTypes.object.isRequired,
};
