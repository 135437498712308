import React, { useContext, useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import { Button, Grid, Box, Typography } from "@mui/material";
import ProductsContext from "../../../Context/Products/ProductsContext";
import CardProductAdmin from "../../../components/Products/CardProductAdmin";
import NoDataComponents from "../../../components/loading/NoDataComponents";

const ProductsDesactivated = () => {
  const { products, GetProductsDesactivate } = useContext(ProductsContext);
  useEffect(() => {
    GetProductsDesactivate();
  }, []);
  return (
    <Grid container spacing={2}>
      {/* {products.map((product, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            key={index}
            justifyContent='center'
          >
            <CardProductAdmin product={product} />
          </Grid>
        ))} */}
      {products.length > 0 ? (
        products.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={product.id}>
            <CardProductAdmin product={product} />
          </Grid>
        ))
      ) : (
        <NoDataComponents />
      )}
    </Grid>
  );
};

export default ProductsDesactivated;
