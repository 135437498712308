import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, TextField, Button, Typography, Box, Paper } from "@mui/material";
import SelectState from "../../../containers/SelectOptions/StateSelect";
import SelectMunicipality from "../../../containers/SelectOptions/MunicipalitySelect";
import AuthContext from "../../../Context/auth/AuthContext";
import DirectionSelect from "../../../containers/SelectOptions/DirectionSelect";
import DirectionsContext from "../../../Context/Directions/DirectionsContext";

export const CheckoutStep1 = ({ onNext, setData, direction, setDirection }) => {
  const { autenticado } = useContext(AuthContext);
  const { directions, GetDirections, storeDirection } =
    useContext(DirectionsContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState("");
  const [municipality, setMunicipality] = useState("");

  const detectarCambiosDirection = (e) => {
    setDirection(e.target.value);
  };

  const detectarCambiosState = (e) => {
    setState(e.target.value);
  };

  const detectarCambiosMunicipality = (e) => {
    setMunicipality(e.target.value);
  };
  const reset = () => {
    setValue("name", "", { shouldDirty: true });
    setValue("street", "", { shouldDirty: true });
    setValue("between_street", "", { shouldDirty: true });
    setValue("and_between_street", "", { shouldDirty: true });
    setValue("number_int", "", { shouldDirty: true });
    setValue("number_ext", "", { shouldDirty: true });
    setState(null);
    setMunicipality(null);
    setValue("city", "", { shouldDirty: true });
    setValue("zone", "", { shouldDirty: true });
    setValue("postal_code", "", { shouldDirty: true });
    setValue("phone", "", { shouldDirty: true });
    setValue("email", "", { shouldDirty: true });
    setValue("references", "", { shouldDirty: true });
  };

  const onSubmit = (data) => {
    data.country = "Mexico";
    data.state_id = state;
    data.municipality_id = municipality;
    console.log(data, "la data");
    storeDirection(data);
    reset();
  };

  useEffect(() => {
    GetDirections();
  }, []);

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ p: 2 }}
    >
      <Paper sx={{ padding: 2, elevation: 8 }}>
        <Typography variant='h6' textAlign='center' gutterBottom sx={{ mt: 0 }}>
          Registra una nueva dirección
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Nombre de dirección'
              {...register("name", { required: "Este campo es obligatorio" })}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField fullWidth label='País' value='México' disabled />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Calle'
              {...register("street", { required: "Este campo es obligatorio" })}
              error={!!errors.street}
              helperText={errors.street?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Entre calle'
              {...register("between_street", {
                required: "Este campo es obligatorio",
              })}
              error={!!errors.between_street}
              helperText={errors.between_street?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Y entre calle'
              {...register("and_between_street", {
                required: "Este campo es obligatorio",
              })}
              error={!!errors.and_between_street}
              helperText={errors.and_between_street?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Número Interior'
              {...register("number_int")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Número Exterior'
              {...register("number_ext", {
                required: "Este campo es obligatorio",
              })}
              error={!!errors.number_ext}
              helperText={errors.number_ext?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <SelectState detectarCambiosState={detectarCambiosState} />
          </Grid>
          {state && (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <SelectMunicipality
                detectarCambiosMunicipality={detectarCambiosMunicipality}
                state_id={state}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Ciudad'
              {...register("city", { required: "Este campo es obligatorio" })}
              error={!!errors.city}
              helperText={errors.city?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Zona / Barrio'
              {...register("zone", { required: "Este campo es obligatorio" })}
              error={!!errors.zone}
              helperText={errors.zone?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Código Postal'
              {...register("postal_code", {
                required: "Este campo es obligatorio",
              })}
              error={!!errors.postal_code}
              helperText={errors.postal_code?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Número de Teléfono'
              placeholder='Se utiliza para paquetería'
              {...register("phone", {
                required: "Este campo es obligatorio",
                pattern: {
                  value: /^[0-9]{10}$/, // Validación para 10 dígitos
                  message:
                    "El número de teléfono debe tener exactamente 10 dígitos",
                },
              })}
              error={!!errors.phone}
              helperText={errors.phone?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label='Correo Electrónico'
              placeholder='Se utiliza para paquetería'
              {...register("email", { required: "Este campo es obligatorio" })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              label='Referencias'
              {...register("references", {
                required: "Este campo es obligatorio",
              })}
              error={!!errors.references}
              helperText={errors.references?.message}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            type='submit'
            variant='contained'
            sx={{
              backgroundColor: "#801F56",
              "&:hover": { backgroundColor: "#801F56" },
            }}
          >
            Guardar Dirección
          </Button>
        </Box>
        <Typography variant='h6' textAlign='center' gutterBottom sx={{ mt: 3 }}>
          O selecciona una dirección existente
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <DirectionSelect
              setDirection={setDirection}
              direction={direction}
              detectarCambiosDirection={detectarCambiosDirection}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
