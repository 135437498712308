import {
  Box,
  Typography,
  IconButton,
  ButtonGroup,
  Button,
  TextField,
  Tooltip,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CurrencyFormat from "../../../utils/FormatCurrency";
export const Card = ({ cart, onChangeQuantity, eliminarProducto }) => {
  const { name, image, product_id, barcode, price, cantidad, final_price } =
    cart;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const miImagen = require("../../../assets/img/default .png");
  return (
    <Box
      sx={{
        padding: 2,
        borderBottom: "0px solid #e0e0e0",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2} alignItems='center'>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          display='flex'
          alignItems='center'
        >
          <Box
            component='img'
            src={image !== null ? image : miImagen}
            alt={name}
            sx={{
              width: isMobile ? 60 : 80,
              height: isMobile ? 60 : 80,
              borderRadius: 1,
              marginRight: 2,
            }}
          />
          <Box>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              fontWeight='bold'
              color='black'
            >
              {name}
            </Typography>
            <Typography variant='caption' color='textSecondary'>
              SKU: {barcode}
            </Typography>
          </Box>
        </Grid>

        {/* Precio Unitario */}
        <Grid item xs={4} sm={4} md={4} lg={2} xl={2} textAlign='center'>
          <Typography variant='caption' color='textSecondary'>
            Precio unitario
          </Typography>
          <Typography variant='subtitle1' color='black'>
            {CurrencyFormat(price)}
          </Typography>
        </Grid>

        {/* Cantidad */}
        <Grid item xs={8} sm={4} md={4} lg={3} xl={3} textAlign='center'>
          <ButtonGroup size='small'>
            <Button
              variant='contained'
              sx={{
                backgroundColor: "#D87390",
                color: "white",
                "&:hover": { backgroundColor: "#D87390" },
              }}
              onClick={() => onChangeQuantity("decrement", cantidad)}
            >
              <RemoveIcon />
            </Button>
            <TextField
              value={cantidad}
              variant='outlined'
              size='small'
              disabled
              sx={{ width: 50, textAlign: "center" }}
              inputProps={{ readOnly: true }}
            />
            <Button
              variant='contained'
              sx={{
                backgroundColor: "#D87390",
                color: "white",
                "&:hover": { backgroundColor: "#D87390" },
              }}
              onClick={() => onChangeQuantity("increment", cantidad)}
            >
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Grid>

        {/* Precio Total */}
        <Grid item xs={6} sm={3} md={2} lg={2} xl={2} textAlign='center'>
          <Typography variant='caption' color='textSecondary'>
            Subtotal
          </Typography>
          <Typography variant='body2' color='black'>
            {CurrencyFormat(final_price)}
          </Typography>
        </Grid>

        {/* Botón Eliminar */}
        <Grid item xs={6} sm={1} md={1} lg={1} xl={1} textAlign='center'>
          <Tooltip title='Eliminar Producto' placement='top'>
            <Button
              onClick={() => eliminarProducto(product_id)}
              variant='contained'
              color='error'
              size='small'
              endIcon={<DeleteIcon />}
            >
              Eliminar
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};
