import { useState } from "react";
import { Box, TextField, Button, Typography, Paper, Grid } from "@mui/material";
import imgcontactform from "../../../assets/img/AdobeStock_498539353.jpeg";

export const ContactFrom = () => {
  // Estados para los valores del formulario
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Número de WhatsApp al que se enviará el mensaje
  const whatsappNumber = "5618391680"; // Reemplaza con el número de WhatsApp válido

  // Manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevenir el comportamiento predeterminado de recargar la página

    // Construir el mensaje para WhatsApp
    const whatsappMessage = `Hola, soy ${fullname}. Mi correo es ${email}. ${message}`;

    // Codificar el mensaje para la URL
    const encodedMessage = encodeURIComponent(whatsappMessage);

    // Redirigir al enlace de WhatsApp
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank"); // Abrir el enlace en una nueva pestaña
    setFullname("");
    setEmail("");
    setMessage("");
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${imgcontactform})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "10vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          maxWidth: 600,
          width: "100%",
          p: 4,
          borderRadius: 3,
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Typography
          variant='h4'
          align='center'
          gutterBottom
          sx={{ color: "#333", fontWeight: "bold" }}
        >
          Escríbenos
        </Typography>
        <Typography
          variant='body1'
          align='center'
          sx={{ color: "#555", mb: 3 }}
        >
          Contáctanos si tienes alguna duda. Nosotros te ayudamos a encontrar
          una solución.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Nombre completo'
                variant='outlined'
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Correo electrónico'
                variant='outlined'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Mensaje'
                variant='outlined'
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{
                  backgroundColor: "#d8708e",
                  "&:hover": { backgroundColor: "#b75976" },
                  py: 1.5,
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Enviar mensaje
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};
