import React from "react";
import { Typography, Grid, Box, Container } from "@mui/material";
import FacebookIcon from "../icons/FacebookIcon";
import { InstaIcon } from "../icons/InstaIcon";
import TiktokIcon from "../icons/TiktokIcon";
import { Link } from "react-router-dom";
const Distributors = () => {
  return (
    <Box p={4} sx={{ backgroundColor: "#FAF9FF" }}>
      <Box sx={{ backgroundColor: "#FAF9FF", pb: 8, width: "100%" }}>
        {/* Sección superior */}
        <Box
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "#fff",

            textAlign: "center",
          }}
        >
          <Container>
            <Typography variant='h6' sx={{ mb: 2 }}>
              CONVIÉRTETE EN AFILIADO
            </Typography>
            <Typography
              variant='h3'
              sx={{ fontWeight: "bold", textTransform: "uppercase", mb: 2 }}
            >
              Comienza a ganar dinero
            </Typography>
            <Grid container spacing={4} justifyContent='center'>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                  AFILIADOS
                </Typography>
                <Typography variant='body2'>
                  Accede a grandes descuentos, promociones, exclusividad en
                  productos seleccionados y más...
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant='subtitle1' sx={{ fontWeight: "bold" }}>
                  DESCUENTOS
                </Typography>
                <Typography variant='body2'>
                  Al ser afiliado, puedes adquirir hasta un 40% de descuento en
                  la compra de nuestros productos.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Sección de pasos */}
        <Container>
          <Typography
            variant='h4'
            sx={{ textAlign: "center", fontWeight: "bold", my: 6 }}
          >
            ¿CÓMO FUNCIONA?
          </Typography>
          <Grid container spacing={4}>
            {[
              {
                step: "01",
                title: "Inscríbete",
                description:
                  "Adquiere un rol de afiliado, el cual te dará acceso a múltiples beneficios con la marca.",
              },
              {
                step: "02",
                title: "Súrtete",
                description:
                  "Realiza tus compras en nuestro sitio web con un porcentaje de descuento exclusivo solo para afiliados.",
              },
              {
                step: "03",
                title: "Diviértete",
                description:
                  "Vende nuestros productos en tu negocio, con tus clientes, familiares y conocidos y adquiere una ganancia con ello.",
              },
            ].map(({ step, title, description }, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: "bold", color: "#801F56" }}
                  >
                    {step}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ mt: 2, color: "#555", lineHeight: 1.8 }}
                  >
                    {description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Grid sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Typography variant='h3' fontWeight='bold' textAlign='center'>
          Conocer nuestros distribuidores autorizados
        </Typography>
      </Grid>
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant='h5' fontWeight='bold' marginBottom={3}>
            MerryColor Guadalajara
          </Typography>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={4} lg={4} xl={4}>
              <FacebookIcon width={50} />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} xl={4}>
              <InstaIcon width={50} />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} xl={4}>
              <TiktokIcon width={50} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.8336994842443!2d-103.35556872399202!3d20.676343680886223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b15416183c95%3A0x12ce378ca1ecd83d!2sMerryColor%20Guadalajara!5e0!3m2!1ses-419!2smx!4v1738430805075!5m2!1ses-419!2smx'
                width='100%'
                height='300'
                style={{ borderRadius: 4 }}
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant='h5' fontWeight='bold' marginBottom={3}>
            {" "}
            MerryColor Mazatlán
          </Typography>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={4} lg={4} xl={4}>
              <Link
                to={"https://www.facebook.com/profile.php?id=61568240134654"}
              >
                <FacebookIcon width={50} />
              </Link>
            </Grid>
            <Grid item xs={12} sm={4} lg={4} xl={4}>
              <InstaIcon width={50} />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} xl={4}>
              <TiktokIcon width={50} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6149.354450293875!2d-106.44232786990307!3d23.23937265503015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869f536092ea14df%3A0x3834aadffd718487!2sGran%20Plaza%20Mazatl%C3%A1n!5e0!3m2!1ses-419!2smx!4v1738432101230!5m2!1ses-419!2smx'
                width='100%'
                height='300'
                style={{ border: 0 }}
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Distributors;
