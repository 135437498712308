import Clips from "../../components/Clips/Clips";
import { ContactDetailBlock } from "../../components/Contact/ContactDetailBlock/ContactDetailBlock";
import { ContactFrom } from "../../components/Contact/ContactForm/ContactForm";
import { ContactInfo } from "../../components/Contact/ContactInfo/ContactInfo";
import { Map } from "../../components/Contact/Map/Map";
import Content from "../../components/Content/Content";
import { PublicLayout } from "../../components/Layout/PublicLayout";

const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Contenido",
    path: "/contenido",
  },
];
const ContentPage = () => {
  return (
    <PublicLayout
      breadcrumb={breadcrumbsData}
      breadcrumbTitle='Contenido Merrycolor'
    >
      <Content />
    </PublicLayout>
  );
};

export default ContentPage;
