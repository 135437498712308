export const UploadFileIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      version='1.1'
      width={width}
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <polygon
          style={{ fill: "#FFE6B8" }}
          points='501.801,120.144 142.319,120.144 142.319,88.733 64.716,88.733 64.716,120.144 10.199,120.144 10.199,380.704 501.801,380.704 '
        ></polygon>{" "}
        <rect
          x='200.241'
          y='380.706'
          style={{ fill: "#CCCCCC" }}
          width='111.508'
          height='73.169'
        ></rect>{" "}
        <polygon
          style={{ fill: "#D35B38" }}
          points='64.716,120.144 64.716,88.733 142.319,88.733 142.319,120.144 501.801,120.144 501.801,58.122 10.199,58.122 10.199,120.144 '
        ></polygon>{" "}
        <polygon
          style={{ fill: "#BCC987" }}
          points='256,180.01 167.267,268.743 200.243,268.743 200.243,380.704 311.757,380.704 311.757,268.743 344.733,268.743 '
        ></polygon>{" "}
        <g>
          {" "}
          <path
            style={{ fill: "#4D3D36" }}
            d='M358.135,464.077c5.633,0,10.199-4.567,10.199-10.199c0-5.632-4.566-10.199-10.199-10.199h-36.179 v-52.775h179.845c5.633,0,10.199-4.567,10.199-10.199V58.122c0-5.632-4.566-10.199-10.199-10.199H10.199 C4.566,47.923,0,52.49,0,58.122v322.581c0,5.632,4.566,10.199,10.199,10.199h179.845v52.775h-36.179 c-5.633,0-10.199,4.567-10.199,10.199c0,5.632,4.566,10.199,10.199,10.199h204.27V464.077z M20.398,68.321h471.203v41.623H152.518 V88.733c0-5.632-4.566-10.199-10.199-10.199H64.717c-5.633,0-10.199,4.567-10.199,10.199v21.211H20.398V68.321z M311.757,258.544 c-5.633,0-10.199,4.567-10.199,10.199v101.762h-91.116V268.743c0-5.632-4.566-10.199-10.199-10.199h-8.353L256,194.435 l64.11,64.109L311.757,258.544L311.757,258.544z M210.442,443.679v-52.775h91.116v52.775H210.442z M491.602,370.505H321.956 v-91.562h22.777c4.125,0,7.844-2.486,9.423-6.296c1.579-3.811,0.706-8.198-2.21-11.115l-88.733-88.733 c-3.983-3.982-10.441-3.982-14.425,0l-88.733,88.733c-2.917,2.917-3.789,7.304-2.21,11.115c1.579,3.81,5.297,6.296,9.423,6.296 h22.777v91.562H20.398V130.343h44.319c5.633,0,10.199-4.567,10.199-10.199V98.932h57.203v21.211 c0,5.632,4.566,10.199,10.199,10.199h349.283V370.505z'
          ></path>{" "}
          <path
            style={{ fill: "#4D3D36" }}
            d='M467.124,99.436h2.04c5.633,0,10.199-4.567,10.199-10.199s-4.566-10.199-10.199-10.199h-2.04 c-5.633,0-10.199,4.567-10.199,10.199S461.49,99.436,467.124,99.436z'
          ></path>{" "}
          <path
            style={{ fill: "#4D3D36" }}
            d='M430.406,99.436h2.04c5.633,0,10.199-4.567,10.199-10.199s-4.566-10.199-10.199-10.199h-2.04 c-5.633,0-10.199,4.567-10.199,10.199S424.773,99.436,430.406,99.436z'
          ></path>{" "}
          <path
            style={{ fill: "#4D3D36" }}
            d='M393.689,99.436h2.04c5.633,0,10.199-4.567,10.199-10.199s-4.566-10.199-10.199-10.199h-2.04 c-5.633,0-10.199,4.567-10.199,10.199S388.056,99.436,393.689,99.436z'
          ></path>{" "}
          <path
            style={{ fill: "#4D3D36" }}
            d='M280.478,282.002c5.633,0,10.199-4.567,10.199-10.199v-3.06c0-5.632-4.566-10.199-10.199-10.199 s-10.199,4.567-10.199,10.199v3.06C270.279,277.436,274.845,282.002,280.478,282.002z'
          ></path>{" "}
          <path
            style={{ fill: "#4D3D36" }}
            d='M280.478,361.556c5.633,0,10.199-4.567,10.199-10.199v-47.937c0-5.632-4.566-10.199-10.199-10.199 s-10.199,4.567-10.199,10.199v47.937C270.279,356.989,274.845,361.556,280.478,361.556z'
          ></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
