import { Header1 } from "./Header1";
import { Footer } from "./Footer";
import AuthContext from "../../Context/auth/AuthContext";
import { useContext } from "react";
import Header from "./Header";
import FloatingWhatsAppButton from "./FloatingWhatsappIcon";
const UserLayout = ({ children }) => (
  <>
    <header className='header'>
      <Header1 />
    </header>
    <main className='content'>{children}</main>
    <footer className='footer'>
      <Footer />
    </footer>
    <FloatingWhatsAppButton />
  </>
);

const AdminLayout = ({ children }) => (
  <>
    <Header />
    <main className='content'>{children}</main>
  </>
);

const NoAuthLayout = ({ children }) => (
  <>
    <header className='header'>
      <Header1 />
    </header>
    <main className='content'>{children}</main>
    <footer className='footer'>
      <Footer />
    </footer>
    <FloatingWhatsAppButton />
  </>
);

const Layout = ({ children }) => {
  const { usuario } = useContext(AuthContext);

  if (!usuario) {
    return <NoAuthLayout>{children}</NoAuthLayout>; // Usar el layout sin autenticación.
  }

  return usuario.type_user === 1 ? (
    <UserLayout>{children}</UserLayout> // Layout para usuarios.
  ) : (
    <AdminLayout>{children}</AdminLayout> // Layout para administradores.
  );
};

export default Layout;
