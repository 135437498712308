import Events from "../../components/Events/Events";
import { PublicLayout } from "../../components/Layout/PublicLayout";
const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Eventos",
    path: "/events",
  },
];
const EventsPage = () => {
  return (
    <PublicLayout
      breadcrumb={breadcrumbsData}
      breadcrumbTitle='Nuestros Eventos'
    >
      <Events />
    </PublicLayout>
  );
};

export default EventsPage;
