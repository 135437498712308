import React, { useContext } from "react";
import AuthContext from "../../Context/auth/AuthContext";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  styled,
  Tooltip,
  Container,
} from "@mui/material/";
import { AvatarIcon } from "../../components/icons/AvatarIcon";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
//app
import { ExitSesionIcon } from "../icons/ExitSesionIcon";
//app
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 0,
    marginTop: "80px",
    marginBottom: 15,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  })
);
const useStyles = makeStyles({});
export default function Header({ children }) {
  const classes = useStyles();

  const { usuario, cerrarSesion } = useContext(AuthContext);

  if (!usuario) return <> </>;
  const { name } = usuario;

  return (
    <div>
      <AppBar
        position='fixed'
        sx={{ backgroundColor: "#F4CED9", height: "80px", width: "100%" }}
      >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Link to='/' className='brand-link'>
                <>
                  <img
                    src={require("../../assets/img/MERRY COLOR D8708E.png")}
                    alt=' '
                    style={{ marginTop: 2, height: "50px" }}
                  />
                </>
              </Link>
            </Box>
            <Box></Box>
            <Box
              sx={{ display: { xs: "flex", md: "none" }, mr: 1, flexGrow: 1 }}
            >
              <Link to='/' className='brand-link'>
                <img
                  src={require("../../assets/img/flor contallo merry color.png")}
                  alt='AdminLTE Logo'
                  height='auto'
                  width='100px'
                />
              </Link>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            ></Box>
            <Box
              x={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <Link to={"/MiCuenta"}>
                <Tooltip title='Mi Cuenta'>
                  <IconButton
                    sx={{
                      marginRight: { lg: 4, xs: 1 },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <AvatarIcon /> {""}
                    <Box
                      component='b'
                      sx={{
                        marginTop: 2,
                        marginLeft: 1,
                        display: { xs: "none", lg: "flex" },
                      }}
                    >
                      {name}
                    </Box>
                  </IconButton>
                </Tooltip>
              </Link>
              <Tooltip title='Cerrar Sesion'>
                <IconButton
                  onClick={() => cerrarSesion()}
                  sx={{
                    "&:hover": {
                      color: "transparent",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <ExitSesionIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Main>{children}</Main>
    </div>
  );
}
