import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import ProductsSelect from "../../SelectOptions/ProductsSelect";
import { Grid, Button, TextField, Typography, Box } from "@mui/material";
import Swal from "sweetalert2";
import MethodGet from "../../../config/Service";
import ListaProductos from "./ListaProductos";
import { useForm } from "react-hook-form";
import DepartureProductsContext from "../../../Context/DeparturesProducts/DepartureProductsContext";
import ShowToast from "../../../utils/ShowToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddDepartureProducts = (props) => {
  const history = useHistory();
  const { CreateDepartureProducts } = useContext(DepartureProductsContext);

  const [products, setProducts] = useState([]);
  const [product, saveProduct] = useState(null);
  const [productsList, saveProductsList] = useState(
    JSON.parse(localStorage.getItem("DepartureProducts")) || []
  );

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await MethodGet("/allProducts");
        setProducts(res.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  const detectarCambiosProduct = (value) => saveProduct(value.value);

  const AgregarProductoState = () => {
    if (!product) {
      Swal.fire({
        title: "Error",
        text: "Primero selecciona un producto",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    const selectedProduct = products.find((p) => p.id === product);
    if (!selectedProduct) {
      ShowToast(
        "error",
        "No se encontró el producto",
        "Producto no encontrado"
      );
      return;
    }

    if (productsList.some((p) => p.product_id === selectedProduct.id)) {
      ShowToast(
        "error",
        "Producto ya agregado",
        "Modifica la cantidad para agregar más piezas de este producto"
      );
      return;
    }

    const newProduct = {
      barcode: selectedProduct.tag,
      name: selectedProduct.name,
      quantity: 1,
      product_id: selectedProduct.id,
      price: selectedProduct.price,
      stock_product: selectedProduct.stock,
    };

    const updatedList = [newProduct, ...productsList];
    saveProductsList(updatedList);
    localStorage.setItem("DepartureProducts", JSON.stringify(updatedList));
    ShowToast("success", "Agregado", "El producto se agregó correctamente!");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.details = productsList.map((p) => ({
      product_id: p.product_id,
      quantity: p.quantity,
    }));
    CreateDepartureProducts(data);
    localStorage.removeItem("DepartureProducts");
    history.goBack();
  };

  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <Typography variant='h4' fontWeight='bold' textAlign='center' mb={3}>
          Crear salida de productos
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete='off'
        >
          <Grid container spacing={3}>
            {/* Selector de producto */}
            <Grid item xs={12} md={9}>
              <ProductsSelect detectarCambiosProduct={detectarCambiosProduct} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={AgregarProductoState}
                disabled={!product}
              >
                Agregar Producto
              </Button>
            </Grid>

            {/* Campos de texto */}
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label='Referencia de Salida'
                variant='outlined'
                name='reference'
                error={!!errors.reference}
                helperText={errors.reference?.message}
                {...register("reference", {
                  required: "Este campo es requerido",
                })}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label='Descripción de Salida'
                variant='outlined'
                name='description'
                error={!!errors.description}
                helperText={errors.description?.message}
                {...register("description", {
                  required: "Este campo es requerido",
                })}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label='Autoriza'
                variant='outlined'
                name='auth'
                error={!!errors.auth}
                helperText={errors.auth?.message}
                {...register("auth", {
                  required: "Este campo es requerido",
                })}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label='Recibe'
                variant='outlined'
                name='reciver'
                error={!!errors.reciver}
                helperText={errors.reciver?.message}
                {...register("reciver", {
                  required: "Este campo es requerido",
                })}
              />
            </Grid>

            {/* Lista de productos */}
            <Grid item xs={12}>
              <ListaProductos
                productsList={productsList}
                saveProductsList={saveProductsList}
              />
            </Grid>

            {/* Botones de acción */}
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                type='submit'
              >
                Terminar Salida
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  );
};

export default AddDepartureProducts;
