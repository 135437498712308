import { CREATE_ENTER_PRODUCTS, GET_ALL_ENTER_PRODUCTS } from "../../types";

export default function EnterProductsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_ENTER_PRODUCTS:
      return {
        ...state,
        enter_products: action.payload,
        success: false,
        ErrorsApi: [],
      };

    case CREATE_ENTER_PRODUCTS:
      return {
        ...state,
        enter_products: [action.payload, ...state.enter_products],
        success: false,
        ErrorsApi: [],
      };

    default:
      return state;
  }
}
