import React, { useContext } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { InstaIcon } from "../../components/icons/InstaIcon";
import FacebookIcon from "../../components/icons/FacebookIcon";
import TiktokIcon from "../../components/icons/TiktokIcon";
import { PlayStoreIcon } from "../icons/PlayStoreIcon";
import { AppStoreIcon } from "../icons/AppStoreIcon";
import { Link } from "react-router-dom";
import AuthContext from "../../Context/auth/AuthContext";
export const Footer = () => {
  const { autenticado } = useContext(AuthContext);
  const socialData = [
    {
      name: "Instagram",
      link: "https://www.instagram.com/merrycoloroficial/",
      icon: <InstaIcon width={70} />,
    },
    {
      name: "Facebook",
      link: "https://www.facebook.com/profile.php?id=100068529635739",
      icon: <FacebookIcon width={70} />,
    },
    {
      name: "TikTok",
      link: "https://www.tiktok.com/@merrycolor.oficial",
      icon: <TiktokIcon width={70} />,
    },
  ];

  const policies = [
    {
      name: "Términos y condiciones",
      link: "/terms-and-conditions",
      auth: false,
    },
    {
      name: "Políticas de privacidad",
      link: "/policies-privacy",
      auth: false,
    },
    {
      name: "Políticas de devolución",
      link: "/policies-back",
      auth: false,
    },
    {
      name: "Términos y condiciones",
      link: "/terminos-y-condiciones",
      auth: true,
    },
    {
      name: "Políticas de privacidad",
      link: "/politicas-privacidad",
      auth: true,
    },
    {
      name: "Políticas de devolución",
      link: "/politicas-devolucion",
      auth: true,
    },
  ];

  return (
    <Box component='footer' sx={{ backgroundColor: "#000", padding: 4 }}>
      <Box sx={{ maxWidth: "100%", margin: "0 auto" }}>
        <Grid container spacing={4} alignItems='center'>
          {/* Social Links */}
          <Grid item xs={12} md={3}>
            <Typography
              variant='subtitle1'
              sx={{
                fontWeight: "bold",
                mb: 1,
                color: "white",
                textAlign: "center",
              }}
            >
              Encuentra más en nuestras redes sociales:
            </Typography>
            <Grid container spacing={2}>
              {socialData.map((social, index) => (
                <>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", justifyContent: "center" }}
                    key={index}
                  >
                    <IconButton
                      key={index}
                      component='a'
                      href={social.link}
                      target='_blank'
                      rel='noopener noreferrer'
                      sx={{
                        color: "inherit",
                        "&:hover": { color: "#D8708E" },
                      }}
                    >
                      {social.icon}
                    </IconButton>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
          {/**QRS */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography textAlign='center' color='white' fontWeight='bold'>
                  ¡Consigue nuestra aplicación!
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={require("../../assets/img/PlayStore.jpeg")}
                  alt='Play Store'
                  style={{ width: "100px", marginBottom: "8px" }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={require("../../assets/img/AppStore.jpeg")}
                  alt='App Store'
                  style={{ width: "100px", marginBottom: "8px" }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <PlayStoreIcon width={50} />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src='https://1000marcas.net/wp-content/uploads/2020/11/App-Store-logo.png'
                  height={50}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Logo */}
          <Grid item xs={12} md={3} textAlign='center'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Link href='/'>
                  <Box
                    component='img'
                    src={require("../../assets/img/MERRY COLOR D8708E.png")}
                    alt='Merry Color Logo'
                    sx={{ maxWidth: "auto", mx: "auto" }}
                  />
                </Link>
              </Grid>
              {policies
                .filter((policy) => policy.auth === autenticado) // Filtra las políticas según el estado de autenticado
                .map((policy, index) => (
                  <Grid item xs={12} sm={4} lg={4} key={index}>
                    <Link to={policy.link} style={{ textDecoration: "none" }}>
                      <Typography
                        variant='body2'
                        sx={{ color: "#1876D2", textAlign: "center" }}
                      >
                        {policy.name}
                      </Typography>
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </Grid>

          {/* Contact Info */}
          <Grid item xs={12} md={3}>
            <Typography
              variant='subtitle1'
              sx={{ fontWeight: "bold", mb: 1, color: "white" }}
            >
              Contacto
            </Typography>
            <Box>
              <Typography
                variant='body2'
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  color: "white",
                }}
              >
                <i className='icon-map-pin' style={{ marginRight: 8 }} />
                Calle José María Morelos, #673, Zona centro, Guadalajara,
                Jalisco C.P 44100
              </Typography>
              <Typography
                variant='body2'
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <i className='icon-smartphone' style={{ marginRight: 8 }} />
                <Link href='tel:+525618391680' sx={{ textDecoration: "none" }}>
                  +52 56 1839 1680
                </Link>
              </Typography>
              <Typography
                variant='body2'
                sx={{ display: "flex", alignItems: "center" }}
              >
                <i className='icon-mail' style={{ marginRight: 8 }} />
                <Link
                  href='mailto:merrycolor.oficial@gmail.com'
                  sx={{ textDecoration: "none" }}
                >
                  merrycolor.oficial@gmail.com
                </Link>
              </Typography>
              <Typography
                variant='body2'
                sx={{ display: "flex", alignItems: "center" }}
              >
                <i className='icon-mail' style={{ marginRight: 8 }} />
                <Link
                  href='mailto:merrycolorventas@gmail.com'
                  sx={{ textDecoration: "none" }}
                >
                  merrycolorventas@gmail.com
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Box
          sx={{
            mt: 4,
            textAlign: "center",
            borderTop: "1px solid #ddd",
            width: "100%",
            pt: 2,
          }}
        >
          <Typography variant='body2' sx={{ color: "#6c757d" }}>
            &copy; {new Date().getFullYear()} All rights reserved.{" "}
            <Link
              href='https://digital-pineapple.com.mx'
              target='_blank'
              rel='noopener noreferrer'
              sx={{
                textDecoration: "none",
                color: "#6c757d",
                fontWeight: "bold",
              }}
            >
              digital-pineapple.com.mx
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
