import React from "react";
import { Footer } from "../../components/Layout/Footer";
import { Header1 } from "../../components/Layout/Header1.js";
import { Banner } from "../../components/Layout/Banner.js";
import CategoriesIndex from "./CategoriesIndex.js";
import { Discount } from "./Discount.js";
import { Info } from "./Info.js";
import Slider from "../Slider/Slider.js";
import Content from "../../components/Content/Content.js";

const Catalogo = () => {
  return (
    <>
      <Header1 />
      <Banner />
      {/* <Content /> */}
      {/* <Slider /> */}
      <CategoriesIndex />
      <Discount />
      <Info />
      {/* <InstagramPosts /> */}
      <Footer />
    </>
  );
};

export default Catalogo;
