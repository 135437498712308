import {
  CREATE_DEPARTURE_PRODUCTS,
  GET_ALL_DEPARTURE_PRODUCTS,
} from "../../types";
export default function DepartureProductsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_DEPARTURE_PRODUCTS:
      return {
        ...state,
        departure_products: action.payload,
        success: false,
        ErrorsApi: [],
      };

    case CREATE_DEPARTURE_PRODUCTS:
      return {
        ...state,
        departure_products: [action.payload, ...state.departure_products],
        success: false,
        ErrorsApi: [],
      };

    default:
      return state;
  }
}
