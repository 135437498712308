import React, { useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Grid, Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import EnterProductsContext from "../../../Context/EnterProducts/EnterProductsContext";
import TableEnterProducts from "./TableEnterProducts";

const EnterProducts = () => {
  const { enter_products, GetEnterProducts } = useContext(EnterProductsContext);
  useEffect(() => {
    GetEnterProducts();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
        <Typography variant='h5' color='initial'>
          {/* Entradas de productos */}
        </Typography>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ marginBottom: 2 }}>
        <Link to={"/Registrar-Entradas"}>
          <Button
            variant='contained'
            sx={{
              backgroundColor: "#7F1F57",
              "&:hover": { backgroundColor: "#7F1F57" },
            }}
          >
            Crear Entrada
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableEnterProducts enterProducts={enter_products} />
      </Grid>
    </Grid>
  );
};

export default EnterProducts;
