import { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PublicRouter } from "./PublicRoute";
import { PrivateRouter } from "./PrivateRoute";

import AuthContext from "../Context/auth/AuthContext";
import { EcommerceRoutes } from "./EcommerceRoutes";
import { Box, Grid } from "@mui/material";
import Catalogo from "../containers/Catalogo/Catalogo";
import Policies from "../containers/Policies";
import Devoluciones from "../containers/Devoluciones";
import LoginPage from "../containers/Auth/Loggin/Loggin";
import RegistrationPage from "../containers/Auth/Registro/RegisterPage";
import ContactIndex from "../containers/Contact/ContactIndex";
import ForgotPassword from "../containers/Auth/ForgotPassword/ForgotPasswordPage";
import CartPage from "../containers/Cart/CartPage";
import CheckoutPage from "../containers/Checkout/CheckoutPage";
import SingleProductPage from "../containers/Products/ProductsShow";
import FaqPage from "../containers/Faq/Faq";
import DiscountsPage from "../containers/Discounts/Discounts";
import Spinner from "../components/loading/Spinner";
import ScrollToTop from "./ScrollTop";
import TermsAndConditionsPage from "../containers/TermsAndConditions/TermsAndConditionsPage";
import PoliciesPage from "../containers/Policies/PoliciesPage";
import PoliciesBackPage from "../containers/PoliciesBack/PoliciesBackPage";
import ContentPage from "../containers/Content/ContentPage";
import EventsPage from "../containers/Events/EventsPage";
export default function AppRouter() {
  const { autenticado, usuarioAutenticado, cargando } = useContext(AuthContext);

  useEffect(() => {
    usuarioAutenticado();
  }, []);
  if (cargando) {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{
            width: "105%",
            height: "177%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </Box>
      </Grid>
    );
  }
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <PublicRouter
            exact
            path='/registro'
            component={RegistrationPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/product/:id'
            component={SingleProductPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/Politicas'
            component={Policies}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/contacto'
            component={ContactIndex}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/ayuda'
            component={FaqPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/distribuidores'
            component={DiscountsPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/carrito'
            component={CartPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/pagar'
            component={CheckoutPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/DevolucionesYReembolsos'
            component={Devoluciones}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/iniciar-sesion'
            component={LoginPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/reset-password'
            component={ForgotPassword}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/catalogo'
            component={Catalogo}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/terms-and-conditions'
            component={TermsAndConditionsPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/policies-privacy'
            component={PoliciesPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/policies-back'
            component={PoliciesBackPage}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/events'
            component={EventsPage}
            isAuthenticated={autenticado}
          />
          <PrivateRouter
            path='/'
            component={EcommerceRoutes}
            isAuthenticated={autenticado}
          />
        </Switch>
      </Router>
    </>
  );
}
