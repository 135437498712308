import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CardMedia } from "@mui/material";
import ModalImage from "react-modal-image-responsive";
import { makeStyles } from "@mui/styles";
import defualtImage from "../../assets/img/default .png";
const useStyles = makeStyles({
  img: {
    width: 500,
    height: 550,
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
export default function ModalForImage({ image, open, handleClose }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          {image === null ? (
            <ModalImage
              className={classes.img}
              small={defualtImage}
              large={defualtImage}
            />
          ) : (
            <ModalImage className={classes.img} small={image} large={image} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "red",
              color: "white",
              "&:hover": { backgroundColor: "red", color: "white" },
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
