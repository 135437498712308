import React from "react";
import Select from "react-select";

const TaxFiscalSelect = (props) => {
  const taxes = [
    { name: "General de Ley Personas Morales", value: "601" },
    { name: "Personas Morales con Fines no Lucrativos", value: "603" },
    {
      name: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
      value: "605",
    },
    { name: "Arrendamiento", value: "606" },
    { name: "Demás ingresos", value: "608" },
    { name: "Consolidación", value: "609" },
    {
      name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
      value: "610",
    },
    { name: "Ingresos por Dividendos (socios y accionistas)", value: "611" },
    {
      name: "Personas Físicas con Actividades Empresariales y Profesionales",
      value: "612",
    },
    { name: "Ingresos por intereses", value: "614" },
    { name: "Sin obligaciones fiscales", value: "616" },
    {
      name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
      value: "620",
    },
    { name: "Incorporación Fiscal", value: "621" },
    {
      name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
      value: "622",
    },
    { name: "Opcional para Grupos de Sociedades", value: "623" },
    { name: "Coordinados", value: "624" },
    { name: "Hidrocarburos", value: "628" },
    { name: "Régimen de Enajenación o Adquisición de Bienes", value: "607" },
    {
      name: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
      value: "629",
    },
    { name: "Enajenación de acciones en bolsa de valores", value: "630" },
    { name: "Régimen de los ingresos por obtención de premios", value: "615" },
    {
      name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
      value: "625",
    },
    { name: "Régimen Simplificado de Confianza", value: "626" },
  ];

  const detectarCambiosTaxFiscal = (value) => {
    props.detectarCambiosTaxFiscal(value);
  };

  const selectStyles = {
    control: (base) => ({
      ...base,
      width: "100%",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  // Configurar el valor por defecto desde props o usar un valor estático
  const defaultTax = props.defaultTax || "612"; // Valor por defecto si no se pasa un prop

  // Encontrar la opción correspondiente en la lista
  const defaultValue = taxes
    .map((value) => ({
      label: `${value.value}-${value.name}`,
      value: value.value,
    }))
    .find((option) => option.value === defaultTax);

  return (
    <>
      <Select
        onChange={(value) => detectarCambiosTaxFiscal(value)}
        className='basic-single'
        classNamePrefix='select'
        styles={selectStyles}
        name='text_fiscal'
        placeholder='Regimen Fiscal'
        options={taxes.map((value) => ({
          label: `${value.value}-${value.name}`,
          value: value.value,
        }))}
        defaultValue={defaultValue} // Asignar el valor por defecto aquí
      />
    </>
  );
};

export default TaxFiscalSelect;
