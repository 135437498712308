import { ProductDetails } from "../../components/Products/ProductDetails/ProductDetails";

const { PublicLayout } = require("../../components/Layout/PublicLayout");

const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Producto",
    path: "/product",
  },
];
const SingleProductPage = (props) => {
  const { id } = props.match.params;
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Producto'>
      <ProductDetails id={id} />
    </PublicLayout>
  );
};

export default SingleProductPage;
