import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Policies = () => {
  return (
    <Container maxWidth='md' sx={{ mt: 5, mb: 5 }}>
      <Typography
        variant='body1'
        align='center'
        gutterBottom
        sx={{ color: "#555" }}
      >
        Nos comprometemos a proteger tu privacidad. Aquí encontrarás información
        sobre cómo recopilamos, usamos y protegemos tus datos personales.
      </Typography>

      {/* Sección 1: Información que recopilamos */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            1. Información que Recopilamos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Información personal que proporcionas al registrarte en nuestro
            sitio, como nombre, correo electrónico, dirección y número de
            teléfono.
            <br />
            - Datos sobre el uso de nuestro sitio, como tu dirección IP, tipo de
            navegador y páginas visitadas.
            <br />- Información relacionada con tus compras y preferencias.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 2: Uso de la información */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            2. Uso de la Información
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Para procesar tus pedidos y proporcionar los servicios que
            solicitas.
            <br />
            - Para personalizar tu experiencia en nuestro sitio y ofrecerte
            contenido relevante.
            <br />
            - Para enviar notificaciones relacionadas con promociones,
            actualizaciones y encuestas.
            <br />- Para cumplir con requisitos legales o normativos.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 3: Compartir información */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            3. Compartir Información
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - No compartiremos tus datos personales con terceros sin tu
            consentimiento, excepto en los siguientes casos:
            <br />
            &emsp;- Proveedores de servicios necesarios para procesar pedidos
            (por ejemplo, servicios de mensajería o procesadores de pagos).
            <br />
            &emsp;- Cuando sea requerido por ley o autoridades competentes.
            <br />- Nos aseguramos de que nuestros socios cumplan con estándares
            estrictos de privacidad y seguridad.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 4: Protección de la información */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            4. Protección de la Información
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Implementamos medidas de seguridad físicas, técnicas y
            administrativas para proteger tu información.
            <br />
            - Aunque hacemos todo lo posible para proteger tus datos, ninguna
            transmisión en línea es completamente segura.
            <br />- Te recomendamos mantener tus credenciales de inicio de
            sesión seguras.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 5: Tus derechos */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            5. Tus Derechos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Puedes acceder, actualizar o eliminar tu información personal en
            cualquier momento desde tu cuenta.
            <br />
            - Si deseas limitar el uso de tu información para fines
            promocionales, contáctanos directamente.
            <br />- Tienes derecho a solicitar una copia de los datos que
            almacenamos sobre ti.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 6: Cambios en la política */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            6. Cambios en esta Política
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Podemos actualizar esta política en cualquier momento para
            reflejar cambios en nuestras prácticas o requisitos legales.
            <br />
            - Te notificaremos sobre cualquier cambio importante a través de
            nuestro sitio o correo electrónico.
            <br />- La fecha de la última actualización se indicará al final de
            esta política.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 7: Contacto */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            7. Contacto
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            Si tienes preguntas o inquietudes sobre esta política de privacidad,
            contáctanos en <strong>merrycolor.oficial@gmail.com</strong>.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default Policies;
