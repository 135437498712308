import React, { useContext, useEffect } from "react";
import { Box, Typography, Paper, IconButton, Stack } from "@mui/material";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
import { SalesWeekIcon } from "../icons/SalesWeek";

const CardSalesWeek = () => {
  const { ventas_de_la_semana, obtenerVentasDelaSemana } =
    useContext(DashboardContext);

  useEffect(() => {
    obtenerVentasDelaSemana();
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        borderRadius: 2,
        backgroundColor: "#f5f5f5",
        maxWidth: 400,
        margin: "0 auto",
      }}
    >
      {/* Icono de la tarjeta */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{
          position: "relative",
          height: 120,
          backgroundColor: "#e3f2fd",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SalesWeekIcon sx={{ fontSize: 40, color: "#fff" }} />
        </Box>
      </Box>

      {/* Encabezado */}
      <Box sx={{ textAlign: "center", marginTop: 6 }}>
        <Typography variant='h6' sx={{ fontWeight: "bold", color: "#424242" }}>
          Ventas de la semana
        </Typography>
        <Typography
          variant='subtitle2'
          sx={{ color: "#757575", marginTop: 0.5 }}
        >
          {ventas_de_la_semana?.rango_fechas || "Cargando rango de fechas..."}
        </Typography>
      </Box>

      {/* Información de ventas */}
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{
          backgroundColor: "#e3f2fd",
          color: "#fff",
          padding: 1.5,
          borderRadius: 1,
          marginTop: 2,
        }}
      >
        <Typography variant='h4' sx={{ fontWeight: "bold", color: "#3D94E6" }}>
          {ventas_de_la_semana?.ventas_de_la_semana || "0"}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default CardSalesWeek;
