import React from "react";
import { PublicLayout } from "../../components/Layout/PublicLayout";
import Distribuitors from "../../components/Distribuitors/Distribuitors";
function DiscountsPage() {
  const breadcrumbsData = [
    {
      label: "Inicio",
      path: "/",
    },
    {
      label: "Distribuidores",
      path: "/distribuidores",
    },
  ];
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Distribuidores'>
      <Distribuitors />
    </PublicLayout>
  );
}

export default DiscountsPage;
