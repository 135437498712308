import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const PaymentInfo = () => {
  const [payment, setPayment] = useState("credit-card");
  const [copied, setCopied] = useState("");
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(text);
    setTimeout(() => setCopied(""), 2000); // Reset "copied" state after 2 seconds
  };
  return (
    <Box
      sx={{
        border: 1,
        borderColor: payment === "credit-card" ? "#E46EA7" : "grey.300",
        borderRadius: 2,
        p: 2,
        mb: 3,
      }}
    >
      <FormControl>
        <RadioGroup
          value={payment}
          onChange={(e) => setPayment(e.target.value)}
        >
          <FormControlLabel
            value='credit-card'
            control={<Radio />}
            label={
              <Box>
                <Typography variant='body1' fontWeight='bold'>
                  Transferencia Bancaria
                </Typography>
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>

      {payment === "credit-card" && (
        <Box sx={{ mt: 3 }}>
          <Typography variant='h6' gutterBottom>
            Detalles de Transferencia Bancaria
          </Typography>

          <Grid container spacing={2}>
            {/* Nombre del titular */}
            <Grid item xs={12} sm={6}>
              <Typography variant='body1' fontWeight='bold'>
                Titular de la cuenta:
              </Typography>
              <Box display='flex' alignItems='center'>
                <Typography
                  variant='body1'
                  sx={{ mr: 1, fontWeight: "bold", color: "black" }}
                >
                  Importadora MerryColor SAPI de CV
                </Typography>
                <IconButton
                  color='secondary'
                  onClick={() => handleCopy("Importadora MerryColor")}
                  size='small'
                >
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              </Box>
            </Grid>

            {/* CLABE */}
            <Grid item xs={12} sm={6}>
              <Typography variant='body1' fontWeight='bold'>
                CLABE:
              </Typography>
              <Box display='flex' alignItems='center'>
                <Typography
                  variant='body2'
                  sx={{ mr: 1, fontWeight: "bold", color: "black" }}
                >
                  012441001237829215
                </Typography>
                <IconButton
                  color='secondary'
                  onClick={() => handleCopy("012345678901234567")}
                  size='small'
                >
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='body1' fontWeight='bold'>
                N° CUENTA:
              </Typography>
              <Box display='flex' alignItems='center'>
                <Typography
                  variant='body2'
                  sx={{ mr: 1, fontWeight: "bold", color: "black" }}
                >
                  0123782921
                </Typography>
                <IconButton
                  color='secondary'
                  onClick={() => handleCopy("012345678901234567")}
                  size='small'
                >
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              </Box>
            </Grid>

            {/* Banco */}
            <Grid item xs={12} sm={6}>
              <Typography variant='body1' fontWeight='bold'>
                Banco:
              </Typography>
              <Box display='flex' alignItems='center'>
                <Typography
                  variant='body2'
                  sx={{ mr: 1, fontWeight: "bold", color: "black" }}
                >
                  BBVA
                </Typography>
                <IconButton
                  color='secondary'
                  onClick={() => handleCopy("Banco Nacional")}
                  size='small'
                >
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          {/* Nota para el cliente */}
          <Typography variant='body2' color='textSecondary' sx={{ mt: 2 }}>
            <b>Importante:</b>
            Por favor, realice la transferencia bancaria y conserve su
            comprobante. Debe de subir su comprobante de pago en la sección de
            mis pedidos.
            <br />
            <b>
              Tiene maximo 5 dias para realizar su transferencia, de lo
              contrario su pedido se te cancelará de manera automática.
            </b>
          </Typography>

          {/* Feedback de copiado */}
          {copied && (
            <Typography
              variant='body1'
              color='#D87390'
              fontWeight='bold'
              sx={{ mt: 2 }}
            >
              ¡Copiado al portapapeles!
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default PaymentInfo;
