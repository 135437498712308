import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ShowToast from "../../utils/ShowToast";
import CartContext from "../../Context/Cart/CartContext";
import ModalImage from "react-modal-image-responsive";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import FormatCurrency from "../../utils/FormatCurrency";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AuthContext from "../../Context/auth/AuthContext";
const CardProduct = ({ product }) => {
  const miImagen = require("../../assets/img/default .png");
  const { autenticado } = useContext(AuthContext);
  const { name, price_purchase, img, id, price, stock, retail_price } = product;
  const { agregarProducto, cart } = useContext(CartContext);
  const agregarProductsState = (prod) => {
    Swal.fire({
      title: "¿Cuántas piezas deseas agregar?",
      allowOutsideClick: false,
      html: `<input type="number" id="quantity" class="swal2-input" placeholder="1258" min="1" oninput="this.value = this.value.replace(/[^0-9]/g, '')">`,
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Guardar",
      focusConfirm: false,
      preConfirm: () => {
        const quantity = parseInt(
          Swal.getPopup().querySelector("#quantity").value,
          10
        );
        if (!quantity || quantity <= 0) {
          Swal.showValidationMessage("La cantidad debe ser mayor a 0");
          return false;
        } else if (quantity > stock) {
          Swal.showValidationMessage(
            `La cantidad debe ser menor o igual al stock ${stock} disponibles unicamente`
          );
          return false;
        }
        return { quantity };
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const quantityToAdd = result.value.quantity;
        agregarProducto(prod, quantityToAdd);
        ShowToast(
          "success",
          "Producto agregado",
          `Piezas agregadas: ${quantityToAdd}`
        );
      }
    });
  };
  const productoEnCarrito = cart.some((item) => item.product_id === product.id);
  return (
    <Card
      sx={{
        maxWidth: "100%",
        borderRadius: 2,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
      }}
    >
      {/* Badge for stock */}
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "#FAA5B7",
          color: "white",
          padding: "5px 10px",
          fontSize: "15px",
          borderRadius: "0 0 10px 0",
          fontWeight: "bold",
        }}
      >
        {stock} disponibles
      </Box>

      {/* Product Image */}
      {img.length > 0 ? (
        <ModalImage
          small={img && img[0]?.image}
          large={img && img[0]?.image}
          alt={name}
        />
      ) : (
        <ModalImage small={miImagen} large={miImagen} alt={name} />
      )}

      {/* Product Details */}
      <CardContent>
        <Typography
          variant='h6'
          component='div'
          sx={{
            fontWeight: "bold",
            color: "#333",
            marginBottom: "10px",
          }}
        >
          {name}
        </Typography>
        <Typography
          variant='h5'
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 4,
            fontWeight: "bold",
            mt: 2,
          }}
        >
          <span>{FormatCurrency(price)}</span>
        </Typography>

        {/* Add/Modify Button */}
        <Grid container spacing={2}>
          {stock >= 1 && (
            <Grid item xs={12} md={8}>
              {productoEnCarrito ? (
                <Button
                  variant='contained'
                  fullWidth
                  size='large'
                  sx={{
                    color: "white",
                    backgroundColor: "#9A457F",
                    "&:hover": { backgroundColor: "#9A457F" },
                  }}
                  onClick={() => agregarProductsState(product)}
                >
                  cambiar
                </Button>
              ) : (
                <Button
                  variant='contained'
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "#FAA5B7",
                    "&:hover": { backgroundColor: "#FAA5B7" },
                  }}
                  onClick={() => agregarProductsState(product)}
                >
                  Agregar
                </Button>
              )}
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            {autenticado ? (
              <Link to={`/producto/${id}`}>
                <Button
                  variant='contained'
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "#FAA5B7",
                    "&:hover": { backgroundColor: "#FAA5B7" },
                  }}
                  endIcon={<VisibilityIcon />}
                >
                  Ver
                </Button>
              </Link>
            ) : (
              <Link to={`/product/${id}`}>
                <Button
                  variant='contained'
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "#FAA5B7",
                    "&:hover": { backgroundColor: "#FAA5B7" },
                  }}
                  endIcon={<VisibilityIcon />}
                >
                  Ver
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </CardContent>
      {/* </Link> */}
    </Card>
  );
};

export default CardProduct;
