import { Box, Typography } from "@mui/material";

export const Map = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#FAF9FF",
        py: 4,
        px: 2,
        borderRadius: 2,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      <Typography
        variant='h4'
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          mb: 3,
          color: "#333",
        }}
      >
        Encuéntranos
      </Typography>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          borderRadius: 2,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.8336994842443!2d-103.35556872399202!3d20.676343680886223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b15416183c95%3A0x12ce378ca1ecd83d!2sMerryColor%20Guadalajara!5e0!3m2!1ses-419!2smx!4v1737049056170!5m2!1ses-419!2smx'
          width='100%'
          height='450'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
          title='Mapa de contacto'
        ></iframe>
      </Box>
    </Box>
  );
};
