import React, { useContext, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
const SalesChart = () => {
  const { ObtenerVentasMesGrafica, ventas_grafica } =
    useContext(DashboardContext);
  useEffect(() => {
    ObtenerVentasMesGrafica();
  }, []);
  const [filter, setFilter] = useState("7days");

  const filterData = () => {
    let days = 7; // Default to last 7 days

    switch (filter) {
      case "14days":
        days = 14;
        break;
      case "1month":
        days = 30;
        break;
      default:
        days = 7;
        break;
    }

    const endDate = dayjs();
    const startDate = endDate.subtract(days, "day");

    return ventas_grafica.filter((data) =>
      dayjs(data.date).isBetween(startDate, endDate, "day", "[]")
    );
  };

  const filteredData = filterData();

  return (
    <div style={{ width: "100%", background: "white", boxShadow: 3 }}>
      <FormControl
        variant='outlined'
        style={{ marginBottom: "20px", minWidth: 120 }}
      >
        <InputLabel>Filtrar por</InputLabel>
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label='Filtrar por'
        >
          <MenuItem value='7days'>Últimos 7 días</MenuItem>
          <MenuItem value='14days'>Últimas 2 semanas</MenuItem>
          <MenuItem value='1month'>Último mes</MenuItem>
        </Select>
      </FormControl>

      <ResponsiveContainer width='100%' height={400}>
        <LineChart data={filteredData}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='date' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type='monotone'
            dataKey='ventas'
            stroke='#8884d8'
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesChart;
