export const PlayStoreIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      width={width}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <path
          style={{ fill: "#FFD41D" }}
          d='M86.019,15.56C66.773,3.937,51.026,12.822,51.026,35.306v441.495 c0,22.484,15.693,31.282,34.873,19.55l360.726-220.618c19.181-11.731,19.127-30.839-0.119-42.463L86.019,15.56z'
        ></path>{" "}
        <path
          style={{ fill: "#D50707" }}
          d='M67.244,501.363c5.404,0.736,11.804-0.822,18.656-5.012l276.357-169.019l-67.291-72.082 L67.244,501.363z'
        ></path>{" "}
        <path
          style={{ fill: "#1EB615" }}
          d='M362.337,182.437L86.019,15.56c-7.123-4.302-13.763-5.788-19.308-4.821L294.965,255.25 L362.337,182.437z'
        ></path>{" "}
        <path
          style={{ fill: "#0084FF" }}
          d='M66.711,10.738c-9.434,1.645-15.685,10.404-15.685,24.567V476.8c0,14.454,6.491,23.239,16.219,24.562 l227.72-246.113L66.711,10.738z'
        ></path>{" "}
        <path d='M451.908,224.327L91.42,6.615C84.152,2.226,76.962,0,70.05,0C55.38,0,40.576,10.917,40.576,35.305V476.8 c0,24.314,14.712,35.198,29.292,35.199c0.002,0,0.002,0,0.003,0c6.945,0,14.172-2.266,21.479-6.734l360.726-220.618 c12.329-7.54,19.381-18.553,19.348-30.215C471.392,242.771,464.279,231.797,451.908,224.327z M61.474,476.8V35.305 c0-3.534,0.494-8.627,2.938-11.716L280.7,255.281L64.671,488.759C61.877,485.536,61.474,479.792,61.474,476.8z M294.999,270.598 l50.879,54.503L112.507,467.83L294.999,270.598z M294.931,239.901L110.965,42.832l234.993,141.92L294.931,239.901z M441.173,266.82 l-77.096,47.152l-54.848-58.754l54.993-59.434l76.882,46.432c5.976,3.609,9.41,8.083,9.422,12.275 C450.538,258.683,447.129,263.176,441.173,266.82z'></path>{" "}
        <path d='M93.657,177.152c-5.771,0-10.449,4.678-10.449,10.449v4.576c0,5.771,4.678,10.449,10.449,10.449s10.449-4.678,10.449-10.449 v-4.576C104.106,181.83,99.428,177.152,93.657,177.152z'></path>{" "}
        <path d='M93.657,214.79c-5.771,0-10.449,4.678-10.449,10.449v99.266c0,5.771,4.678,10.449,10.449,10.449s10.449-4.678,10.449-10.449 v-99.266C104.106,219.468,99.428,214.79,93.657,214.79z'></path>{" "}
      </g>
    </svg>
  );
};
