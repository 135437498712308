import React, { useContext, useEffect, useState, useCallback } from "react";
import OrdersContext from "../../../Context/Orders/OrdersContext";
import NoDataComponent from "../../../components/loading/NoDataComponents";
import CardOrder from "../../../components/Cards/orders/CardOrder";
import AuthContext from "../../../Context/auth/AuthContext";
import SearchBar from "../../../components/Search/SearchOrder";
import {
  Grid,
  CircularProgress,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  TablePagination,
} from "@mui/material";
import TableOrdersAdmin from "./TableOrdersAdmin";
const PreparingIndex = () => {
  const { usuario } = useContext(AuthContext);
  const { type_user, name } = usuario;
  const {
    orders,
    GetAllordersPreparing,
    PdfOrder,
    GetAllOrdersPreparingSeller,
  } = useContext(OrdersContext);
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      if (type_user === 0) {
        GetAllordersPreparing();
      } else if (type_user === 2) {
        GetAllOrdersPreparingSeller(name);
      }
      setLoading(false);
    };
    fetchOrders();
  }, []);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // Filter orders based on search term
  const filteredOrders =
    orders?.filter((order) => {
      const term = searchTerm.toLowerCase();
      return (
        order.id?.toString().toLowerCase().includes(term) ||
        order.name?.toLowerCase().includes(term)
      );
    }) || [];

  // Handle search input change
  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset pagination
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <>
        {/* Search Bar */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <TextField
            label='Buscar órdenes'
            variant='outlined'
            size='small'
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
          />
        </Grid>

        {/* Loading Spinner */}
        {loading ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress size={50} />
          </Grid>
        ) : filteredOrders.length > 0 ? (
          <>
            {/* Orders Table */}
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label='Lista de órdenes' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Folio Venta</TableCell>
                      <TableCell align='center'>Cliente</TableCell>
                      <TableCell align='center'>Telefono</TableCell>
                      <TableCell align='center'>Subtotal</TableCell>
                      <TableCell align='center'>Costo Envio</TableCell>
                      <TableCell align='center'>Total</TableCell>
                      <TableCell align='center'>Fecha</TableCell>
                      <TableCell align='center'>Vendedor</TableCell>
                      <TableCell align='center'>Opciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredOrders
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order, index) => (
                        <TableOrdersAdmin
                          key={index}
                          product={order}
                          discount={0}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component='div'
                count={filteredOrders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='Órdenes por página'
              />
            </Grid>
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Typography variant='h6' color='textSecondary'>
              No se encontraron órdenes.
            </Typography>
          </Grid>
        )}
      </>
    </>
  );
};

export default PreparingIndex;
