import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom"; // Para obtener el ID de la entrada desde la URL
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import MethodGet from "../../../config/Service";
import DateFormat from "../../../utils/FormatDate";
import Layout from "../../../components/Layout/Layout";
import { BackIcon } from "../../../components/icons/BackIcon";
const EntryDetail = () => {
  const history = useHistory();
  const { id } = useParams(); // Obtener el ID de la entrada desde la ruta

  const [entry, setEntry] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Llamada a la API para obtener los detalles de la entrada
    MethodGet(`/entrada/${id}`)
      .then((res) => {
        setLoading(false);
        setEntry(res.data.entrada);
      })
      .catch((error) => console.log(error));
  }, [id]);

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!entry) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Typography variant='h6'>
          No se encontró la información de la entrada
        </Typography>
      </Box>
    );
  }

  return (
    <Layout>
      <Container maxWidth='xl' sx={{ mt: 4 }}>
        <IconButton
          onClick={() => history.goBack()}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <BackIcon />
        </IconButton>
        <Typography variant='h4' align='center' gutterBottom>
          Detalle de Entrada #{entry.id}
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          <strong>Fecha de Entrada:</strong> {DateFormat(entry.created_at)}
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#F4CED9" }}>
              <TableRow>
                <TableCell>
                  <strong>Producto</strong>
                </TableCell>
                <TableCell>
                  <strong>Cantidad</strong>
                </TableCell>
                <TableCell>
                  <strong>Precio Unitario</strong>
                </TableCell>
                <TableCell>
                  <strong>Total</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entry &&
                entry?.entry_details.map((detail) => (
                  <TableRow key={detail.id}>
                    <TableCell>
                      {detail.product?.name || "Producto no disponible"}
                    </TableCell>
                    <TableCell>{detail.quantity}</TableCell>
                    <TableCell>${detail.product?.price.toFixed(2)}</TableCell>
                    <TableCell>
                      ${(detail.quantity * detail.product?.price).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Layout>
  );
};

export default EntryDetail;
