import React from "react";
import { Box, Typography, Container } from "@mui/material";

export const ContactInfo = () => {
  return (
    <Box
      sx={{
        marginTop: -10,
        backgroundColor: "#FAF9FF",
        py: 10,
      }}
    >
      <Container maxWidth='md'>
        <Box
          sx={{
            textAlign: "center",
            mb: 3,
          }}
        >
          <Typography
            variant='h4'
            component='h4'
            sx={{
              fontWeight: "bold",
              mb: 2,
              color: "#333",
              marginTop: 19,
            }}
          >
            En MerryColor, nos importa tu satisfacción
          </Typography>
          <Typography
            variant='body1'
            sx={{
              textAlign: "justify",
              color: "#555",
              lineHeight: 1.8,
            }}
          >
            Si tienes alguna pregunta o necesitas ayuda, envíanos un correo
            electrónico y nuestro equipo se pondrá en contacto contigo para
            ofrecerte la mejor solución. Además, nuestros expertos están siempre
            disponibles para asesorarte y ayudarte a elegir el producto perfecto
            al mejor precio.
            <br />
            En MerryColor, trabajamos constantemente para mejorar y adaptarnos a
            tus necesidades, siguiendo las últimas tendencias del mercado y
            cuidando cada detalle. Nuestra prioridad es que, al elegirnos,
            tengas una experiencia de compra cómoda, con productos de calidad y
            el respaldo de asesores altamente profesionales.
            <br />
            ¡Estamos aquí para ti!
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
