import { PublicLayout } from "../../../components/Layout/PublicLayout";
import ResetPassword from "../../../components/Auth/ResetPassword";
const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Recuperar Contraseña",
    path: "/login",
  },
];
const LoginPage = () => {
  return (
    <PublicLayout
      breadcrumb={breadcrumbsData}
      breadcrumbTitle='Recuperar Contraseña'
    >
      <ResetPassword />
    </PublicLayout>
  );
};

export default LoginPage;
