import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

export const PublicRouter = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      // Verifica si la ruta es para un producto específico
      if (!isAuthenticated && props.location.pathname.startsWith("/product")) {
        return <Component {...props} />;
      }

      // Si no está autenticado y la ruta no es para un producto, redirige a '/'
      return !isAuthenticated ? <Component {...props} /> : <Redirect to='/' />;
    }}
  />
);
PublicRouter.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
