import React from "react";
import AuthState from "./Context/auth/AuthState";
import AppRouter from "./routes/AppRouter";
import OrdersState from "./Context/Orders/OrdersState";
import ProductsState from "./Context/Products/ProductsState";
import CartState from "./Context/Cart/CartState";
import CategoriesState from "./Context/Categories/CategoriesState";
import PedidosState from "./Context/Pedidos/PedidosState";
import DirectionsState from "./Context/Directions/DirectionsState";
import BrandsState from "./Context/Brands/BrandsState";
import DashboardState from "./Context/Dashboard/DashboardState";
import PointsState from "./Context/Points/PointsState";
import EnterProductsState from "./Context/EnterProducts/EnterProductsState";
import DepartureProductsState from "./Context/DeparturesProducts/DepartureProductsState";
import EventsState from "./Context/Events/EventsState";
export default function CatalogoApp() {
  return (
    <>
      <CartState>
        <AuthState>
          <OrdersState>
            <CategoriesState>
              <ProductsState>
                <PedidosState>
                  <DirectionsState>
                    <BrandsState>
                      <DashboardState>
                        <PointsState>
                          <EnterProductsState>
                            <DepartureProductsState>
                              <EventsState>
                                <AppRouter />
                              </EventsState>
                            </DepartureProductsState>
                          </EnterProductsState>
                        </PointsState>
                      </DashboardState>
                    </BrandsState>
                  </DirectionsState>
                </PedidosState>
              </ProductsState>
            </CategoriesState>
          </OrdersState>
        </AuthState>
      </CartState>
    </>
  );
}
