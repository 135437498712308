import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import ProductsSelect from "../../SelectOptions/ProductsSelect";
import { Grid, Button, TextField } from "@mui/material";
import Swal from "sweetalert2";
import MethodGet from "../../../config/Service";
import ListaProductos from "./ListaProductos";
import { useForm } from "react-hook-form";
import EnterProductsContext from "../../../Context/EnterProducts/EnterProductsContext";
import ShowToast from "../../../utils/ShowToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddEntryProducts = () => {
  const history = useHistory();
  const { CreateEnterProducts } = useContext(EnterProductsContext);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productsList, setProductsList] = useState(
    JSON.parse(localStorage.getItem("EnterProducts")) || []
  );

  useEffect(() => {
    MethodGet("/allProducts")
      .then((res) => setProducts(res.data))
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  const handleProductChange = (value) => setSelectedProduct(value.value);

  const addProductToList = () => {
    if (!selectedProduct) {
      return Swal.fire({
        title: "Error",
        text: "Primero selecciona un producto",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }

    const product = products.find((p) => p.id === selectedProduct);

    if (!product) {
      return ShowToast("error", "Producto no encontrado", "Error");
    }

    if (productsList.some((p) => p.product_id === product.id)) {
      return ShowToast(
        "error",
        "Producto ya agregado",
        "Modifica la cantidad para agregar más piezas de este producto"
      );
    }

    const newProduct = {
      product_id: product.id,
      barcode: product.tag,
      name: product.name,
      quantity: 1,
      price: product.price,
      stock_product: product.stock,
    };

    const updatedList = [newProduct, ...productsList];
    setProductsList(updatedList);
    localStorage.setItem("EnterProducts", JSON.stringify(updatedList));

    ShowToast("success", "Producto agregado correctamente", "");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.details = productsList.map(({ product_id, quantity }) => ({
      product_id,
      quantity,
    }));
    CreateEnterProducts(data);
    localStorage.removeItem("EnterProducts");
    history.goBack();
  };

  const handleExit = () => {
    localStorage.removeItem("EnterProducts");
    history.goBack();
  };

  return (
    <Layout>
      <div style={{ padding: "15px" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete='off'
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <ProductsSelect detectarCambiosProduct={handleProductChange} />
            </Grid>

            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant='contained'
                sx={{ bgcolor: "#5F1030", "&:hover": { bgcolor: "#5F1030" } }}
                onClick={addProductToList}
                disabled={!selectedProduct}
              >
                Agregar
              </Button>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                color='secondary'
                variant='contained'
                type='submit'
              >
                Terminar Entradas
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label='Referencia de Entrada'
                name='reference'
                variant='outlined'
                {...register("reference", {
                  required: "Este campo es requerido",
                })}
                error={!!errors.reference}
                helperText={errors.reference?.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label='Descripción de entrada'
                name='description'
                variant='outlined'
                {...register("description", {
                  required: "Este campo es requerido",
                })}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label='Autoriza la entrada'
                name='auth'
                variant='outlined'
                {...register("auth", {
                  required: "Este campo es requerido",
                })}
                error={!!errors.auth}
                helperText={errors.auth?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <ListaProductos
                productsList={productsList}
                saveProductsList={setProductsList}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Layout>
  );
};

export default AddEntryProducts;
