import { useState } from "react";
import { Card } from "./Card/Card";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PendingPayment from "./orders/PendingPayment";
import ValidatePayment from "./orders/ValidatePayment";
import PreparingOrder from "./orders/PreparingOrder";
import ShippOrder from "./orders/ShippOrder";
import CancelOrders from "./orders/CancelOrders";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const ProfileOrders = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
        <AppBar position='static' style={{ backgroundColor: "#d8708e" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='secondary'
            textColor='inherit'
            variant='scrollable'
            aria-label='full width tabs example'
          >
            <Tab label='Pedidos Pendientes de pago' {...a11yProps(0)} />
            <Tab label='Pedidos En validacion de pago' {...a11yProps(1)} />
            <Tab label='Pedidos En preparación' {...a11yProps(2)} />
            <Tab label='Pedidos Enviados' {...a11yProps(3)} />
            <Tab label='Pedidos Cancelados' {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <PendingPayment />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ValidatePayment />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <PreparingOrder />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <ShippOrder />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <CancelOrders />
        </TabPanel>
      </Box>
    </>
  );
};
