import { Link } from "react-router-dom";
import discountBg from "../../assets/img/discount-bg.jpg";

export const Discount = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}
      <div
        className='discount js-img'
        style={{ backgroundImage: `url(${discountBg})` }}
      >
        <div className='wrapper'>
          <div className='discount-info'>
            <span className='saint-text'>Descuentos</span>
            <span className='main-text'>
              Obtén hasta el <span>40%</span> descuento
            </span>
            <p>
              Nutre tu piel con productos cosméticos libres de toxinas. Con las
              ofertas que no puedes rechazar
            </p>

            <Link to='/distribuidores'>
              <a className='btn'>Ver descuentos!</a>
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};
