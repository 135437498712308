import React from "react";

const TiktokIcon = ({ width }) => {
  return (
    <svg
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      aria-label='TikTok'
      role='img'
      viewBox='0 0 512 512'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth={0} />
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g id='SVGRepo_iconCarrier'>
        <rect rx='15%' height={512} width={512} fill='#ffffff' />
        <defs>
          <path
            id='t'
            d='M219 200a117 117 0 1 0 101 115v-128a150 150 0 0 0 88 28v-63a88 88 0 0 1-88-88h-64v252a54 54 0 1 1-37-51z'
            style={{ mixBlendMode: "multiply" }}
          />
        </defs>
        <use href='#t' fill='#f05' x={18} y={15} />
        <use href='#t' fill='#0ee' />
      </g>
    </svg>
  );
};

export default TiktokIcon;
