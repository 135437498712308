import React, { useEffect, useState } from "react";
import MethodGet from "../../config/Service";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
const MunicipalitySelect = (props) => {
  const [municipalities, setMunicipalities] = useState([]);
  useEffect(() => {
    MethodGet(`/distribucion/municipalities/${props.state_id}`)
      .then((res) => {
        setMunicipalities(res.data.municipalities);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.state_id]);
  const detectarCambiosMunicipality = (e) => {
    props.detectarCambiosMunicipality(e);
  };
  return (
    <Grid container spacing={2} style={{ marginBottom: 10 }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Municipio</InputLabel>
          {props.municipality_id ? (
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Marcas'
              onChange={detectarCambiosMunicipality}
              defaultValue={props.municipality_id}
            >
              {municipalities &&
                municipalities.map((municipality) => (
                  <MenuItem key={municipality.id} value={municipality.id}>
                    {municipality.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Marcas'
              onChange={detectarCambiosMunicipality}
            >
              {municipalities &&
                municipalities.map((municipality) => (
                  <MenuItem key={municipality.id} value={municipality.id}>
                    {municipality.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          {/* <FormHelperText>Campo Requerido*</FormHelperText> */}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default MunicipalitySelect;
