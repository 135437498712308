import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@material-ui/core";
import FormatCurrency from "../../../utils/FormatCurrency";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ModalImage from "react-modal-image-responsive";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import { Link } from "react-router-dom";
import AddImageProduct from "./AddImageProduct";
import ModalShowProduct from "./ModalShowProduct";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "20%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
const TableProductsAdmin = ({ product, activeTab }) => {
  const classes = useStyles();
  const { img } = product;
  const miImagen = require("../../../assets/img/default.webp");
  const [modalMultimedia, openModalMultimedia] = useState(false);
  const [id_product, setIdProduct] = useState(null);
  const handleOpenMultimedia = (id) => {
    openModalMultimedia(true);
    setIdProduct(id);
  };
  const handleCloseMultimedia = () => {
    openModalMultimedia(false);
    setIdProduct(null);
  };
  const [openShow, setOpenShow] = useState(false);
  const handleOpenShow = (id) => {
    setOpenShow(true);
    setIdProduct(id);
  };
  const handleCloseShow = () => {
    setOpenShow(false);
    setIdProduct(null);
  };
  return (
    <>
      <TableRow>
        <TableCell align='center'>
          <Stack direction='row' spacing={2}>
            <>
              {img.length > 0 ? (
                <ModalImage
                  className={classes.img}
                  small={img[0]?.image}
                  large={img[0]?.image}
                  alt={product.name}
                />
              ) : (
                <ModalImage
                  className={classes.img}
                  small={miImagen}
                  large={miImagen}
                  alt={product.name}
                />
              )}
            </>
          </Stack>
        </TableCell>
        <TableCell align='center'>{product.tag}</TableCell>{" "}
        {/* Codigo de barras */}
        <TableCell align='center'>{product.name}</TableCell>{" "}
        <TableCell align='center'>{product.description}</TableCell>{" "}
        {/* Nombre del producto */}
        <TableCell align='center'>
          <>
            <Typography>
              {" "}
              {FormatCurrency(product.price ?? 0)} {/* Precio unitario */}
            </Typography>
          </>
        </TableCell>
        <TableCell align='center'>
          <Typography
            fontWeight='bold'
            color={product.stock === 0 ? "red" : "green"}
          >
            {product.stock}{" "}
          </Typography>{" "}
          {/* Cantidad */}
        </TableCell>
        <TableCell align='center'>
          <Link to={`/Editar-producto/${product.id}`}>
            <Tooltip title='editar'>
              <IconButton>
                <EditIcon sx={{ color: "#E6A2B6" }} />
              </IconButton>
            </Tooltip>
          </Link>
          <Tooltip title='Actualizar imagen'>
            <IconButton onClick={() => handleOpenMultimedia(product.id)}>
              <VideoSettingsIcon sx={{ color: "#974C63" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {id_product !== null && (
        <AddImageProduct
          open={modalMultimedia}
          handleClose={handleCloseMultimedia}
          id={id_product}
        />
      )}
      {id_product !== null && (
        <ModalShowProduct
          open={openShow}
          id={id_product}
          handleClose={handleCloseShow}
        />
      )}
    </>
  );
};
TableProductsAdmin.propTypes = {
  product: PropTypes.object.isRequired,
};
export default TableProductsAdmin;
