import { Button, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import TableDepartureProducts from "./TableDepartureProducts";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DepartureProductsContext from "../../../Context/DeparturesProducts/DepartureProductsContext";
const DepartureProducts = () => {
  const { GetDepartureProducts, departure_products } = useContext(
    DepartureProductsContext
  );

  useEffect(() => {
    GetDepartureProducts();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
        <Link to={"/nueva-salida"}>
          <Button
            variant='contained'
            sx={{
              backgroundColor: "#95255E",
              "&:hover": { backgroundColor: "#95255E" },
            }}
          >
            Nueva salida de productos
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableDepartureProducts departureProducts={departure_products} />
      </Grid>
    </Grid>
  );
};

export default DepartureProducts;
