import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  IconButton,
  Alert,
} from "@mui/material";
import MethodGet from "../../../config/Service";
import DateFormat from "../../../utils/FormatDate";
import Layout from "../../../components/Layout/Layout";
import { BackIcon } from "../../../components/icons/BackIcon";

const ShowDepartureProducts = () => {
  const history = useHistory();
  const { id } = useParams();

  const [departure, setDeparture] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch departure data
  const fetchDepartureData = async () => {
    try {
      const response = await MethodGet(`/departures/${id}`);
      setDeparture(response.data.salida);
    } catch (err) {
      setError("Error al cargar la información de la salida.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartureData();
  }, [id]);

  // Loading state
  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  // Error state
  if (error) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Alert severity='error'>{error}</Alert>
      </Box>
    );
  }

  // No data found
  if (!departure) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Typography variant='h6'>
          No se encontró la información de la salida
        </Typography>
      </Box>
    );
  }

  // Calculate row total
  const calculateTotal = (quantity, price) => (quantity * price).toFixed(2);

  return (
    <Layout>
      <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
        {/* Back Button */}
        <Box display='flex' alignItems='center' mb={2}>
          <IconButton onClick={() => history.goBack()}>
            <BackIcon />
          </IconButton>
          <Typography variant='h6' sx={{ ml: 2 }}>
            Volver
          </Typography>
        </Box>

        {/* Title and Details */}
        <Typography variant='h4' align='center' gutterBottom>
          Detalle de salida #{departure.id}
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          <strong>Fecha de la salida:</strong>{" "}
          {DateFormat(departure.created_at)}
        </Typography>

        {/* Product Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#F4CED9" }}>
              <TableRow>
                <TableCell>
                  <strong>Producto</strong>
                </TableCell>
                <TableCell>
                  <strong>Cantidad</strong>
                </TableCell>
                <TableCell>
                  <strong>Precio Unitario</strong>
                </TableCell>
                <TableCell>
                  <strong>Total</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departure.details.map((detail) => (
                <TableRow key={detail.id} hover>
                  <TableCell>
                    {detail.product?.name || "Producto no disponible"}
                  </TableCell>
                  <TableCell>{detail.quantity}</TableCell>
                  <TableCell>${detail.product?.price.toFixed(2)}</TableCell>
                  <TableCell>
                    ${calculateTotal(detail.quantity, detail.product?.price)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Layout>
  );
};

export default ShowDepartureProducts;
