import { PublicLayout } from "../../components/Layout/PublicLayout";
import Terms from "../../components/Terms/Terms";

const breadcrumbsData = [
  {
    label: "Inicio",
    path: "/",
  },
  {
    label: "Terminos y condiciones",
    path: "/terms-and-conditions",
  },
];
const TermsAndConditionsPage = () => {
  return (
    <PublicLayout
      breadcrumb={breadcrumbsData}
      breadcrumbTitle='Terminos y condiciones'
    >
      <Terms />
    </PublicLayout>
  );
};

export default TermsAndConditionsPage;
