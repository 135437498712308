import { Link } from "react-router-dom";

export const Card = ({ order }) => {
  const { image, name, price, productNumber, id, cantidad, barcode } = order;

  return (
    <>
      {/* <!-- BEING ORDER ITEM CARD --> */}
      <div className='checkout-order__item'>
        <Link to={`/product/${id}`}>
          <a className='checkout-order__item-img'>
            <img
              src={image}
              // className='js-img'
              alt={name}
              style={{ width: "100px", height: "100px" }}
            />
          </a>
        </Link>
        <div className='checkout-order__item-info'>
          <Link to={`/product/${id}`}>
            <a className='title6'>
              {name} <span>x{cantidad}</span>
            </a>
          </Link>
          <span className='checkout-order__item-price'>
            ${(price * cantidad).toFixed(2)}
          </span>
          <span className='checkout-order__item-num'>SKU: {barcode}</span>
        </div>
      </div>
      {/* <!-- ORDER ITEM CARD EOF --> */}
    </>
  );
};
