import { useContext, useState } from "react";
import { Stepper, Step, StepLabel, Box, Grid, Button } from "@mui/material";
import { CheckoutOrders } from "./CheckoutOrder/CheckoutOrders";
import { CheckoutStep1 } from "./CheckoutSteps/CheckoutStep1";
import { CheckoutStep2 } from "./CheckoutSteps/CheckoutStep2";
import { CheckoutStep3 } from "./CheckoutSteps/CheckoutStep3";
import { CheckCircleOutline, Payment, DoneAll } from "@mui/icons-material";
import PropTypes from "prop-types";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import OrdersContext from "../../Context/Orders/OrdersContext";
import { useHistory } from "react-router-dom";
import { MethodPost } from "../../config/Service";
import CartContext from "../../Context/Cart/CartContext";

const detailBlocks = [
  {
    step: "Paso 1",
    title: "Detalle de pedido",
    icon: <CheckCircleOutline />,
  },
  {
    step: "Paso 2",
    title: "Datos de pago",
    icon: <Payment />,
  },
  {
    step: "Paso 3",
    title: "Finalizar!",
    icon: <DoneAll />,
  },
];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(95deg, rgb(216,114,144) 0%, rgb(251, 193, 223) 50%, rgb(211,87,143) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(95deg, rgb(216,114,144) 0%, rgb(251, 193, 223) 50%, rgb(211,87,143) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#D87390",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    ownerState.active || ownerState.completed ? "#F8BDD9" : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <CheckCircleOutline />,
    2: <Payment />,
    3: <DoneAll />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export const Checkout = () => {
  const { clearCart } = useContext(CartContext);
  const [activeStep, setActiveStep] = useState(0);
  const [direction, setDirection] = useState(null);
  const [helpSeller, setHelpSeller] = useState("");
  const [invoice, setInvoice] = useState("");
  const [cart, setCart] = useState(
    localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
  );
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [aditional, setAditional] = useState(0);
  const [coupon_id, setCouponId] = useState(null);
  const [shippingCost, setShippingCost] = useState(0);
  const [id_business, setIdBusiness] = useState(null);
  const { register, handleSubmit } = useForm();
  const { CreateOrder } = useContext(OrdersContext);
  const history = useHistory();

  const handleNext = () => {
    if (activeStep < detailBlocks.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrev = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleOrderCreation = async (data) => {
    try {
      const url = "/distribucion/Sales";
      Swal.fire({
        title: "Creando orden...",
        showConfirmButton: false,
        didOpen: () => Swal.showLoading(),
      });

      const response = await MethodPost(url, data); // Método de POST
      if (response.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Tu compra se ha realizado con éxito. Ahora sube tu comprobante de pago.",
          icon: "success",
          timer: 3500,
          showConfirmButton: false,
        });

        setActiveStep(2); // Ir al último paso
        // Eliminar el carrito y el cupón aplicado solo después de la creación exitosa de la orden
        clearCart();
        localStorage.removeItem("appliedCoupon"); // Limpiar el cupón aplicado
      }
    } catch (error) {
      const errorMsg =
        error.response?.data || "Ocurrió un error al procesar tu orden.";
      Swal.fire({
        title: "Error",
        text: errorMsg,
        icon: "error",
        timer: 3000,
        showConfirmButton: true,
      });
    }
  };
  const validateOrder = () => {
    if (!direction) {
      Swal.fire({
        title: "Cuidado",
        text: "Debes seleccionar una dirección de envío.",
        icon: "warning",
        timer: 2500,
        showConfirmButton: false,
      });
      return false;
    }

    if (!helpSeller) {
      Swal.fire({
        title: "Cuidado",
        text: "Indica si recibiste ayuda de un vendedor.",
        icon: "warning",
        timer: 1800,
        showConfirmButton: false,
      });
      return false;
    }

    if (!invoice) {
      Swal.fire({
        title: "Atención",
        text: "Selecciona si necesitas factura.",
        icon: "warning",
        timer: 1800,
        showConfirmButton: false,
      });
      return false;
    }

    return true;
  };

  const onSubmit = (formData) => {
    // Preparar los datos de la orden
    const orderData = {
      ...formData,
      status: 0,
      subtotal: cart.reduce((sum, item) => sum + Number(item.final_price), 0),
      id_business_rule: id_business,
      shippingCost,
      aditional_discount: aditional,
      total,
      direction_id: direction,
      help_for_seller: helpSeller,
      invoice,
      products_list: cart,
      coupon_id: coupon_id,
    };

    if (validateOrder()) {
      handleOrderCreation(orderData);
      localStorage.removeItem("cart");
      localStorage.removeItem("appliedCoupon");
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 4, mt: 10 }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {detailBlocks.map((block, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {block.step} <br />
                {block.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            {activeStep === 0 && (
              <CheckoutStep1
                onNext={handleNext}
                setDirection={setDirection}
                direction={direction}
              />
            )}
            {activeStep === 1 && (
              <CheckoutStep2
                onNext={handleNext}
                onPrev={handlePrev}
                helpSeller={helpSeller}
                setHelpSeller={setHelpSeller}
                invoice={invoice}
                setInvoice={setInvoice}
              />
            )}
            {activeStep === 2 && <CheckoutStep3 />}
          </Grid>
          <Grid item xs={12} md={7}>
            <CheckoutOrders
              total={total}
              setTotal={setTotal}
              discount={discount}
              setDiscount={setDiscount}
              couponDiscount={couponDiscount}
              setCouponDiscount={setCouponDiscount}
              shippingCost={shippingCost}
              setShippingCost={setShippingCost}
              setAditional={setAditional}
              setCouponId={setCouponId}
              id_business={id_business}
              setIdBusiness={setIdBusiness}
              helpSeller={helpSeller}
              setHelpSeller={setHelpSeller}
              invoice={invoice}
              setInvoice={setInvoice}
              cart={cart}
              setCart={setCart}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "space-between",
          mb: 4,
          p: 3,
        }}
      >
        {activeStep === 0 && (
          <Button
            variant='contained'
            sx={{
              backgroundColor: "#801F56",
              "&:hover": { backgroundColor: "#801F56" },
            }}
            onClick={() => history.push("/Carrito-compras")}
            disabled={activeStep === 2}
          >
            Anterior
          </Button>
        )}
        {activeStep === 0 && (
          <Button
            variant='contained'
            sx={{
              backgroundColor: "#801F56",
              "&:hover": { backgroundColor: "#801F56" },
            }}
            onClick={handleNext}
          >
            Siguiente
          </Button>
        )}{" "}
        {activeStep === 1 && (
          <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
            <Button
              variant='contained'
              type='submit'
              sx={{
                backgroundColor: "#801F56",
                "&:hover": { backgroundColor: "#801F56" },
              }}
            >
              Finalizar compra
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
