import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const PoliciesBack = () => {
  return (
    <Container maxWidth='md' sx={{ mt: 5, mb: 5 }}>
      <Typography
        variant='body1'
        align='center'
        gutterBottom
        sx={{ color: "#555" }}
      >
        Conoce nuestras políticas y procedimientos para devoluciones y
        reembolsos.
      </Typography>

      {/* Sección 1: Condiciones Generales */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            1. Requisitos para la Devolución
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Las devoluciones deben solicitarse dentro de los 7 días
            posteriores a la recepción del pedido.
            <br />
            - Los productos deben estar sin usar, en su empaque original, con
            todos los sellos intactos y en condiciones aptas para la reventa.
            <br />- Contar con un video sin cortes al abrir dicho pedido
            solicitado enfocado a todo el contexto del producto.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 2: Productos no elegibles para devolución */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            2. Productos no elegibles para devolución
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Productos en oferta o descuentos especiales.
            <br />- Productos personalizados o hechos a medida.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 3: Procedimiento de Devolución */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            3. Procedimiento de Devolución
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            1. Contáctanos a través de nuestro formulario de soporte o al correo
            electrónico <strong>ventasmerrycolor@gmail.com</strong> y al número
            de telefono <strong>5618391680</strong>.<br />
            2. Proporciona los detalles de la compra(número de pedido, nombre
            del cliente), el motivo de la devolución y evidencia en caso de
            productos defectuosos.
            <br />
            3. añadiendo un video de la apertura de tu pedido sin cortes y
            enfocado a todo el contexto del producto.
            <br /> 4. Una vez aprobada la devolución, te proporcionaremos las
            instrucciones para el envío del producto.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sección 4: Reembolsos */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            4. Reembolsos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Una vez que recibamos el producto devuelto y verifiquemos su
            estado, procesaremos el reembolso en un plazo de 7 a 10 días hábiles
            a través del mismo método de pago utilizado.
            <br />
            - Los reembolsos sólo cubren el valor del producto; los costos de
            envío no serán reembolsados.
            <br />- Los gastos de envío no son reembolsables, a menos que el
            producto esté defectuoso o incorrecto.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Sección 5: Contacto */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            5. Productos Dañados o Defectuosos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Si recibes un producto dañado o defectuoso, por favor notifícalo
            dentro de los 3 días posteriores a la entrega. Nos encargaremos de
            reemplazar el producto defectuoso.
            <br />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Sección 6: Contacto */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: "#F5F5F5" }}
        >
          <Typography variant='h6' sx={{ fontWeight: "bold" }}>
            6. Contacto
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' sx={{ color: "#555" }}>
            - Para cualquier pregunta o inquietud sobre devoluciones y
            reembolsos, puedes contactarnos en ventasmerrycolor@gmail.com o
            llamarnos al 5618391680.
            <br />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default PoliciesBack;
