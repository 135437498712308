import React, { useReducer } from "react";
import EventsReducer from "./EventsReducer";
import { ADD_EVENT_TO_CART, CLEAR_EVENT_FROM_CART } from "../../types";
import EventsContext from "./EventsContext";

const EventsState = ({ children }) => {
  const initialState = {
    cartEvents: [],
  };

  const [state, dispatch] = useReducer(EventsReducer, initialState);

  // Agregar boletos al carrito
  const addToCart = (event, quantity) => {
    dispatch({
      type: ADD_EVENT_TO_CART,
      payload: { ...event, quantity },
    });
  };

  // Vaciar carrito
  const clearCart = () => {
    dispatch({ type: CLEAR_EVENT_FROM_CART });
  };

  return (
    // ✅ Agregar return para que el JSX sea devuelto correctamente
    <EventsContext.Provider
      value={{ cartEvents: state.cartEvents, addToCart, clearCart }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export default EventsState;
